import "./SeasonSelector.css";
import SeasonNumber from './SeasonNumber'
import {useEffect, useState} from 'react'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';
import {setStorageItem,getStorageItem} from '../../utils/utils'


const SeasonSelector = ({ hasFocus, seasonCount, bubbleFocusUP, setSelectedSeason, savedSelectedSeason }) => {


    const [focusIndex, setFocusIndex] = useState(Number(savedSelectedSeason))
    const [selectedIndex, setselectedIndex] = useState(Number(savedSelectedSeason))

    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['LEFT', 'RIGHT', 'OK'], bubbleFocusUP, hasFocus)
    const [currentFocus, setCurrentFocus] = useState('')


    useEffect(() => {

        if(keyState.RIGHT){
            focusIndex === seasonCount - 1 ? setFocusIndex(seasonCount - 1) : setFocusIndex(focusIndex + 1)
        }

        if(keyState.LEFT){
            if(focusIndex === 0){
                bubbleFocusUP({relieveFocus : true, action : "LEFT"})
            }else{
                setFocusIndex(focusIndex - 1)
            }
            
        }

        
        if(keyState.OK){
            setSelectedSeason(focusIndex)
            setselectedIndex(focusIndex)
            setStorageItem('savedSeasonIndex', focusIndex);
        }

        if(keyState.BACK){
            console.log("back")
           // nextPath(-1)
        }


    }, [keyState]);

    useEffect(() => {
        if (hasFocus) {
            setFocus()
        }
    }, [hasFocus])


    return (
        <div className="outerSeasonContainer">
            <div className='divLineTop'/>
            <div className="season-selector-container">
                <p className="season-text">
                    Season
                </p>
                <div>
                    <ul className="season-list">{Array.from(Array(seasonCount), (e, i) => {
                        return <SeasonNumber key={i} number={i + 1} hasFocus={hasFocus && i === focusIndex} isSelected = {i === selectedIndex}/>
                    })}</ul>
                </div>
            </div>
            <div className='divLineBottom'/>
        </div>
       
    );
};

export default SeasonSelector;
