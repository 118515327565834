import React, { useState, useEffect, useContext } from 'react'

import SideMenuButton from './SideMenuButton'
import './SideMenu.css'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent'
import { SVG } from '../../assets';
import focusIndexOkPressed from '../../utils/IndexHandler'
import { resetScroll } from '../../utils/utils';
import { memo } from "react"; 
import { useNavigate } from "react-router-dom";
import focusInfo from '../../utils/SettingsFocusHandler';
import { getStorageItem,removeStorageItem,setStorageItem } from '../../utils/utils';

import {AstroBBCContext} from '../../App'

const SideMenu = ({ hasFocus, bubbleFocusUP, setMenuSelection, changeFocusOwnerApp, updateMenuState, suppressKeys }) => {

    const navigate = useNavigate()
    const appData = useContext(AstroBBCContext)

    const nextPath = (path) => {
        // setColapsed(true)
        if(path !== '/home'){
            navigate(path);
        }else{
            navigate(path, {replace : true});
        }
        
    }

    const navigateWithPayload = (path, payload) => {
        // setColapsed(true)
        navigate(path, {state : payload});
    }
    
    const [focusIndex, setfocusIndex] = useState(0);
    const [subListFocusIndex, setSubListFocusIndex] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['DOWN', 'UP', 'OK'], bubbleFocusUP, hasFocus)


    const [menuState, setMenuState] = useState([{
        focused: false,
        selected: false,
        hasSublist: false,
        isExpanded: false,
        sublistFocusedIndex: 0,
        subListSelectedIndex: 0
    }])

    const [sideMenuSelected, setSideMenuSelected] = useState({})

    useEffect(()=>{
        if(suppressKeys){
            setSuppressed(suppressKeys)
        }else{
            setSuppressed(suppressKeys)
        }
    }, [suppressKeys])

    useEffect(() => {
        if (keyState.UP) {


            if (!menuState[focusIndex].isExpanded) {
                focusIndex === 0 ? setfocusIndex(0) : setfocusIndex(focusIndex - 1)
            } else {
                if( subListFocusIndex === 0){
                    ToggleExpandMenuItem(focusIndex)
                    setfocusIndex(focusIndex)
                    setSubListFocusIndex(0)
                    setExpanded(false)
                }else{
                    setSubListFocusIndex(subListFocusIndex - 1)
                }
                
            }
        }

        if (keyState.DOWN) {


            //focus index menu is expanded



            if (!menuState[focusIndex].isExpanded) {
                focusIndex === MenuItems.length - 1 ? setfocusIndex(MenuItems.length - 1) : setfocusIndex(focusIndex + 1)
            } else {
                let submenuItemsLen = MenuItems[focusIndex].subMenuItems.length
                if( subListFocusIndex === submenuItemsLen - 1 ){
                    ToggleExpandMenuItem(focusIndex)
                    setfocusIndex(focusIndex + 1)
                    setSubListFocusIndex(0)
                    setExpanded(false)
                }else{
                    setSubListFocusIndex(subListFocusIndex + 1)
                }
            }
            
        }

        if (keyState.OK) {

            // go to the screen if there isn't a sub list
            // expand and go to the first submenu if there is a sub list
            // if can not expand because expanded, shrink
            // if expanded and sublist has the focus, go to the screen
            // if can expand exppand and focus else just 
            
            if (CanExpand(focusIndex)) {
                if( !expanded){
                    ToggleExpandMenuItem(focusIndex)
                }else{
                    resetScroll()
                    console.log(subListFocusIndex)
                    setSelectedIndex(focusIndex)
                    focusIndexOkPressed["didSelect"] = true
                    let apiPath = MenuItems[focusIndex].subMenuItems[subListFocusIndex].apiPath
                    navigateWithPayload(MenuItems[focusIndex].path, {data : apiPath})
                    //
                    let focusDetails = { mainSelectedIndex : focusIndex, subSelectedIndex : subListFocusIndex, playerDirect : false}
                    setStorageItem("sidemenu-focus-details",focusDetails, false, true)


                    setMenuSelection(focusIndex)
                }

            } else {

                if( MenuItems[focusIndex].path === '/settings'){
                    console.log("change owner to settings")
                    changeFocusOwnerApp("Settings")
                    focusInfo["settings"] = true
                   // removeStorageItem("sidemenu-focus-details")
                   let menuFocusDetails =  getStorageItem("sidemenu-focus-details", false, true)
                   if(menuFocusDetails){
                       menuFocusDetails.settingsDirect = true  
                       setStorageItem("sidemenu-focus-details", menuFocusDetails, false, true)
                   }
                }else{
                    setMenuSelection(focusIndex)
                    setSelectedIndex(focusIndex)
                    let focusDetails = { mainSelectedIndex : focusIndex, subSelectedIndex : subListFocusIndex, playerDirect : false, settingsDirect : false}
                    setStorageItem("sidemenu-focus-details",focusDetails, false, true)
                   // setCollapsed(collapsed => !collapsed)
                }
                nextPath(MenuItems[focusIndex].path)
            }

        }

    }, [keyState]);

    const [menuToggled, setToggled] = useState(false)

    const ToggleExpandMenuItem = (menuIndex) => {
        //expand
        let prevState = [...menuState]
        prevState[menuIndex].isExpanded = !prevState[menuIndex].isExpanded
        setMenuState(prevState)
        setToggled(true)
        setExpanded(true)
        //focus sub menu
    }

    const Reset = (menuIndex) => {
        //expand
        let prevState = [...menuState]
        prevState[menuIndex].isExpanded = false
        setMenuState(prevState)
        setToggled(false)
        setExpanded(false)
        //focus sub menu
    }

    const CanExpand = (menuIndex) => {
        return menuState[menuIndex].hasSublist;
    }


    //
    useEffect(() => {
        console.log(menuState)
    }, [menuState])


    useEffect(() => {
        console.log("has focus on side menu")
        if (hasFocus) {
            setFocus()
            setCollapsed(false)
            if(focusInfo["settings"]){
                //HardCoding the settings index
                setfocusIndex(4);
                focusInfo["settings"] = false
            }

            if (focusInfo["shouldExpand"]){
                focusInfo["shouldExpand"] = false
                if (CanExpand(selectedIndex)){
                    ToggleExpandMenuItem(selectedIndex)
                }
                //setExpanded(true);
            }
            //updateState({collapsed : false})
        }else{
            console.log("has focus on side menu")
            let savedFocusDetails = getStorageItem("sidemenu-focus-details", false, true)
            console.log(savedFocusDetails)
            if(savedFocusDetails !== null && ( savedFocusDetails.playerDirect || savedFocusDetails.settingsDirect )){
                setfocusIndex(savedFocusDetails.mainSelectedIndex)
                setSubListFocusIndex(savedFocusDetails.subSelectedIndex)
                setSelectedIndex(savedFocusDetails.mainSelectedIndex)
                savedFocusDetails.playerDirect = false
                setStorageItem("sidemenu-focus-details", savedFocusDetails, false, true)
                //removeStorageItem("sidemenu-focus-details")
            }else{
                setfocusIndex(selectedIndex);
            }
            
            if(expanded){
               focusInfo["shouldExpand"] = true
               setCollapsed(true)
               Reset(focusIndex)
            }
            setCollapsed(true)
           // updateMenuState({colled : true})

        }

    }, [hasFocus]);


    const [collapsed, setCollapsed] = useState(false);

    const MenuItems = [
        {
            title: "Home",
            subMenuItems: [],
            icon: SVG.Home,
            path: '/home'
        },
        {
            title: "Search",
            subMenuItems: [],
            icon: SVG.Search,
            path: '/search'
        },
        {            
            title: "Channels",
            subMenuItems: [
                { title: "BBC Brit", path: "brit", apiPath : "bbc-brit" },
                { title: "CBeebies", path: "cbeebees", apiPath : "cbeebies" },
                { title: "BBC Earth", path: "earth", apiPath : "bbc-earth" },
                { title: "BBC First", path: "first", apiPath : "bbc-first" },
                { title: "BBC Lifestyle", path: "lifestyle", apiPath : "bbc-lifestyle" }  
            ],
            icon: SVG.Channels,
            path: '/channels'
        },
        {
            title: "Categories",
            subMenuItems: [
                { title: "Comedy", path: "comedy", apiPath : "comedy" },
                { title: "Drama", path: "drama", apiPath : "drama" },
                { title: "Entertainment", path: "entertainment", apiPath : "entertainment" },
                { title: "Factual", path: "factual", apiPath : "factual" },
                { title: "Kids & Family", path: "childrens", apiPath : "childrens" },
                { title: "Lifestyle", path: "lifestyle", apiPath : "lifestyle" },
            ],
            icon: SVG.Categories,
            path: '/categories'
        },
        {
            title: "Settings",
            subMenuItems: [],
            icon: SVG.Settings,
            path: '/settings'
        }
    ]

    useEffect(() => {
        console.log(MenuItems);

        let initialState = MenuItems.map((item) => {
            return {
                focused: false,
                selected: false,
                hasSublist: item.subMenuItems.length > 0,
                isExpanded: false,
                sublistFocusedIndex: 0,
                subListSelectedIndex: 0
            }
        })

        setMenuState(initialState)

    }, [])

    const handleUnfocusFromSublist = (data) => {
        if (data === 'inc') {
            focusIndex === MenuItems.length - 1 ? setfocusIndex(MenuItems.length - 1) : setfocusIndex(focusIndex + 1)
        }

        if (data === 'dec') {
            focusIndex === 0 ? setfocusIndex(0) : setfocusIndex(focusIndex - 1)
        }
        setExpanded(false);
    }

    return (
        <div className={"side-menu-container " + (collapsed ? "side-menu-collapsed" : "")}>
            <div className="side-menu">
                <ul>
                    {
                        MenuItems.map((item, index) => {
                            return <SideMenuButton
                                key={index}
                                text={item.title}
                                sublistFocusIndex={subListFocusIndex}
                                focused={focusIndex === index && !expanded}
                                subList={item.subMenuItems}
                                Icon={item.icon} path={item.path}
                                selected={menuState[index] !== undefined && menuState[index].isExpanded && menuToggled}
                                sendReqToParent={handleUnfocusFromSublist}
                                sideMenuSelected={sideMenuSelected}
                                collapsed = {collapsed} />
                        })

                    }
                </ul>
            </div>
            <div className="side-menu-bar">
                
            </div>

        </div>
    )
}

export default memo(SideMenu)
