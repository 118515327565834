import React, { useEffect } from 'react'
import './event-vertical-list.css';
import EventDetail from './EventDetail';
import ThumbNail from './ThumbNail';

const Event = (props) => {
    useEffect(() => {
       
    }, []);

    useEffect(() => {
        return () => {

        };
    }, []);


    return (
        <div className="eventListItemContainer" >
            <ThumbNail event={props.data} hasFocus={props.hasFocus && props.index === props.focusIndex?"true":"false"}/>
            <EventDetail event={props.data} />
        </div>
    )
}

export default Event

