import './ControlBar.css'
import ControlHead from '../../assets/icons/player/control-head.png'
import {useRef, useEffect, useState} from 'react'
import moment from "moment";

const ControlBar = ({percent, duration}) => {
    const progress = useRef()
    const progressHead = useRef()
    const [currentTime, setCurrentTime] = useState('')
    const [remainTime, setRemainTime] = useState('')

    useEffect(()=>{
        let width = percent * 1700
        progress.current.style.width = width + 'px'
        progressHead.current.style.left = width + 'px'

        if (isNaN(duration) || duration < 0) {
            setCurrentTime("00:00:00");
            setRemainTime("00:00:00");
            return;
        }
    
        if (isNaN(percent) || percent < 0 || percent > 1) {
            setCurrentTime("00:00:00");
            setRemainTime(moment.utc(duration * 1000).format("HH:mm:ss")); 
            return;
        }
    
        let currentTimeSeconds = duration * percent;
        if (!isNaN(currentTimeSeconds) && currentTimeSeconds >= 0) {
            setCurrentTime(moment.utc(currentTimeSeconds * 1000).format("HH:mm:ss"));
            setRemainTime(moment.utc((duration - currentTimeSeconds) * 1000).format("HH:mm:ss"));
        }
    },[percent])

    return(
        <div className="control-bar-container">
            <div className="control-bar-bg">

            </div>
            <div className="control-bar-fg" ref={progress}>
                <img className="control-bar-head" ref={progressHead} src={ControlHead} />
            </div>
            <div className="control-bar-time-container">
                <p className="control-bar-current-time">{currentTime}</p>
                <p className="control-bar-remain-time">{remainTime}</p>
            </div>
        </div>
    )
}

export default ControlBar