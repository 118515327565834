import React, { useEffect, useState } from 'react'

import './SideMenuButton.css'
import SideMenuSubListButton from './SideMenuSubListButton'
import { SVG } from '../../assets';
import { memo } from "react"; 

const SideMenuButton = ({ text, sublistFocusIndex, subList, focused, Icon, path, selected, sendReqToParent, sideMenuSelected, collapsed }) => {

    const [focus, setFocus] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [subIndex, setSubIndex] = useState(0);
    const [loaded, setLoaded] = useState(false)
    const [Chevron, setChevron] = useState(SVG.DownChevron)


    const sideMenuClickHandler = () => {
        if (subList.length > 0) {

            setExpanded((!expanded), [expanded])

            setChevron(expanded ? SVG.DownChevron : SVG.UpChevron)
        }

    }
    useEffect(() => {
        setFocus(focused)
    }, [focused])

    useEffect(() => {
        if (loaded) {
            sideMenuClickHandler()
        }


    }, [selected])

    useEffect(() => {

        setLoaded(true)

    }, [])

    useEffect(() => {
        if (sideMenuSelected.selected && subList.length > 0 && selected) {
            console.log(subIndex)
            console.log(subList[0].path)
            console.log(subList[subIndex].path)

        }

    }, [sideMenuSelected])

    useEffect(() => {
        setSubIndex(sublistFocusIndex)
        if (selected) {
            console.log("triggered")
            console.log(sublistFocusIndex)
            
/*             if (navi.includes('inc')) {
                let a = subIndex
                a += 1
                if (a > subList.length - 1) {
                    a = subList.length - 1
                    sendReqToParent('inc')
                }
                setSubIndex(a)
            }
            if (navi.includes('dec')) {
                let a = subIndex
                a -= 1
                if (a < 0) {
                    a = 0
                    sendReqToParent('dec')
                }
                setSubIndex(a)
            } */

        }

    }, [sublistFocusIndex, selected])



    return (
        <li onClick={sideMenuClickHandler} className={expanded ? 'side-menu-expanded' : 'side-menu-collapsed'}>

            <div className={"side-bar-button" + (focus ? '-show' : '')}
                onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)}>

                <div className={"side-bar-button-container" + (focus ? '-show' : '') + (collapsed ? '-collapsed' : '')}>
                    <Icon className={"side-bar-button-icon"  + (focus ? '-show' : '')}></Icon>
                    <p className="side-bar-button-text">{text}</p>

                    {subList.length > 0 &&
                        <Chevron className={"side-bar-button-icon" + (focus ? '-show' : '')}></Chevron>
                    }

                </div>
                {subList.length > 0 &&
                    <div className={"sub-list"+ (collapsed ? ' collapsed' : '')}>
                        <ul>
                            {
                                subList.map((item, index) => {
                                    return <SideMenuSubListButton key={index} name={item.title} hasFocus={subIndex === index} />
                                })
                            }

                        </ul>
                    </div>
                }

            </div>


        </li>

    )
}

export default memo(SideMenuButton)


// <img src={logo} className="side-bar-button-icon" alt="logo" />