import './ChoosePin.css';
import PinKeyBoard from '../../KeyBoard/PinKeyBoard';
import useFocusParent from '../../../hooks/useFocusParent'
import React, { useState,useEffect } from 'react';
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';

const ChoosePin = (props) => {

    let focusNavMap = {
        PinKeyBoard :{

        },
        Settings :{
            "LEFT" : "SideMenu"
        }
      }
    
    const [currentFocusOwner, setCurrentFocusOwner] = useState('')

   //const [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction] = useFocusParent('', focusNavMap)
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)

    useEffect(() => {
        console.log(" Inside Choose Pin:"+props.hasFocus)
        if (props.hasFocus) {
            console.log("Choose Pin gained focus:"+props.hasFocus)
            setFocus()
            setCurrentFocusOwner('PinKeyBoard')
        } else {
            setCurrentFocusOwner('')
            console.log('Choose Pin Board Lost Focus')
        }
    }, [props.hasFocus])

    useEffect(() => {
        console.log("The current focus owner is:"+currentFocusOwner)    
    })

    return ( 
    <div className="choosePinContainer">
        
    <div className={`${props.screenState["showChoosePin"].forgotten ? 'lineOneforgotten' : 'lineOne'}`}> {props.lineOne}</div>
    <div className={`${props.screenState["showChoosePin"].forgotten ? 'hideLabel' : 'parentalLabel'}`}> {props.parentLabel}</div>
    <PinKeyBoard backNavFromSettings={props.backNavFromSettings} hasFocus={currentFocusOwner === "PinKeyBoard"} bubbleFocusUP={props.bubbleFocusUP} screenState = {props.screenState} setScreenState = {props.setScreenState}/>
    </div>
    )
    
}


export default ChoosePin;