import PlayerControlsButton from './PlayerControlsButton'
import PlayButton from './PlayButton'
import SkipButton from './SkipButton'
import SettingsButton from './SettingsButton'
import AudioButton from './AudioButton'
import ControlBar from './ControlBar'
import PlayIcon from '../../assets/icons/player/play.png'
import PauseIcon from '../../assets/icons/player/pause.png'
import PlayIconActive from '../../assets/icons/player/play-active.png'
import PlayIconActiveMain from '../../assets/icons/player/play-active-main.png'
import PauseIconActiveMain from '../../assets/icons/player/pause-active-main.png'
import SkipForward from '../../assets/icons/player/skip-right.png'
import SkipForwardActive from '../../assets/icons/player/skip-right-active.png'
import SkipBackward from '../../assets/icons/player/skip-left.png'
import SkipBackwardActive from '../../assets/icons/player/skip-left-active.png'
import SettingsIcon from '../../assets/icons/player/settings-icon.png'
import SettingsIconActive from '../../assets/icons/player/settings-icon-selected.png'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';

import './PlayerControl.css'

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PlayerControls = ({ hasFocus, bubbleFocusUP, togglePlay, skip, percent, duration, paused, resetTimer, showCCSettings, toggleAudioDisplay, player, playerRef, enableAudioButton }) => {
    const [currentFocus, setCurrentFocus] = useState('Play')
    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['OK', 'FORWARD', 'REVERSE', 'RIGHT', 'LEFT', 'STOP', 'BACK'], bubbleFocusUP, hasFocus)
    let navigate = useNavigate()
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, [])
    const animateSkip = (callback) => {
        const skipButton = document.getElementById("skip-button");
        skipButton.classList.add("skip-button-zoom-out");
        setTimeout(() => {
            skipButton.classList.remove("skip-button-zoom-out");
            skipButton.classList.add("skip-button-boundary-circle");
            
            setTimeout(() => {
                skipButton.classList.remove("skip-button-boundary-circle");
                callback()
            }, 250);
          }, 250);
    }

    useEffect(() => {
        if (hasFocus) {
            setFocus()
            setCurrentFocus("Play")
        }
    }, [hasFocus])


    useEffect(() => {
        resetTimer()
        if (keyState.RIGHT) {

            if (currentFocus === "SkipLeft") {
                setCurrentFocus("Play")
            }

            if (currentFocus === "Play") {
                setCurrentFocus("SkipRight")
            }

            if (currentFocus === "SkipRight") {
                if (!enableAudioButton) {
                    setCurrentFocus("CCSettings")
                } else {
                    setCurrentFocus("AudioSettings")
                }
            }

            if (currentFocus === "AudioSettings") {
                setCurrentFocus("CCSettings")
            }
        }

        if (keyState.LEFT) {
            if (currentFocus === "Play") {
                setCurrentFocus("SkipLeft")
            }

            if (currentFocus === "SkipRight") {
                setCurrentFocus("Play")
            }

            if (currentFocus === "AudioSettings") {
                setCurrentFocus("SkipRight")
            }

            if (currentFocus === "CCSettings") {
                if (!enableAudioButton) {
                    setCurrentFocus("SkipRight")
                } else {
                    setCurrentFocus("AudioSettings")
                }
            }
        }

        if (keyState.OK) {
            if (currentFocus === "SkipLeft") {
                const callback = () => {
                    skip(-1)
                }
                animateSkip(callback)
            }

            if (currentFocus === "SkipRight") {
                const callback = () => {
                    skip(1)
                }
                animateSkip(callback)
            }

            if (currentFocus === "AudioSettings") {
                toggleAudioDisplay()
            }   

            if (currentFocus === "CCSettings") {
                showCCSettings()
            }   

            if (currentFocus === "Play") {
                togglePlay()
            }

        }
      
        if (keyState.FORWARD) {
            skip(1)
        }
    
        if (keyState.REVERSE) {
            skip(-1)
        }
    
        if (keyState.STOP) {
            console.log(" AstroKeyEvent STOP");
            if (player) {
                try {
                  console.log("Going to call detach player");
                  player.detach();
                } catch (error) {
                  console.error("Error destroying Shaka player:", error);
                }
            }
            
            if (playerRef && playerRef.current) {
                try {
                  const video = playerRef.current;
                  video.pause();
                  video.load(); // Reload the video element to clear the previous source
                  video.remove(); // Remove the video element from the DOM
                } catch (error) {
                  console.error("Error cleaning up video element:", error);
                }
            }
            bubbleFocusUP({ relieveFocus: true, action: "BACK" })
        }

        if (keyState.BACK) {
            console.log(" AstroKeyEvent BACK");
            if (player) {
                try {
                  console.log("Going to call detach player");
                  player.detach();
                } catch (error) {
                  console.error("Error destroying Shaka player:", error);
                }
            }

            if (playerRef && playerRef.current) {
                try {
                  const video = playerRef.current;
                  video.pause();
                  video.load(); // Reload the video element to clear the previous source
                  video.remove(); // Remove the video element from the DOM
                } catch (error) {
                  console.error("Error cleaning up video element:", error);
                }
            }
            bubbleFocusUP({ relieveFocus: true, action: "BACK" });
        }
      
    }, [keyState]);


    return (
        <div className="player-controls-container">
            <ControlBar percent={percent} duration={duration} />
            <div className="player-controls-parent-container">
                <div className="player-controls">
                    <SkipButton hasFocus={currentFocus === "SkipLeft"} data={{ icon: SkipBackward, activeIcon: SkipBackwardActive }} bubbleFocusUP={bubbleFocusUP} clickHandler={() => skip(-1)} />
                    <PlayButton hasFocus={currentFocus === "Play"}
                        data={{
                            icon: PlayIcon,
                            pauseIcon: PauseIcon,
                            activeIcon: PlayIconActiveMain,
                            pauseActiveIcon: PauseIconActiveMain
                        }}
                        paused={paused} />
                    <SkipButton hasFocus={currentFocus === "SkipRight"} data={{ icon: SkipForward, activeIcon: SkipForwardActive }} clickHandler={() => skip(1)} />

                </div>
                { enableAudioButton && <div className="audio-container">
                    <AudioButton hasFocus={currentFocus === "AudioSettings"} clickHandler={() => console.log("Audio settings")} />
                </div> }
                <div className="settings-container">
                    <SettingsButton hasFocus={currentFocus === "CCSettings"} data={{ icon: SettingsIcon, activeIcon: SettingsIconActive }} clickHandler={() => console.log("settings")} />
                </div>
            </div>

        </div>
    )
}

export default PlayerControls