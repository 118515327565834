import React from 'react'
import './Series.css'
import EventVerticalList from '../../components/EventVerticalCollection'
import SeasonSelector from '../../components/SeasonSelector/SeasonSelector'
import { useLocation } from 'react-router-dom'
import useFocusParent from '../../hooks/useFocusParent'
import { useEffect, useState, useContext } from 'react'
import { makeRequest } from "../../utils/fetchData";
import BBCPlayerLogo from "../../assets/images/logo-player.png"
import {AstroBBCContext} from '../../App'
import { removeStorageItem,getStorageItem } from '../../utils/utils'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';

const Series = ({ toggleBack, hasFocus, bubbleFocusUP, menuState, changeFocusOwnerApp, setPlayerSource, showAstroInfo, setShowAstroInfo }) => {

    const appData = useContext(AstroBBCContext)
    const [seasons, setSeasons] = useState([]);
    const { state } = useLocation();
    const { path } = state
    const { source } = state
    const [metadata, setMetadata] = useState({ name: "", description: "", image: "" })
    const savedSeasonIndex = getStorageItem('savedSeasonIndex') || 0;
    const [selectedSeason, setSelectedSeason] = useState(Number(savedSeasonIndex))
    const [shouldSetFocusToSeasonSelector,setShouldSetFocusToSeasonSelector] = useState(true);
    const [keyState] = useRemoteKeyEvent(["LEFT","RIGHT","BACK","UP","DOWN"], bubbleFocusUP, hasFocus)

    useEffect(() => {
        if (keyState.BACK || keyState.LEFT || keyState.RIGHT || keyState.UP || keyState.DOWN){
            if(savedSeasonIndex){
                removeStorageItem('savedSeasonIndex');
                removeStorageItem('savedEpisodeIndex');
            }
        }

    }, [keyState]);

    useEffect(() => {

    }, [state])

    useEffect(() => {
        const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/contentitem${path}`;
        appData.showLoading(true)
        makeRequest(url)
            .then((data) => {
                console.log("data", data);
                if (data.contentItem) {
                    const seasonsData = data.contentItem.seasons;
                    setMetadata(metadata => ({
                        name: data.contentItem.name,
                        description: data.contentItem.description,
                        image: data.contentItem?.channelImage?.url,
                        headerImage: data.contentItem.slide.image.url,
                        seasonCount: data.contentItem.seasonCount,
                        seasonText: data.contentItem.seasonCount + " Season | " + data.contentItem.episodeCount + " Episodes"
                    }))
                    setSeasons(seasonsData);
                    appData.showLoading(false)
                }
            })
            .catch((err) => console.log(err));
    }, [state]);


    let focusNavMap = {
        EventList:
        {
            "UP": "SeasonSelector",
            "RIGHT" : "EventList",
            "BACK" : source
        },
        Categories:
        {
            "LEFT": "SideMenu"
        },
        SeasonSelector: {
            "DOWN": "EventList",
            "BACK" : source
        },
        Series: {
            "BACK" : "SideMenu"
        }


    }

    const [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction] = useFocusParent('', focusNavMap, false, changeFocusOwnerApp, null, toggleBack, showAstroInfo, setShowAstroInfo)
    useEffect(() => {
        if (hasFocus) {
            changeFocusOwner(shouldSetFocusToSeasonSelector && !savedSeasonIndex ? 'SeasonSelector' : 'EventList')
        } else {
            changeFocusOwner('')
            setUnHandledAction('')
            console.log("Series has lost focus")
        }

    }, [hasFocus]);

    useEffect(() => {
        if (unhandledAction !== '') {
            bubbleFocusUP({ relieveFocus: true, action: unhandledAction })
        }
    }, [unhandledAction]);


    useEffect(() => {
        console.log("series menu state")    
        console.log(menuState)
    }, [menuState]);

    return (
        <div className= {hasFocus ? "series-screen removeSeriesMargin" : "series-screen addSeriesMargin"}>
            <div className="series-header-details-container">
                <div className="series-title-items-container">
                    <div className="series-bbc-logo-container">
                        <img className="series-bbc-logo" src={BBCPlayerLogo} />
                    </div>
                    <div className="series-info-container">
                        <div className="series-text-container">
                            <p className="series-title">
                                {metadata.name}
                            </p>
                            <p className="series-episode-detail">
                                {metadata.seasonText}
                            </p>
                            <p className="series-description">
                                {metadata.description}
                            </p>
                        </div>
                        <div className="series-spec-logo-container">
                            <img className="series-spec-logo" src={metadata.image} />
                        </div>
                    </div>
                </div>
                <div className="series-header-image-container">
                    <img className="series-header-image" src={metadata.headerImage}/>
                </div>
            </div>

            <SeasonSelector hasFocus={currentFocusOwner === "SeasonSelector"} seasonCount={metadata.seasonCount} bubbleFocusUP={setFocusRequest} setSelectedSeason={setSelectedSeason} savedSelectedSeason={savedSeasonIndex} />
            {seasons[selectedSeason] && seasons[selectedSeason].episodes.length > 0 &&
                <div className= {hasFocus ? "series-page-vertical-list-container shrunkLeftMargin" : "series-page-vertical-list-container"}>
                    <EventVerticalList hasFocus={currentFocusOwner === "EventList"} bubbleFocusUP={setFocusRequest} selectedSeason={selectedSeason} season={seasons[selectedSeason]} changeFocusOwnerApp={changeFocusOwnerApp}  setPlayerSource={setPlayerSource} focusSeasonSelector = {setShouldSetFocusToSeasonSelector}/>
                    </div>    
            }

        </div>
    )
}

export default Series