import React , {useEffect, useState, useCallback, useReducer, useRef} from 'react';
import './SkipButton.css'

const SkipButton = ({data,bubbleFocusUP, hasFocus, clickHandler }) =>{



    return(
        <div id={`${hasFocus ? 'skip-button' : ''}`} onClick={clickHandler} className={`${hasFocus ? 'skip-button-selected' : 'skip-button-unselected'}`}>
            {
                data.icon && 
                <img className="skip-button-icon" src={hasFocus ? data.activeIcon : data.icon} />
            }
        </div>
    )
}

export default SkipButton

