import React , {useEffect,useState} from 'react'
import './Settings.css'
import useFocusParent from '../../hooks/useFocusParent'
import RouteWithSideMenu from '../../components/RouteWithSideMenu'
import AVSettings from '../../components/Settings/AVSettingComponent/AVSettings'
import SignOut from '../../components/Settings/SignOut/SignOut'
import PinManagement from '../../components/Settings/PinManagement/PinManagement'
import SettingsSideMenu from '../../components/Settings/SettingsSideMenu/SettingsSideMenu'
import BackButton from '../../components/Settings/BackButton/BackButton'
import About from '../../screens/Settings/About/About'
import HelpAndFAQ from './HelpAndFAQ/HelpAndFAQ'
import TermsAndConditions from './TermsAndConditions/TermsAndConditions'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent'
import ChangePinSecurity from '../../components/Settings/PinManagement/ChangePinSecurity'
import { useNavigate } from "react-router-dom";
import SettingsIcon from "../../assets/images/settingsSideIcon.png"
import pjson from '../../../package.json'
const Settings = ({ hasFocus, bubbleFocusUP, changeFocusOwnerApp }) => {


    const [version, setVersion] = useState( "Version : " + pjson.version)

    const ComponentList = [AVSettings,PinManagement,SignOut,About,HelpAndFAQ,TermsAndConditions]
    const ComponentInString = ["AVSettings","PinManagement","SignOut","About","HelpAndFAQ","TermsAndConditions"]
    let focusNavMap = {
        SignOut:
        {
            "LEFT" : "SettingsSideMenu"
        } ,
        PinManagement: {
            "LEFT" : "SettingsSideMenu"
        },
        AVSettings :{
            "LEFT" : "SettingsSideMenu"
        },
        ChangePinSecurity:{
            "LEFT" : "SettingsSideMenu"
        },
        SettingsSideMenu : {
            "BACK" : "SideMenu",
            "UP": "BackButton"
        },
        About:{
            "LEFT":"SettingsSideMenu"
        },
        HelpAndFAQ:{
            "LEFT":"SettingsSideMenu"
        },
        TermsAndConditions:{
            "LEFT":"SettingsSideMenu"
        },
        BackButton:{
            "DOWN":"SettingsSideMenu",
            "BACK" : "SideMenu"
        },
        Settings : {

        }
        
      }

    const [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction] = useFocusParent('', focusNavMap)
    const [componentIndex,setComponentIndex] =  useState(0)
   // const [focusIndex, setFocusIndex] = useState(0)
   // const [selectedIndex, setSelectedIndex] = useState(0)
    //const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK','LEFT','RIGHT'], bubbleFocusUP, hasFocus)
    const navigate = useNavigate()
    
    useEffect(() => {
        console.log("Inside use effect of Settings")
        if(hasFocus){
            console.log("Gaining focus inside the settings screen")
            changeFocusOwner("SettingsSideMenu");

        }else {
            console.log("Losing focus inside setting screen")
            changeFocusOwner("");
            setUnHandledAction("");
        }
    }, [hasFocus]);

    useEffect(() => {
        console.log("CURRENT FOCUS OWNER IS CHANGED NOW INSIDE SETTINGS"+currentFocusOwner)
       
    }, [currentFocusOwner]);

    useEffect(()=>{
        console.log("After changing the component index: The focus owner is:"+currentFocusOwner)
    }, [componentIndex]);

    useEffect(()=>{
        if(unhandledAction !== ''){
            bubbleFocusUP({ relieveFocus : true, action : unhandledAction})
        }
    }, [unhandledAction]);

    const ChangeFocusOwner = () => {
        console.log("Changing the focsu owner inside settings when right pressed:"+ComponentInString[componentIndex])
        changeFocusOwner(ComponentInString[componentIndex])
    }

    const backNavFromSettings = ()=> {
        navigate(-1)
        changeFocusOwnerApp("SideMenu")
    }

    let SettingComponent = ComponentList[componentIndex]
    return(
        
        <React.Fragment>
            <div className="settings-screen">
                <div className="SidemenuContainer">
                    <BackButton hasFocus={currentFocusOwner === "BackButton"}
                    changeFocusOwnerApp ={changeFocusOwnerApp}
                    backNavFromSettings={backNavFromSettings}
                    bubbleFocusUP={setFocusRequest}/>
                    <div className='SideMenuDiv'>
                        <div className='settingsLabelDiv'>
                            <img className= "settings-ph-icon" src = {SettingsIcon}></img>
                            <div className='SettingsLabel'>Settings</div>
                        </div>
                        <SettingsSideMenu 
                            hasFocus={currentFocusOwner === "SettingsSideMenu"} 
                            bubbleFocusUP={setFocusRequest} 
                            setComponentIndex = {setComponentIndex} 
                            changeComponentFocus = {ChangeFocusOwner}
                            changeFocusOwnerApp ={changeFocusOwnerApp}
                            />
                    </div>
                </div>
                <div className='ComponentContainer'>
                    <SettingComponent hasFocus={currentFocusOwner === ComponentInString[componentIndex]} backNavFromSettings={backNavFromSettings}  bubbleFocusUP={setFocusRequest} changeFocusOwnerApp={changeFocusOwnerApp}/>
                </div>
                <div className="app-version-text-container">
                    <p className ="app-version-text"> {version} </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Settings