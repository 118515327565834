import React from 'react'
import './Categories.css'
import {useLocation} from 'react-router-dom'
import useFocusParent from '../../hooks/useFocusParent'
import { useEffect, useState, useContext } from 'react'
import { makeRequest } from "../../utils/fetchData";
import SliderList from "../../components/Slider/SliderList";
import BBCPlayerLogo from "../../assets/images/logo-player.png"
import {removeStorageItem} from "../../utils/utils"
import {AstroBBCContext} from '../../App'
import {memo} from 'react'


const Categories = ({ hasFocus, bubbleFocusUP, changeFocusOwnerApp, setPlayerSource, toggleBack, showAstroInfo, setShowAstroInfo }) => {

    const appData = useContext(AstroBBCContext)
    const [components, setComponents] = useState([]);
    const {state} = useLocation();
    const {data} = state
    const [metadata, setMetadata] = useState({name : "", description : "", image : ""})
    const [spotLightData, setSpotLightData] = useState(null)


    useEffect(()=>{

    },[state])

    useEffect(() => {
        const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/contentitem/category/${data}`;
        appData.showLoading(true)
        let header = null
        if(appData.auth.isUserSignedIn()){
          header = appData.auth.getAuthHeader()
          console.log(header)
        }
        makeRequest(url, 'GET', null, header)
            .then((data) => {
                console.log("data", data);
                if (data.contentItem) {
                    const unfiltered = data.contentItem.components;
                    console.log(typeof(data.contentItem.image))
                    setMetadata( metadata => ({
                        name : data.contentItem.name,
                        description : data.contentItem.description !== null ? data.contentItem.description : '',
                        image : data.contentItem.tile.image !== null ? data.contentItem.tile.image.url : ''
                    }))


                    const promises = [];
                    const metadataMap = {}
                    let metaDataFetchIndex = 0
                    unfiltered.forEach((item, index) => {
                        if (item?.viewAll !== '' && item.viewAll !== undefined) {
                            let viewAllURL = `${process.env.REACT_APP_MIDDLEWARE_URL}/contentitem` + item.viewAll
                            promises.push(makeRequest(viewAllURL, 'GET', null, header))
                            metadataMap[item?.header] = metaDataFetchIndex
                            metaDataFetchIndex++
                        }
                    });

                    Promise.all(promises)
                    .then((metadatas) => {
                        unfiltered.forEach((element,i, self) => {
                          if(element?.viewAll !== '' && element.viewAll !== undefined){
                            let current = element
                            let currentIndex = metadataMap[element?.header]
                            current.collection.items.nodes = metadatas[currentIndex].contentItem?.components[0]?.collection?.items?.nodes
                            unfiltered[i] = current
                            console.log(metadatas[currentIndex].contentItem?.components[0]?.collection?.items?.nodes)
                          }
                        });
                        setComponents(unfiltered);
                        appData.showLoading(false)
                    })
                    .catch((e) => {
                        // handle errors here
                    });
                }
            })
            .catch((err) => console.log(err));
    }, [state]);


    let focusNavMap = {
        eventList:
        {

        },
        Categories:
        {
            "LEFT": "SideMenu"
        },
        SliderList : {
            "BACK" : "SideMenu"
        }

    }

    const [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction] = useFocusParent('', focusNavMap,false, changeFocusOwnerApp, null, toggleBack, showAstroInfo, setShowAstroInfo)
    useEffect(() => {
        console.log("categories focus gain")
        if (hasFocus) {
            changeFocusOwner("SliderList")
        } else {
            changeFocusOwner('')
            setUnHandledAction('')
        }

    }, [hasFocus]);

    useEffect(() => {
        if (unhandledAction !== '') {
            bubbleFocusUP({ relieveFocus: true, action: unhandledAction })
        }
    }, [unhandledAction]);

    return (
        <div className= {hasFocus ? "categories-screen removeCategoriesMargin" : "categories-screen addCategoriesMargin"}>
            <div className="categories-title-items-container">
                <div className="categories-bbc-logo-container">
                    <img className="categories-bbc-logo" src={BBCPlayerLogo} />
                </div>
                <div className="categories-info-container">
                    <div className="categories-text-container">
                        <p className="categories-description">
                        {metadata.description}
                        </p>
                    </div>
                    <div className="categories-spec-logo-container">
                        <img className="categories-spec-logo" src={metadata.image} />
                    </div>
                </div>
            </div>
            <div className="categories-horizontal-line">
                
            </div>
            {/*  <EventVerticalList hasFocus={currentFocusOwner == "eventList"} bubbleFocusUP={setFocusRequest}  /> */}
            <div className="categories-carousal-list">
            <SliderList
                hasFocus={currentFocusOwner === "SliderList"}
                source="Categories"
                bubbleFocusUP={setFocusRequest}
                isSelected={true}
                components={components}
                holdFocus={false}
                setPlayerSource={setPlayerSource}
                changeFocusOwnerApp={changeFocusOwnerApp}
                overridVisibleRows={2}
            />
            </div>
           
        </div>
    )
}

export default memo(Categories)