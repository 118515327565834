import './SecurityQuestions.css'
import { useState,useEffect } from "react"
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';


const SecurityQuestions = (props) => {

    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 1 });
    let [qselected, setQSelected] = useState(-1);
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK',"BACK", "LEFT"], props.bubbleFocusUP, props.hasFocus)

    useEffect(() => {
        if (props.hasFocus) {
            console.log("Security Questions gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 1})
            setFocus()
        } else {
            setFocusPoint({ "x": -1, "y": -1})
            console.log('Security Questions Lost Focus')
        }
    }, [props.hasFocus])

    const questions = [
        { "keycode": "What was your childhood nickname?", "xIndex": 0, "yIndex": 1 },
        { "keycode": "Name of your first pet?", "xIndex": 0, "yIndex": 2 },
        { "keycode": "Name of the street your first house was on?", "xIndex": 0, "yIndex": 3 },
        { "keycode": "Name of your primary school?", "xIndex": 0, "yIndex": 4 },
        {"keycode": "Next", "xIndex": 0, "yIndex": 5}
    ]

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        //Left Needs to be handled later
        if (keyState.LEFT) {
            props.bubbleFocusUP({relieveFocus : true, action : "LEFT"})
        }
        else if (keyState.DOWN) {
            let newFocusPoint = {}
            if (currentFocuspoint.y < 4) {
                newFocusPoint.y = currentFocuspoint.y + 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }else if (qselected !== -1 && currentFocuspoint.y === 4){
                newFocusPoint.y = currentFocuspoint.y + 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y > 1 && currentFocuspoint.x === 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            if (questions[currentFocuspoint.y - 1].keycode !== "Next"){
                setFocusPoint({ "x": 0, "y": 5})
                setQSelected(currentFocuspoint.y);
            }

            if (questions[currentFocuspoint.y - 1].keycode === "Next"){
                let oldState = props.screenState
                oldState.showAnswers["visibility"] = true
                oldState.showSecurity["visibility"] = false
                oldState.showAnswers["forgotten"] = false
                oldState.showAnswers["labelData"] = {
                    "lineOne":"3 of 4: Select a security answer",
                    "parentLabel":"Now add your answer to:"+ questions[qselected - 1].keycode
                }
                oldState.tempSetting["securityQuestion"] = questions[qselected - 1].keycode
                props.setScreenState({...oldState}) 
            }   
        }
        else if (keyState.BACK){
            props.backNavFromSettings()
        }

    }, [keyState]);

    
    return <div className='securityQuestionsContainer'>
        <div className='firstLine'> 2 of 4: Select a security question</div>
        <div className= 'questionsPanel'>{
              questions.map((row, rowIndex) => {
                
                let style = ""
                if (row.xIndex === focusPoint.x && row.yIndex === focusPoint.y) {
                   style = style + " focusedQuestion"
                }
                if (row.xIndex === 0 && row.yIndex === 1){
                    style = style + " questionsButtonOne"
                }
                if (row.xIndex === 0 && row.yIndex === 2){
                    style = style + " questionsButtonTwo"
                }
                if (row.xIndex === 0 && row.yIndex === 3){
                    style = style + " questionsButtonThree"
                }
                if (row.xIndex === 0 && row.yIndex === 4){
                    style = style + " questionsButtonFour"
                }
                if (row.xIndex === 0 && row.yIndex === 5){
                    style = style + " nextButtonFive"
                }
                if(qselected === row.yIndex){
                    style = style + " focusedSelected"
                }

                if (qselected !== -1 && row.yIndex === 5){
                    style = style + " questionSelected"
                }
                return  <div key={'key' + rowIndex + "_" + "1"} className={style}>{row.keycode}</div>
            })
        }</div>
    </div>    
}

export default SecurityQuestions