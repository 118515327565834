import { ReactComponent as HomeInSVG } from "./icons/home.svg";
import { ReactComponent as SearchInSVG } from "./icons/search.svg";
import { ReactComponent as ChannelsInSVG } from "./icons/channels.svg";
import { ReactComponent as CategoriesInSVG } from "./icons/categories.svg";
import { ReactComponent as SettingsInSVG } from "./icons/settings.svg";
import { ReactComponent as AudioInSVG } from "./icons/audio.svg";
import { ReactComponent as UpChevronInSVG } from "./icons/up-chevron.svg";
import { ReactComponent as DownChevronInSVG } from "./icons/down-chevron.svg";
import { ReactComponent as PlayInSVG } from "./icons/play.svg";

const SVG = {
    Home: HomeInSVG,
    Search: SearchInSVG,
    Channels: ChannelsInSVG,
    Categories: CategoriesInSVG,
    Settings: SettingsInSVG,
    Audio: AudioInSVG,
    UpChevron: UpChevronInSVG,
    DownChevron: DownChevronInSVG,
    Play: PlayInSVG,
}

export { SVG };