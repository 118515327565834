//https://edge.api.brightcove.com/playback/v1/accounts/{account_id}
//Authorization: BCOV-Policy {policy_key}


const playbackEndpoint = "https://edge.api.brightcove.com/playback/v1/"
const getVideoByRefID = `accounts/${process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}/videos/`
const bcovHeader = `BCOV-Policy ${process.env.REACT_APP_BCOV_POLICY}`

export const getVideo = async (refID) => {
    
    let url = playbackEndpoint + getVideoByRefID + "ref:" + refID
 
    const result = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc. 
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : bcovHeader
            }
          }).catch(err => {
              console.log("Playback info fetch FAIL")
              return undefined
          })
    console.log(result)
    
    if( result && result.ok){
        let jsonBody = result.json()
        return jsonBody
    }else{
        console.log("Playback info fetch FAIL")
        return undefined
    }
        
  }
