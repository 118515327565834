import { getQueryUrl} from "./getMetadata";
import { resetScroll } from "./utils";

export const performFetch = async (url, params) => {
  // const { setCountry, setDesktopFlag } = this.props;
  try {
    const response = await fetch(url, params);
    console.log(params)
    // const status = response.status;
    const contentType = response.headers.get("content-type");
    // const countryCode = response.headers.get("X-Country-Code");
    // const onDesktop = response.headers.get("X-Is-Desktop-Viewer");

    // let loading = false;

    // if(countryCode) {
    //   setCountry(countryCode);
    // }
    // if(onDesktop) {
    //   setDesktopFlag(onDesktop)
    // }

    const data =
      contentType && contentType.includes("application/json")
        ? await response.json()
        : await response.text();

    if (response.ok) {
      // this.setState({ loading, data, status });
      const skipDataCheck = url.includes("navigation");
      console.log("Data received: ", skipDataCheck ? data : data?.data);
      return skipDataCheck ? data : data?.data;
    } else {
      // this.setState({
      //   loading,
      //   errors: [{ message: data }],
      //   ...data, // contains `data` and possibly `errors` fields
      //   status,
      // });
      console.log("error in response: ", data);
      return;
    }
  } catch (error) {
    //   this.setState({
    //     loading: false,
    //     errors: [{ message: err.message }],
    //   });
    console.log("exception in response: ", error?.message);
    return;
  }
};


const postData = async (url = '', body = {} ) => {

  console.log(url)
  console.log(body)
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc. 
      mode : 'cors',
      headers: {

        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      },
      body: JSON.stringify(body) // body data type must match "Content-Type" header
    });
    let jsonBody = await response.json(); 
    console.log(response)
    console.log(jsonBody)

    if (response.ok) {
      console.log("Data received: ", jsonBody)
      return jsonBody;
    } else {
      console.log("error in response: ", response);
      return;
    }
}

export const makeRequest = async (
  url,
  method,
  body,
  header,
  query,
  options,
  includeCredentials
) => {
  // const { url, method, body, query, options, includeCredentials } = this.props;
  const resolvedUrl = (query && getQueryUrl(url, query)) || url;
  // const middlewareRequest = resolvedUrl && resolvedUrl.startsWith(process.env.REACT_APP_MIDDLEWARE_URL!);
  let params = { method, headers: {}, ...options };

  if (includeCredentials) {
    params.credentials = "include";
  }

  if(header){
    params.headers = { "Authorization" : header}
  }

  // if(middlewareRequest) {
  //   params.headers["x-platform"] = "WEB";
  //   const authHeader = User.authHeader();
  //   if(authHeader)
  //     params.headers.Authorization = authHeader;
  // }
  if (method === "POST") {
    params.headers["Content-Type"] = "application/json";
    //TO BE REMOVED LATER
    //params.headers["x-country-code"] = 'SG'
    if (body) params.body = JSON.stringify(body);
    console.log(JSON.stringify(body))

    try {
      const result = await postData(resolvedUrl, body);
      console.log("result received.", result);
      return result;
    } catch (error) {
      console.log("error: ", error);
      throw error;
    }


  }else{
    console.log(params)
    try {
      const result = await performFetch(resolvedUrl, params);
      console.log("result received.", result);
      return result;
    } catch (error) {
      console.log("error: ", error);
      throw error;
    }
  }
};
