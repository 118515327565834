import './SignOut.css'
import { useEffect, useState,useContext } from 'react'
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import {AstroBBCContext} from '../../../App'
import { useNavigate } from "react-router-dom";

const SignOut = (props) => {

    const navigate = useNavigate()


    const appData = useContext(AstroBBCContext);
    const [signOutFocus,setSignOutFocus] = useState(false);
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    useEffect(() => {
        if (props.hasFocus) {
            setSignOutFocus(true)
            setFocus()
        } else {
            setSignOutFocus(false)
            console.log('Signout page Lost Focus')
        }
    }, [props.hasFocus])

    useEffect(() => {
        if (keyState.LEFT) {
            props.bubbleFocusUP({relieveFocus : true, action : "LEFT"})
        }
        else if (keyState.OK) {
            //Handle OK Key Press Here
            appData.showLoading(true)
            appData.auth.signOutUser().then(response => {
                console.log(response)
                if(response === 'SUCCESS'){
                    appData.showLoading(false)
                    navigate('/home', { replace: true })
                    props.changeFocusOwnerApp('SideMenu')
                    
                }
                appData.showLoading(false)
            })
        
        }else if(keyState.BACK){
            props.backNavFromSettings();
        }
    }, [keyState]);

    return <div>
            <div className='header'>Sign out</div>
            <div className='SignOutline'></div>
            <div className='SignOutlineOne'>By signing out of your account, your rating level and PG PIN will be reset. To continue, click the button below.</div>
            <div className='SignOutlineTwo'> To watch content you will have to sign back in again</div>
            <div>{
                signOutFocus ? <div className='SignOutbutton signoutFocused'>Sign me out</div> : <div className='SignOutbutton'>Sign me out</div>
            }</div>    
        </div>
}


export default SignOut