import './About.css'
import React , {useRef,useEffect,useState} from 'react'
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import UpNav from '../../../assets/images/upNav.png'
import DownNav from '../../../assets/images/downNav.png'
const About = (props) => {

    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    const scrollDiv = document.getElementById('divToScroll')
    const navKeys = [
        [
        { "keycode": "", "xIndex": 0, "yIndex": 0, "span":2, "imgsrc":UpNav },
        { "keycode": "", "xIndex": 0, "yIndex": 1 , "span":2, "imgsrc":DownNav},
    ]
    ] 
    useEffect(() => {
        console.log(" Inside Aboutpage:"+props.hasFocus)
        if (props.hasFocus) {
            console.log("About page gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
        } else {
            //setCurrentFocusOwner('')
            console.log('About page Lost Focus')
            setFocusPoint({ "x": -1, "y": -1})
        }
    }, [props.hasFocus])

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        //Left Needs to be handled later
    
        if (keyState.DOWN) {
            let newFocusPoint = {}
            if (currentFocuspoint.y < 1 && currentFocuspoint.x === 0) {
                newFocusPoint.y = currentFocuspoint.y + 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        } else if (keyState.LEFT){
            props.bubbleFocusUP({ relieveFocus : true, action : "LEFT"})
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y === 1 && currentFocuspoint.x === 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            if (currentFocuspoint.y === 0 && currentFocuspoint.x === 0){
                scrollDiv.scrollBy(0,-100);
            }else {
                scrollDiv.scrollBy(0,100);
            }
        } 
        else if (keyState.BACK) {
            props.backNavFromSettings();
        }
    }, [keyState]);

    return ( <div>
       <div className='aboutTitle'>About</div>
       <div className='aboutLine'></div>
       <div className='extremeOuterDiv'>
       <div className='keyContainer'>{
        navKeys.map((row, rowIndex) => {
            return (row.map((item, colIndex) => {
                let divStyle = 'upAndDownKeys'
                if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                    divStyle = divStyle + "  focused"
                }

                return (<div className={divStyle}><img className='upAndDownImage' src = {item.imgsrc}></img></div>)
            }))
        })
       }
       </div>
       <div id = "divToScroll" className='outerDiv'>
       <div className='aboutContent'>
        <p className='firstParaTitle'> About BBC Player</p>
        <p className='firstParaContent'>BBC Player is an authenticated multi-genre SVOD (Subscription Video On Demand)
        service that allows viewers to watch the very best BBC programmes anytime,
        anywhere. It is available online (www.bbcplayer.com), and as an app on Apple's App
        Store for iOS devices and Google's Play Store for Android devices 
        <br/>
        <br/>
        </p>
        <p className='secondParaTitle'> What do I get</p>
        <p className='secondParaContent'>BBC Player features content from five of our global brands:  
        <br/>
        <br/>
        <b>BBC Earth</b> showcases the work of the world’s foremost factual filmmakers. From the smallest 
        creatures under the microscope to the limitless expanses of space, BBC Earth brings viewers face-to-
        face with heart pounding action, mind-blowing ideas and the wonders of being human.  
        <br/>
        <br/>
        <b>BBC First</b> is the home of premium, original BBC drama. Showcasing world-renowned, award-winning 
        talent in front of and behind the camera, it delivers audiences the first chance to see stories that 
        challenge, immerse and entertain, all with a unique British perspective.  
        <br/>
        <br/>
        <b>BBC Brit</b> offers premium factual entertainment that is intelligent but irreverent. It blends expertise 
        with entertainment and fact with fun. The channel covers a rich letiety of subjects: cars, science, 
        adventure, business, life-changing moments, extreme characters, quirky eccentrics and comedy. BBC 
        Brit is available exclusively on BBC Player.
        <br/>
        <br/>
        <b>BBC Lifestyle</b> offers inspiration for home, family and life, offering programmes on home and design, 
        food, fashion and style, to entertain, engage and inspire viewers. 
        <br/>
        <br/>
        <b>CBeebies</b> is loved by preschoolers under six, and by their parents and carers. Parents and carers 
        trust CBeebies to deliver a safe, non-violent, interactive experience designed by developmental 
        experts, and delivered by the trusted BBC brand, to promote imaginative play, social interaction, 
        language skills and educational values. 
        <br/>
        <br/>
        </p>
        <p className='thirdParaContent'>
        You will need to have a valid unifi or Astro TV subscription in order to watch programmes on BBC 
        Player. 
        </p>
        <br/>
        <br/>
        <p className='fourthParaContent'>
        For more information, please refer to the FAQs.
        </p>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
       </div>
       </div>
       </div>
    </div>
    )
}


export default About