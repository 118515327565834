import React from "react";
import "./Search.css";
import TextBox from "../../components/TextBox";
import KeyBoard from "../../components/KeyBoard";
import { useEffect, useState, useContext } from "react";
import useFocusParent from "../../hooks/useFocusParent";
import Slider from "../../components/Slider/Slider";
import { makeRequest } from "../../utils/fetchData";
import {AstroBBCContext} from '../../App'
import _ from "lodash";

const Search = ({ hasFocus, bubbleFocusUP, changeFocusOwnerApp }) => {
  let [keyBoardVisibility, setKeyBoardVisibility] = useState(true);
  let [typedText, setTypedText] = useState("");
  let [showSearchForHeader, setshowSearchForHeader] = useState(false);
  let [shouldGoUp, setShouldGoUp] = useState(false)
  let [showNoResultHeader, setShowNoResultHeader] = useState(false);


  const appData = useContext(AstroBBCContext);

  const [caraousalData, setCarousalData] = useState({
    items: {
      nodes: [],
    },
  });

  let focusNavMap = {
    TextBox: {
      DOWN: "Slider",
    },
    Slider: {
      DOWN: "KeyBoard",
      UP: "TextBox",
    },
    KeyBoard: {
      UP: "Slider",
    },
  };

  let [searchResults, changeSearchResults] = useState({});

  useEffect(() => {
    let header = null
    if(appData.auth.isUserSignedIn()){
      header = appData.auth.getAuthHeader()
      console.log(header)
    }


    let search = async () => {
      let encodedSearchKey = encodeURIComponent(typedText)
      const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/search/${encodedSearchKey}`;

      makeRequest(url, 'GET', null, header)
      .then((data) => {
        console.log(data)
        const newCaraousalData = {
          items: {
            nodes: data?.search?.nodes,
          },
        };
        setCarousalData(newCaraousalData);
      })
    }
    if (typedText.length > 3 || typedText.trim().length === 3) {
      search();
    } else {
      let newCaraousalData = {
        items: {
          nodes: [],
        },
      };
      setCarousalData(newCaraousalData);
    }
  }, [typedText]);

  useEffect(() => {
    if (
      (typedText.length > 3 || typedText.trim().length === 3) &&
      caraousalData.items.nodes.length > 0
    ) {
      setShouldGoUp(true)
      setshowSearchForHeader(true);
    } else {
      setShouldGoUp(false)
      setshowSearchForHeader(false);
    }
  }, [typedText, caraousalData]);

  useEffect(() => {
    if (
      (typedText.length > 3 || typedText.trim().length === 3) &&
      caraousalData.items.nodes.length === 0
    ) {
      setShowNoResultHeader(true);
    } else {
      setShowNoResultHeader(false);
    }
  }, [typedText, caraousalData]);

  const [
    currentFocusOwner,
    setFocusRequest,
    changeFocusOwner,
    unhandledAction,
    setUnHandledAction,
  ] = useFocusParent("", focusNavMap);
  useEffect(() => {
    console.log("Channels focus gain" + hasFocus);
    if (hasFocus) {
      changeFocusOwner("TextBox");
    } else {
      changeFocusOwner("");
      setUnHandledAction("");
    }
  }, [hasFocus]);

  useEffect(() => {
    if (unhandledAction !== "") {
      bubbleFocusUP({ relieveFocus: true, action: unhandledAction });
    }
  }, [unhandledAction]);

  const changeKeyBoardVisibility = (visbility) => {
    setKeyBoardVisibility(visbility);
    if (visbility) changeFocusOwner("KeyBoard");
  };

  return (
    <div className= {hasFocus ? "search-screen removeSearchMargin" : "search-screen addSearchMargin"}>
      <TextBox
        className="textBox"
        hasFocus={currentFocusOwner === "TextBox"}
        changeKeyBoardVisibility={changeKeyBoardVisibility}
        bubbleFocusUP={setFocusRequest}
        text={typedText}
      />
      {showSearchForHeader && (
        <div className="SearchForHeader">
          <p>
            Search results for <span class="text-style-1">{typedText}</span>
          </p>
        </div>
      )}
      {showNoResultHeader && (
        <div className="noResultDiv">
          <p className="p1">No results found</p>
          <p className="p2">Please try again</p>
        </div>
      )}
      <div className="search-results-div">
        <Slider
          hasFocus={currentFocusOwner === "Slider"}
          text="TOP PART"
          key="searchresultslider"
          source="Search"
          changeFocusOwnerApp={changeFocusOwnerApp}
          bubbleFocusUP={setFocusRequest}
          collection={caraousalData}
          isSelected={true}
        />
      </div>

      {keyBoardVisibility === true && (
        <KeyBoard
          hasFocus={currentFocusOwner === "KeyBoard"}
          bubbleFocusUP={setFocusRequest}
          setTypedText={setTypedText}
          shouldGoUp = {shouldGoUp}
        />
      )}
    </div>
  );
};

export default Search;
