import './SideMenuSubListButton.css';
import React, {useState, useEffect} from 'react'
import { memo } from "react"; 
const SideMenuSubListButton = ({name,hasFocus}) => {

    const [focus, setFocus] = useState(false)

    useEffect(()=>{
        if(hasFocus){
            setFocus(true)
        }else{
            setFocus(false)
        }
    }, [hasFocus])

    return(
     <div>
      <button className={`${focus ? 'sublist-button-selected' : 'sublist-button-unselected'}`}>{name}</button>
     </div>
    )
}

export default memo(SideMenuSubListButton)