// ${process.env.REACT_APP_MIDDLEWARE_URL}/contentitem/
import moment from "moment";
import querystring from "querystring";

export const getAvailability = (contentItem) => {
  if (!["Episode", "Season"].includes(contentItem.__typename)) return "";

  const availability = [];
  if (contentItem.end)
    availability.push(
      `Available for ${moment(contentItem.end).diff(moment(), "days")} days`
    );
  if (contentItem.channel) availability.push(contentItem.channel);

  return availability.join(" | ");
};

export const Constants = {
  HomePath: "/en",
};

export const getUrl = (url) =>
  `${Constants.HomePath}/${url || ""}`
    .replace(/\/{2,}/g, "/")
    .replace(/\/$/, "");

export const getQueryUrl = (url, query) => {
  return url
    ? query
      ? `${url}?${querystring.stringify(query)}`
      : url
    : undefined;
};

export const getContentItemMetadata = (contentItem, componentType) => {
  switch (componentType) {
    case "Tile":
      switch (contentItem.__typename) {
        case "Episode":
          return {
            header: contentItem.season.name,
            meta1: contentItem.name,
            meta2: getAvailability(contentItem),
          };
        case "Season":
          let episodeCount = contentItem.episodeCount;
          return {
            header: contentItem.name,
            meta1: `${contentItem.episodeCount} ${
              episodeCount > 1 ? "Episodes" : "Episode"
            }`,
            meta2: getAvailability(contentItem),
            url: getUrl(
              `/brand/${contentItem.series.path}/${contentItem.series.path}-s${contentItem.seasonNumber}`
            ),
          };
        case "Series":
          let seasonCount = contentItem.seasonCount;
          return {
            header: contentItem.name,
            meta1: `${contentItem.seasonCount} ${
              seasonCount > 1 ? "Seasons" : "Season"
            }`,
            meta2: contentItem.channel,
            url: getUrl(`/brand/${contentItem.path}`),
          };
        case "now":
          return {};
        case "next":
          return {};
        default:
          return {
            header: contentItem.series.name,
            meta1: contentItem.name,
            meta2: getAvailability(contentItem),
          };
      }

    case "Hero":
      switch (contentItem.__typename) {
        case "Episode":
          return {
            header: contentItem.season.name,
            subHeader: contentItem.name,
            description: contentItem.description,
          };
        case "Season":
          let episodeCount = contentItem.episodeCount;
          return {
            header: contentItem.name,
            subHeader: `${contentItem.episodeCount} ${
              episodeCount > 1 ? "Episodes" : "Episode"
            }`,
            description: contentItem.description,
            episodesUrl: getUrl(
              `/brand/${contentItem.series.path}/${contentItem.series.path}-s${contentItem.seasonNumber}`
            ),
          };
        case "Series":
          let seasonCount = contentItem.seasonCount;
          return {
            header: contentItem.name,
            subHeader: `${contentItem.seasonCount} ${
              seasonCount > 1 ? "Seasons" : "Season"
            }`,
            description: contentItem.description,
            episodesUrl: getUrl(`/brand/${contentItem.path}`),
          };
        default:
          return {};
      }
    default:
      return {};
  }
};
