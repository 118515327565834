import React , {useEffect, useState, useCallback, useReducer, useRef} from 'react';
import './SettingsButton.css'

const SettingsButton = ({data,bubbleFocusUP, hasFocus, clickHandler }) =>{



    return(
        <div onClick={clickHandler} className={`${hasFocus ? 'settings-button-selected' : 'settings-button-unselected'}`}>
            {
                data.icon && 
                <img className="Settings-button-icon" src={hasFocus ? data.activeIcon : data.icon} />
            }
        </div>
    )
}

export default SettingsButton

