import './ChangePinSecurity.css'
import React, { useState,useEffect } from 'react';
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import { getStorageItem } from '../../../utils/utils';
import GreenTick from '../../../assets/images/greenTick.png'

const ChangePinSecurity = (props) => {
    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    const localSettings = getStorageItem("pinSettings",true,true)
    console.log("The Local Settings inside Change pin security is:"+JSON.stringify(localSettings))
    useEffect(() => {
        console.log("The focus inside use effect of CP Security is:"+props.hasFocus)
        if (props.hasFocus){
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
            console.log("Change pin has focus")
        }else {
            setFocusPoint({ "x": -1, "y": -1})
            console.log("Change pin doesnt have focus")
        }
    },[props.hasFocus])

    const questions = [
        [
        { "keycode": "Turn off PIN", "xIndex": 0, "yIndex": 0, "span":2 },
        { "keycode": "Change PIN", "xIndex": 0, "yIndex": 1 , "span":2},
        { "keycode": "Forgotten your PIN?", "xIndex": 0, "yIndex": 2 , "span":2},
        { "keycode": "PIN rating level(PG lock)", "xIndex": 0, "yIndex": 3 , "span":2},
        { "keycode": localSettings["ratingToSave"], "xIndex": 0, "yIndex": 4 },
    ],[
        { "keycode": "Change", "xIndex": 1, "yIndex":  0},
    ]
    ]   

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        //Left Needs to be handled later
    
        if (keyState.DOWN) {
            let newFocusPoint = {}
            console.log("The current focus point in down is:"+JSON.stringify(currentFocuspoint))
            if (currentFocuspoint.y < 4 && currentFocuspoint.x === 0) {
                //To handle a focus jump from "Forgotten your pin to pg 13"
                if (currentFocuspoint.y === 2){
                    newFocusPoint.y = 0
                    newFocusPoint.x = 1
                }else {
                    newFocusPoint.y = currentFocuspoint.y + 1
                    newFocusPoint.x = currentFocuspoint.x
                }
                
                console.log("The New focus point in down is:"+JSON.stringify(newFocusPoint))
                setFocusPoint(newFocusPoint)
            }
        } else if (keyState.LEFT){
            console.log("Inside left of Change Pin security current focus is:"+JSON.stringify(currentFocuspoint))
            let newFocusPoint = {}
            //Special Key handling because of array structure
            
            props.bubbleFocusUP({ relieveFocus : true, action : "LEFT"})
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y >= 1) {
                //Handling focus jump of one cell
                if (currentFocuspoint.y === 4){
                    newFocusPoint.y = currentFocuspoint.y - 2
                }else {
                    newFocusPoint.y = currentFocuspoint.y - 1
                }
                
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            } else {
                if (currentFocuspoint.y === 0 && currentFocuspoint.x === 1){
                    newFocusPoint.x = 0
                    newFocusPoint.y = 2
                    setFocusPoint(newFocusPoint)
                }
            }
        }else if (keyState.RIGHT){
            let newFocusPoint = {}
            if(currentFocuspoint.y === 4){
                newFocusPoint.y = 0
                newFocusPoint.x = 1
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            if(questions[focusPoint.x][focusPoint.y].keycode === "Change PIN"){
                let oldState = props.screenState
                oldState.showChoosePin["visibility"] = true
                oldState.showChoosePin["checkChangePin"] = true
                oldState.showChangePinSecurity["visibility"] = false
                oldState.showChoosePin["labelData"] = {
                    "lineOne":"1 of 2: Change PIN",
                    "parentLabel":"Please confirm your current PIN to continue"
                }
                props.setScreenState({...oldState}) 
            }else if (questions[focusPoint.x][focusPoint.y].keycode === "Forgotten your PIN?"){
                let oldState = props.screenState
                oldState.showChoosePin["visibility"] = false
                oldState.showChoosePin["checkChangePin"] = false
                oldState.showChangePinSecurity["visibility"] = false
                oldState.showAnswers["visibility"] = true
                oldState.showAnswers["forgotten"] = true
                oldState.showAnswers["labelData"] = {
                    "lineOne":"Please anwer the security questions below to reset your PIN",
                    "parentLabel":(localSettings && localSettings["securityQuestion"]) ? localSettings["securityQuestion"] : ""
                }
                props.setScreenState({...oldState}) 
            }else if (questions[focusPoint.x][focusPoint.y].keycode === "Change"){
                let oldState = props.screenState
                oldState.showChangePinSecurity["visibility"] = false
                oldState.showChoosePin["visibility"] = true
                oldState.showChoosePin["changeRatingConfirmation"] = true
                oldState.showChoosePin["labelData"] = {
                    "lineOne":"1 of 2: Change PIN rating level ",
                    //TODO: Fetch this info from local storage
                    "parentLabel":"Please confirm your PIN to continue"
                }
                props.setScreenState({...oldState}) 
            }else if (questions[focusPoint.x][focusPoint.y].keycode === "Turn off PIN"){
                let oldState = props.screenState
                oldState.showChoosePin["visibility"] = true
                oldState.showChoosePin["disable"] = true
                oldState.showChangePinSecurity["visibility"] = false
                oldState.showChoosePin["labelData"] = {
                    "lineOne":"Turn off PIN",
                    "parentLabel":"Turning off your PIN will also reset your rating level. Please confirm your PIN to continue"
                }
                props.setScreenState({...oldState}) 
            }
        
        }
        else if (keyState.BACK){
            props.backNavFromSettings()
        }

    }, [keyState]);


    return <div className='changePinQuestionsContainer'>
        <div className='changePinSecurityPanel'>
        {
                questions.map((row, rowIndex) => {
                    return (row.map((item, colIndex) => {
                        let style = ""

                        if (item.keycode === "Turn off PIN"){
                            style = style + " questionsButton"
                        }
                        if (item.keycode === "Change PIN"){
                            style = style + " changePinButton"
                        }
                        if (item.keycode === "Forgotten your PIN?"){
                            style = style + " forgottenPinButton"
                        }

                        if (item.keycode === localSettings["ratingToSave"]){
                            style = style + " selectedRating"
                            return <div className="ratingDiv">
                                <img src = {GreenTick}></img>
                                <div>{item.keycode}</div>
                            </div>
                        }
                        if (item.keycode === "Change"){
                            style = style + " changeButton"
                        }
                        if (item.keycode === "PIN rating level(PG lock)"){
                            style = style + " ratingLabel" 
                        }

                        if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                            style = style + "  focusedButton"
                        }

                        return <div key={'key' + rowIndex + "_" + colIndex} className={style}>{item.keycode}</div>
                    }))
                })
            }
    </div>
    </div> 
    
}

export default ChangePinSecurity;