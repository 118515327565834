import React from 'react';
import SideMenu from './SideMenu/SideMenu'
import { useState } from 'react'
import { Outlet } from 'react-router';

export default ({suppressKeys, hasFocus, bubbleFocusUP, setMenuSelection, changeFocusOwnerApp, setMenuState }) => {
  
  return (
    <>

        <SideMenu suppressKeys={suppressKeys} hasFocus={hasFocus} bubbleFocusUP={bubbleFocusUP} setMenuSelection={setMenuSelection} changeFocusOwnerApp={changeFocusOwnerApp} updateMenuState={setMenuState} />


      <Outlet />
    </>
  );
};