import React, { useEffect } from 'react'
import './event-vertical-list.css';
import LowResThumb from '../../assets/images/bbc-default-thumbnail.png'

const ThumbNail = (props) => {

   
    //hard coded low image to avoid crash
    //let imgUrl = LowResThumb
    let imgUrl = LowResThumb
    if (props.event && props.event.lowResTile && props.event.lowResTile.image){
        imgUrl =  props.event.lowResTile.image["url"]
    }else{
        if (props.event.tile && props.event.tile.image){
            imgUrl = props.event.tile.image.url
        }
    }
    let divStyle = {
        backgroundImage: 'url(' + imgUrl + ')'
    }
    useEffect(()=>{
         
    },[props.event])

    return (
        <div className="eventThumbnailContainer">
            <div>
                <div className={`${props.hasFocus === 'true' ? 'tileFocused' : ''} eventThumbailFocusContainer`}>
                    <div className="eventThumbnailImage" style={divStyle}>
                        <div className="eventPlayButton">
                            <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <circle className="circle" cx="50%" cy="50%" r="48%">
                                </circle>
                                <polygon points="30,20 30,80, 80,50" className="triangle" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="eventSeparator"> </div>
            </div>

        </div>
    )
}

export default ThumbNail