import './TermsAndConditions.css'
import UpNav from '../../../assets/images/upNav.png'
import DownNav from '../../../assets/images/downNav.png'
import React , {useRef,useEffect,useState} from 'react'
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';

const TermsAndConditions = (props) => {
    
    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    const scrollDiv = document.getElementById('outerDivToScroll')
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    const navKeys = [
        [
        { "keycode": "", "xIndex": 0, "yIndex": 0, "span":2, "imgsrc":UpNav },
        { "keycode": "", "xIndex": 0, "yIndex": 1 , "span":2, "imgsrc":DownNav},
    ]
    ] 

    useEffect(() => {
        console.log(" Inside Aboutpage:"+props.hasFocus)
        if (props.hasFocus) {
            console.log("About page gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
        } else {
            //setCurrentFocusOwner('')
            console.log('About page Lost Focus')
            setFocusPoint({ "x": -1, "y": -1})
        }
    }, [props.hasFocus])

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        //Left Needs to be handled later
    
        if (keyState.DOWN) {
            let newFocusPoint = {}
            if (currentFocuspoint.y < 1 && currentFocuspoint.x === 0) {
                newFocusPoint.y = currentFocuspoint.y + 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        } else if (keyState.LEFT){
            props.bubbleFocusUP({ relieveFocus : true, action : "LEFT"})
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y === 1 && currentFocuspoint.x === 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            if (currentFocuspoint.y === 0 && currentFocuspoint.x === 0){
                scrollDiv.scrollBy(0,-100);
            }else {
                scrollDiv.scrollBy(0,100);
            }
        }
        else if (keyState.BACK) {
            props.backNavFromSettings();
        }
    }, [keyState]);

return (
    <div>
        <div className='tandCTitle'>BBC Player Terms & Conditions</div>
       <div className='tandCLine'></div>
       <div className='extremeOuterDivForTandC'>
       <div className='keyContainer'>{
        navKeys.map((row, rowIndex) => {
            return (row.map((item, colIndex) => {
                let divStyle = 'upAndDownKeys'
                if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                    divStyle = divStyle + "  focused"
                }

                return (<div className={divStyle}><img className='upAndDownImage' src = {item.imgsrc}></img></div>)
            }))
        })
       }
       </div>
       <div id = "outerDivToScroll" className='outerTandCDiv'>
       <div className='tandCContent'>
       <p className='mainTitle'>(BBC Player Term's)</p>
       <p className='subHeading'>By your access and/or use of the BBC Player, BBC Player App or BBCS Content, you are deemed to have accepted the BBC Player Terms set out herein. In the event that you disagree with any of the BBC Player Terms, please immediately cease all access and use of the BBC Player, BBC Player App or BBCS Content.</p>
       <p className='subHeadingTitle'>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Definitions</p>
       <p className='normalContent'>1.1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>"BBC Player"</b> is an online video service operated under licence by BBC Studios Distribution Limited that can be accessed via the web page www.player.bbc.com or via the BBC Player App.
       <br/>
       <br/>
       <b>1.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>"BBC Player App"</b> is the BBC Player software application, currently available via the iOS and Android operating systems and which can be downloaded for free from the Apple App Store (for iOS devices) or Google Play Store (for Android devices, including TV set-top boxes).
       <br/>
       <br/>
       <b>1.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>"BBCS Content"</b> means all content on BBC Player including without limitation programmes, audiovisual and audio content, clips, text, images and photographs.
       <br/>
       <br/>
       </p>
       <p className='subHeadingTitle'>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General</p>
       <p className='normalContent'>
       <b>2.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player is operated under licence by BBC Studios Distribution Limited ("BBCS") and in Malaysia is only made available to users under licence by TM Net and Astro.
       <br/>
       <br/>
       <b>2.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBCS is a wholly-owned subsidiary of the British Broadcasting Corporation ("BBC"), and is incorporated in England and Wales under company number 1420028. Our registered office is at 1 Television Centre, 101 Wood Lane, London W12 7FA. Our VAT number is 333289454.
       <br/>
       <br/>
       <b>2.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The BBC Player Terms apply to your use of BBC Player. By your access or use of BBC Player, you shall be deemed to have accepted and agreed to be legally bound by these BBC Player Terms, the BBCS Website Terms and Conditions at https://www.bbcstudios.com/terms-conditions, as well as any other terms referred to in these BBC Player Terms. Please note that your use of BBC Player may also be subject to additional terms and conditions by TM Net or Astro (as applicable), which form separate agreements between you and TM Net or Astro respectively.
       <br/>
       <br/>
       <b>2.4</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Capitalised terms used in these BBC Player Terms but are not otherwise defined shall have the same meaning given to them in the BBCS Website Terms and Conditions. In the event of any conflict or inconsistency between the BBC Player Terms and the BBCS Website Terms and Conditions, the BBC Player Terms will prevail.
       <br/>
       </p>
       <p className='subHeadingTitle'>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Using BBC Player</p>
       <p className='normalContent'><b>3.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player is only available to users in Malaysia via the following:</p>
       <br/>
       <br/>
       <p className='normalContent'><b>3.1.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.1.1</b>&nbsp;Subscribers to one or more of the available BBC Channels on 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net's pay television service; or (ii) who have elected to subscribe
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to BBC Player on a standalone subscription basis <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;("<b>TM Net Subscribers</b>")
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.1.2</b>&nbsp;Access to BBC Player is: (i) controlled and authenticated by 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net; and (ii) via your TM Net unifi account number.
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.1.3</b>&nbsp;You are responsible for all activity under your TM Net unifi 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;account number.
       <br/>
       <br/>
       <b>3.1.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Astro
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.2.1</b>&nbsp;Subscribers to one or more of the available BBC Channels 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;on Astro’s pay television service
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.2.2</b>&nbsp;Access to BBC Player is: (i) controlled and authenticated by 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Astro; and (ii) via your Astro ID.
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.2.3</b>&nbsp;You are responsible for all activity under your Astro ID. 
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>3.1.2.4</b>&nbsp;BBC Player on your set-top box uses the react-player 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Copyright © Pete Cook http://cookpete.com), access to which is 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subject to the MIT Licence. THIS SOFTWARE IS PROVIDED "AS IS",
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SOFTWARE.
       <br/>
       <br/>
       <b>3.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBCS Content on BBC Player can be viewed by streaming or in some cases can be temporarily downloaded for playback at a later date (subject to the viewing periods referred to in clause 4.3 below).
       <br/>
       <br/>
       <b>3.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is your responsibility to ensure that you have requisite internet connectivity and a compatible device in order to use BBC Player. BBCS is not responsible if you are unable to access BBC Player, view any BBCS Content or if there is any failure, interruption or degradation in the performance of BBC Player arising from any failure in or poor quality of the internet connectivity or mobile network and/or the device you are using or because you have not downloaded any necessary software.
       <br/>
       <br/>
       <b>3.4</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your use of BBC Player may lead to substantial bandwidth usage, which can be expensive. Data charges remain your responsibility, so you should be careful you do not exceed any 'bandwidth cap' on your account with your internet or data service provider.
       <br/>
       <br/>
       <b>3.5</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your installation and/or use of a BBC Player App may also be subject to the terms and conditions of any person(s) owning rights in the software that operates BBC Player App (if any).
       <br/>
       <br/>
       <b>3.6</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player is provided to you, solely for your private viewing and for personal, non-commercial use. Your rights to use it may be further governed by additional terms and conditions with TM Net or Astro (as applicable), which are separate agreements between you and Astro or TM Net respectively. You agree not to use BBC Player for any other purposes, such as public performances.
       </p>
       <br/>
       <br/>
       <p className='subHeadingTitle'>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBCS Content</p>
       <br/>
       <br/>
       <p className='normalContent'><b>4.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your access to BBCS Content on BBC Player will be limited by and depend on your subscription to TM Net’s or Astro’s pay television service (as applicable); you will only have access to programmes and content from the applicable BBC Channels that you subscribe to on TM Net’s or Astro’s pay television service(as applicable). Please see the FAQs for further information.
       <br/>
       <br/>
       <b>4.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The BBCS Content on the BBC Player will be updated and changed regularly and BBCS reserves the right to change or remove BBCS Content at any time. BBCS Content may be removed from BBC Player (amongst other reasons) when our rights have expired, if there are legal issues, or if you breach these BBC Player Terms. Downloads of BBCS Content will be removed from your device when the viewing period expires, when BBC Player App is deleted from your device, when you delink your account with the BBC Player App, or if the when your subscription to TM Net’s or Astro’s pay television service expires or is terminated. By your use of BBC Player you are deemed to have agreed to this deletion from your device. 
       <br/>
       <br/>
       <b>4.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBCS Content on BBC Player will have differing viewing periods, meaning that each programme will be available on BBC Player for a particular period of time. The viewing period for each programme of BBCS Content will be determined by BBCS in its sole discretion. When the viewing period of a programme is, or has reached, 14 days or less, a notice will usually be shown in the information about the programme. Where BBCS Content has been temporarily downloaded, such download will be automatically deleted on the expiry of the viewing period, and by your use of BBC Player you are deemed to have agreed to this deletion from your device.
       <br/>
       <br/>
       <b>4.4</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Downloaded BBCS Content must not be transferred from one device to another (e.g. from your laptop to your mobile phone) and you must not make multiple copies of a single item of BBCS Content.
       <br/>
       <br/>
       <b>4.5</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBCS Content as made available on BBC Player must always be viewed using BBC Player.
       <br/>
       <br/>
       <b>4.6</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may not directly or indirectly change, edit, add to or create derivative works from any BBCS Content or other content available on BBC Player.
       <br/>
       <br/>
       <b>4.7</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You do not have the right to use any BBCS Content or BBCS or BBC mark/logo on your device or elsewhere except as expressly permitted under these BBC Player Terms.
       </p>
       <p className='subHeadingTitle'>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Installing Update(s) to BBC Player</p>
       <br/>
       <br/>
       <p className='normalContent'><b>5.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You are solely responsible for installing update(s) to BBC Player and for all data charges associated with downloading any such updates. Depending on your device operating system and settings, download of updates may be automatic.
       <br/>
       <br/>
       <b>5.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You acknowledge that you may not be able to access or use BBC Player unless you install relevant update(s).
       <br/>
       <br/>
       <b>5.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After you install update(s), you may no longer be able to access BBCS Content previously downloaded and/or the features of BBC Player may change and BBCS will have no liability to you if this occurs.
       </p>
       <br/>
       <br/>
       <p className='subHeadingTitle'>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Intellectual Property Rights</p>
       <br/>
       <br/>
       <p className='normalContent'><b>6.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The intellectual property rights in BBC Player and BBCS Content are either owned by BBCS or third party providers of content. When you access and view BBCS Content through BBC Player you get permission (a licence) from TM Net or Astro (as applicable) to watch the BBCS Content for your personal use, as may be further detailed in terms and conditions with TM Net or Astro (as applicable), which are separate agreements between you and TM Net or Astro respectively.
       <br/>
       <br/>
       <b>6.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All BBCS Content, including all information, data, text, documents, graphics, logos, designs, images, pictures, photographs, videos, podcasts, weblogs, RSS feeds, widgets, embeddable media players, software, interactive features, advertisements or other content, services or materials (or any part of them) accessible on BBC Player, is protected by copyright, trade marks, database rights and other intellectual property rights. Nothing contained in these BBC Player Terms grants you any licence or right to use the BBCS Content other than as expressly permitted in these BBC Player Terms.
       <br/>
       <br/>
       <b>6.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Except as specifically permitted under these BBC Player Terms, you agree not to copy, store in any medium (including on any other website), distribute, transmit, re-transmit, re-publish, broadcast, modify, or show in public any part of BBC Player or BBCS Content without our prior written permission (and the prior written consent of the owner of the relevant intellectual property rights if applicable) in accordance with the Copyright Act 2021 (Cap. 22).
       <br/>
       <br/>
       <b>6.4</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You must not:
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>6.4.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;circumvent, remove, disable or attempt to bypass any content 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;protection system, digital rights management technology or 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;geographic restriction applied to BBC Player or BBCS Content, for 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;example, by using technology tools to misrepresent your location;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>6.4.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sell BBCS Content;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>6.4.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;modify BBCS Content in any way;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>6.4.4</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;remove any copyright or other notices from BBC Player or BBCS 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Content;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>6.4.5</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;use BBC Player or BBCS Content in an unlawful or unauthorised way;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>6.4.6</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;use BBC Player or BBCS Content or in a way that suggests an 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;association with our products, services or brands; or
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>6.4.7</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;transfer BBCS Content to other devices.
       </p>
       <p className='subHeadingTitle'>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy</p>
       <br/>
       <p className='normalContent'><b>7.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBCS and the BBC group take your privacy seriously. Find out more about the information and personal data we collect and what we do with it in the FAQs, our Privacy Policy and our Cookies Policy. By your access and/or use of BBC Player, you are deemed to have consented to us collecting and processing your data as described in these policies.
       <br/>
       <br/>
       <b>7.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net or Astro may also collect, use and/or disclose your personal data for the purposes of administering your access to BBC Player. Please refer to TM Net or Astro directly for more information on their personal data protection policies.
       </p>
       <p className='subHeadingTitle'>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suspension and termination of your account by us</p>
       <br/>
       <p className='normalContent'><b>8.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you breach any of these BBC Player Terms we may terminate or suspend your access to BBC Player and Content without giving you notice.
       </p>
       <p className='subHeadingTitle'>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Parental Control</p>
       <br/>
       <p className='normalContent'><b>9.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Some BBCS Content may contain things that aren’t suitable for young people, or things you feel are indecent, objectionable or offensive.
       <br/>
       <br/>
       <b>9.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you let a child use your computer or device to access BBCS Content via BBC Player, you are responsible for deciding whether it is appropriate for that child.
       <br/>
       <br/>
       <b>9.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you are concerned about whether BBCS Content is suitable for children you can find out more about using parental controls and parental guidance in our FAQs.
       </p>
       <p className='subHeadingTitle'>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Liability</p>
       <br/>
       <p className='normalContent'><b>10.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We take care to make content to the highest editorial, ethical and technical standards. However, BBCS is not responsible or liable for anything that happens to you:
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>10.1.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if you rely on any advice, data, commentary, opinions or other 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;materials included in any BBCS Content or links we provide;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>10.1.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if there are errors, omissions, interruptions, delays, bugs or viruses in 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any BBCS Content or with BBC Player;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>10.1.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if using BBCS Content, BBC Player (including software updates) or 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;sites we link to causes you loss of data, loss of content or damage to 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;your device or software;
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>10.1.4</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if we turn off or remove any parts of the BBCS Content, BBC Player 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;or external links; or
       <br/>
       <br/>
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>10.1.5</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;if we remove BBCS Content from BBC Player or your device and or 
       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;software as permitted by these BBC Player Terms.
       </p>
       <br/>
       <br/>
       <p className='subHeadingTitle'>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;General</p>
       <br/>
       <p className='normalContent'><b>11.1</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These BBC Player Terms, together with the additional terms and information set out in our FAQs, our Privacy Policy and our Cookies Policy and the BBCS Website Terms and Conditions, are all the terms and conditions between you and us in relation to your use of BBC Player, and supersede all previous agreements in relation to your use of BBC Player.
       <br/>
       <br/>
       <b>11.2</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We sometimes update these BBC Player Terms, so please review them regularly. By using the BBC Player, you agree to comply with the then-current version of these BBC Player Terms.
       <br/>
       <br/>
       <b>11.3</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These Terms and Conditions do not give any enforceable rights to any person(s) who is not party to them.
       <br/>
       <br/>
       <b>11.4</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any dispute, controversy, proceedings or claim arising out of or in connection with these BBC Player Terms, including any question regarding their existence, validity or termination, will be governed by and construed in accordance with the laws of Singapore and the parties irrevocably submit to the exclusive jurisdiction of the courts of Singapore.
       <br/>
       <br/>
       <b>11.5</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you have any questions about BBC Player, please see the FAQs. For TM Net or Astro subscription and login issues, please contact TM Net and Astro respectively.
       <br/>
       <br/>
       <b>Version dated 5 October 2022</b>
       </p>
       <br/>
       <br/>
       <br/>
       </div>
       </div>
       </div>
    </div>
)
}

export default TermsAndConditions