import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFocusManager from './useFocusManager';
import { useLocation } from 'react-router-dom'
import { titleCase, resetScroll} from '../utils/utils'


const useFocusParent = (initialFocus, focusNavMap, hasFocusProp, appLevelFocus, toggleAppExit, toggleBack, showAstroInfo, setShowAstroInfo) => {
  const [getNextFocusItem] = useFocusManager(focusNavMap)
  const location = useLocation();

  const [suppressed, setSuppressed] = useState(false)

  const [currentFocusOwner, changeFocusOwner] = useState(initialFocus);

  const [unhandledFocusRequesFromChild, setFocusRequest] = useState({ relieveFocus: false, action: "" })

  const [unhandledAction, setUnHandledAction] = useState({ action: "" })

  let navigate = useNavigate()

  useEffect(() => {
    let hadBacked = false
    let shouldBack = false
    console.log("relieved focus");
    if (unhandledFocusRequesFromChild.relieveFocus) {

      console.log(focusNavMap);
      console.log(currentFocusOwner);
      console.log(unhandledFocusRequesFromChild);
      console.log(unhandledFocusRequesFromChild.action);
      console.log(location.pathname)

      if ((unhandledFocusRequesFromChild.action === 'BACK' || unhandledFocusRequesFromChild.action.action === 'BACK') && showAstroInfo){
        setShowAstroInfo(false)
        return
      }
      let newFocusOwner = undefined

      if (currentFocusOwner === 'SideMenu' && unhandledFocusRequesFromChild.action === 'RIGHT' && location.pathname !== '/home') {
        newFocusOwner = titleCase(location.pathname.split('/')[1])
      } else {
        newFocusOwner = getNextFocusItem(currentFocusOwner, unhandledFocusRequesFromChild.action)
      }
      console.log(newFocusOwner)
      if ((unhandledFocusRequesFromChild.action === 'BACK' || unhandledFocusRequesFromChild.action.action === 'BACK') &&
        (focusNavMap["Home"] === undefined || focusNavMap["Player"] !== undefined)) {


        if(window.history.state.path === undefined){
          if(currentFocusOwner === 'SideMenu' && !hadBacked){
            toggleAppExit()
            return
          }
         
          //navigate(-1, {replace : true})
          if(toggleBack && newFocusOwner !== 'SideMenu'){
            hadBacked = true
           shouldBack = true
          }
          setSuppressed(false)
        }else{
          console.log("appexit")
          if( location.pathname === '/home'){
            toggleAppExit()
          }
          
         
        }
        
      }



      console.log("The new focsu owner is:" + newFocusOwner)
      //handle focus lost case here
      if (newFocusOwner !== undefined) {
        if (appLevelFocus && (unhandledFocusRequesFromChild.action === 'BACK' || unhandledFocusRequesFromChild.action.action === 'BACK')) {
          appLevelFocus(newFocusOwner)

        } else {

          if(!suppressed){
            changeFocusOwner(newFocusOwner)
            console.log("newFocusOwner " + newFocusOwner)
          }
          
        }
        if(shouldBack){
          toggleBack()
        }
      } else {
        if( location.pathname === '/home' && unhandledFocusRequesFromChild.action === 'BACK' && window?.history?.state?.path === undefined){
          if(!hadBacked){
            toggleAppExit()
          }
        }else{
          setUnHandledAction(unhandledFocusRequesFromChild.action)
        }
        
      }
      console.log('Old Focus owner ' + currentFocusOwner)
    }

  }, [unhandledFocusRequesFromChild])


  return [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction, setSuppressed]


}
export default useFocusParent
