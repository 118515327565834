import './SecurityAnswer.css'
import SecurityAnswerKeyBoard from './SecurityAnswerKeyBoard'
import React, { useState,useEffect } from 'react';
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import useFocusParent from '../../../hooks/useFocusParent'

const SecurityAnswer = (props) => {
    let focusNavMap = {
        SecurityAnswerKeyBoard :{

        },
        Settings :{
            "LEFT" : "SettingsSideMenu"
        }
      }
      const [currentFocusOwner, changeFocusOwner] = useState('')

   // const [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction] = useFocusParent('', focusNavMap)
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT"], props.bubbleFocusUP, props.hasFocus)
    
    useEffect(() => {
        console.log("inside use effect of securoty answer:"+props.hasFocus)
        if (props.hasFocus) {
            setFocus()
            changeFocusOwner('SecurityAnswerKeyBoard')
            console.log('Key Board Gained Focus')
        } else {
            changeFocusOwner('')
            console.log('Key Board Lost Focus')
        }
    }, [props.hasFocus])

    return <div className="securityAnswersContainer">
        <div className={`${props.screenState.showAnswers["forgotten"] ? 'checkAnsLineOne' : 'lineOne'}`}>{props.lineOne}</div>
        <div className={`${props.screenState.showAnswers["forgotten"] ? 'checkAnsLineTwo' : 'question'}`}>{props.parentLabel}</div>
        <SecurityAnswerKeyBoard backNavFromSettings={props.backNavFromSettings} hasFocus={currentFocusOwner === "SecurityAnswerKeyBoard"} bubbleFocusUP={props.bubbleFocusUP} forgotten={props.forgotten} screenState = {props.screenState} setScreenState = {props.setScreenState}/>
    </div>
}


export default SecurityAnswer