import "./Card.css";
import { getContentItemMetadata } from "../../utils/getMetadata";
import { memo } from "react";
//hard coded low image to avoid crash
import LowResThumb from '../../assets/images/bbc-default-thumbnail.png'
import { useEffect, useState } from "react";

const Card = ({ item, isSelected }) => {
  let metadata = getContentItemMetadata(item, "Tile");

  const [imageURL, setImageURL] = useState(LowResThumb)
  
  useEffect(()=>{
    if (item.lowResTile && item.lowResTile.image){
       setImageURL(item.lowResTile.image["url"])
    }else {
      if(item.images){
            const result = item.images.filter(image => image.name.includes("860x504"));
            console.log(result)
            if( result.length > 0 ){
              setImageURL(result[0].url)
            }else{
              setImageURL(LowResThumb)
            }
          }
    }
  },[item])


  return (
    <div className={"card" }>
      <div>
        <img className={(isSelected ? "card-onSelected" : "")} src={imageURL} alt="" />
          <div className= { (item.__typename === "Episode" && item.progress) ? "w3-light-grey" : "w3-light-grey hide"}>
            <div className="w3-blue" style = {{width:item.progress * 100 + "%"}}></div>
          </div> 
    </div>
      <div className="card-body">
        <p className={"card-title" + (isSelected ? " card-title-selected" : "")}>{metadata.header}</p>
        <p className="card-season">{metadata.meta1 ? metadata.meta1 : ""}</p>
        <p className="card-availability">{metadata.meta2}</p>
      </div>
    </div>
  );
};

export default memo(Card);
