import React, { useEffect, useContext, useState, useReducer } from 'react'
import Event from "./Event"
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';
import { useNavigate } from "react-router-dom";
import { AstroBBCContext } from '../../App'
import Modal from '../../components/Modals/Modal'
import focusIndexOkPressed from '../../utils/IndexHandler'
import {resetScroll, getStorageItem, setStorageItem} from '../../utils/utils'

const MAX_VISIBLE_ITEMS = 6

const initialListState = {
    startIndex : 0,
    endIndex : MAX_VISIBLE_ITEMS
};

function visibilityStateReducer(state, action) {
    let start = state.startIndex, end = state.endIndex
  switch (action.type) {  
    case 'scrollDown':
        console.log(state)
        if(state.endIndex !== action.maxItems - 1 && action.focusIndex >= MAX_VISIBLE_ITEMS - 1 ){
            start = action.end ? action.end - MAX_VISIBLE_ITEMS : state.startIndex + 1
            end =  action.end ? action.end : state.endIndex + 1
        }
        return {startIndex: start , endIndex : end };
    case 'scrollUp':
        if(state.startIndex !== 0 ){
            start = state.startIndex - 1
            end = state.endIndex - 1
        }
      return {startIndex: start, endIndex : end};
    default:
      console.error("unknown action")
  }
}




const EventVerticalList = (props) => {

    let hasScrolled = false
    //pagination
    const [visibilityState, updateVisibilityState] = useReducer(visibilityStateReducer, initialListState);

    const isInVisibleRange = (index) => {
        return index >= visibilityState.startIndex && index <= visibilityState.endIndex
    }

    const shouldBeVisible = (index) => {
        return isInVisibleRange(index)
    }

    const [isOpen, setIsOpen] = useState(false)
    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['DOWN', 'UP', 'OK'], props.bubbleFocusUP, props.hasFocus)
    const appData = useContext(AstroBBCContext);
    const navigate = useNavigate()
    const savedSelectedEpisode = getStorageItem('savedEpisodeIndex') || 0;
    let [focusIndex, setfocusIndex] = useState(Number(savedSelectedEpisode));
    const { innerHeight: viewportHeight } = window;

    useEffect(()=>{
        if(savedSelectedEpisode){
            if(savedSelectedEpisode < 1){
                updateVisibilityState({type : "scrollUp", maxItems : props.season.episodes.length})
            }else{
                if( savedSelectedEpisode >= MAX_VISIBLE_ITEMS){
                    updateVisibilityState({ type : "scrollDown", maxItems : props.season.episodes.length, focusIndex: Number(savedSelectedEpisode), end: Number(savedSelectedEpisode)});
                    setTimeout(()=>{
                        window.scrollTo(0, (document.body.scrollHeight || document.documentElement.scrollHeight ));
                    }, 10)
                }else{
                    updateVisibilityState({type : "scrollDown", maxItems : props.season.episodes.length, focusIndex: Number(savedSelectedEpisode) })
                    if(!hasScrolled){
                        scrollElement("DOWN", 700);
                        hasScrolled = true
                    }
                }          
        }
        }
    },[])
    
    const events = [
        {
            title: "Great Primer - Series header",
            duration: "53",
            parentalGuidance: "yes",
            resolution: "HD",
            synopsis: "Body copy - Giles and Monica arrive at the Royal Mansour, one of the world's most discrete hotels",
            thumbnailUrl: "https://cdn.zeplin.io/62cd3c7c5e59d618ce7684a6/assets/83302e75-9891-49b3-b6ca-3a3af1d7eb3f.png",
            assetId: "1"
        },
        {
            title: "Great Primer - Second Event",
            duration: "120",
            parentalGuidance: "no",
            resolution: "HD",
            synopsis: "Body copy - Giles and Monica arrive at the Royal Mansour, one of the world's most discrete hotels",
            thumbnailUrl: "https://cdn.zeplin.io/62cd3c7c5e59d618ce7684a6/assets/83302e75-9891-49b3-b6ca-3a3af1d7eb3f.png",
            assetId: "1"
        },
    ];
    useEffect(() => {

        if (keyState.UP) {
            updateVisibilityState({type : "scrollUp", maxItems : props.season.episodes.length})
            if (focusIndex === 0) {
                if (props.focusSeasonSelector){
                    props.focusSeasonSelector(true)
                }
               
                props.bubbleFocusUP({ relieveFocus: true, action: "UP" })
            } else {
                setfocusIndex(focusIndex - 1)
            }
            let parentElement = document.getElementById("verticalDiv");
            let currElement = parentElement.children[focusIndex]
            if(currElement && currElement?.clientHeight){
                scrollElement("UP", currElement.clientHeight - 20)
            }
            
        }

        if (keyState.DOWN) {
            
            focusIndex === props.season.episodes.length - 1 ? setfocusIndex(props.season.episodes.length - 1) : setfocusIndex(focusIndex + 1)
            updateVisibilityState({type : "scrollDown", maxItems : props.season.episodes.length, focusIndex : focusIndex})
            if(!hasScrolled){
                scrollElement("DOWN", 700);
                hasScrolled = true
            }
        }


        if (keyState.OK) {
            setStorageItem('savedSeasonIndex', props.selectedSeason);
            setStorageItem('savedEpisodeIndex', focusIndex);
            console.log("Selected event " + JSON.stringify(events[focusIndex]))
            if (appData.auth.hasActiveSubscription(props.season.episodes[focusIndex])) {

                appData.auth.hasAuthorizedPackage(props.season.episodes[focusIndex]).then(data => {
                    if (data) {
                        props.changeFocusOwnerApp("Player")
                        props.setPlayerSource('Series')
                        resetScroll()
                        let menuFocusDetails =  getStorageItem("sidemenu-focus-details", false, true)
                        if(menuFocusDetails){
                            menuFocusDetails.playerDirect = true  
                            setStorageItem("sidemenu-focus-details", menuFocusDetails, false, true)
                        }
                        navigate('/player', { state: { title: "Show title", subtitle: "Subtitle", source: "Series", metadata: props.season.episodes[focusIndex] } });
                    } else {
                        setIsOpen(true)
                        setSuppressed(true)
                    }
                })

            }else{
                if(appData.auth.isUserSignedIn()){
                    setIsOpen(true)
                    setSuppressed(true)
                    //navigate('/player', { state: { title: "Show title", subtitle: "Subtitle", source: "Series", metadata: props.season.episodes[focusIndex] } });
                }else{
                    setIsOpen(true)
                    setSuppressed(true)
                    navigate('/')
                    props.changeFocusOwnerApp('Splash')
                }
            }
            //nextPath(MenuItems[focusIndex].path)
            //setMenuSelection(focusIndex)
        }

    }, [keyState]);

    useEffect(() => {
        if (props.hasFocus) {
            if(props.focusSeasonSelector){
                if (focusIndexOkPressed && focusIndexOkPressed["didSelect"]){
                    setfocusIndex(0)
                }else {
                    setfocusIndex(focusIndex)
                }
                props.focusSeasonSelector(false)
            }
            setFocus()
        } else {
            setfocusIndex(focusIndex);
            focusIndexOkPressed["didSelect"] = false
        }
    }, [props.hasFocus])

    const redirectToSignIn = () => {
        setIsOpen(false); 
        setSuppressed(true)
        appData.displayAstroInfo(true)
    }

    useEffect(()=>{
        if(!appData.showAstroInfo){
          setSuppressed(false)
        }
      },[appData.showAstroInfo])


    //to scroll the component on keyboard down and up
    function scrollElement(scrollDirection, scrollDistance) {
        scrollDirection = scrollDirection === "UP" ? 1 : -1;
        const pos = window.pageYOffset;

        window.scroll(        {
            top: pos - scrollDistance * scrollDirection,
            left: 0,
            behavior: 'auto'
          });
    }

    return (
        <div id="verticalDiv" className="event-vertical-list-container">
            {props.season.episodes.map((event, index) => {
                
                return shouldBeVisible(index) && <Event
                key={index}
                data={event}
                index={index}
                focusIndex={focusIndex}
                hasFocus={props.hasFocus} />
    
            })}
            <Modal
                iswhiteBg={true}
                open={isOpen}
                hasFocus={isOpen}
                onClose={redirectToSignIn}
                showBtwo={true}
                buttonOneText={"Upgrade now"}
                buttonTwoText={"Upgrade later"}
                onCancel={() => { setIsOpen(false); setSuppressed(false) }}
                title={"You currently don't have the right pack to watch this content"}
            >
            </Modal>
        </div>
    )
}

export default EventVerticalList

