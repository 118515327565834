import React , {useEffect, useState, useCallback, useReducer, useRef} from 'react'
import eventBus from "../../utils/EventBus";
import './button.css'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent'

const AstroButton = ({data,bubbleFocusUP, hasFocus }) =>{

    const [selected, setSelected] = useState(false);

    const [keyState, setFocus] = useRemoteKeyEvent(['OK'], bubbleFocusUP, hasFocus )

    const [className, setClassName] = useState({selected : '', unselected : ''})

    useEffect(()=>{
        if(data.customClass){
            setClassName({selected : `astro-button-${data.customClass} astro-button-selected` , unselected : `astro-button-${data.customClass} astro-button-unselected` })
        }else{
            setClassName({selected : 'astro-button astro-button-selected' , unselected : 'astro-button astro-button-unselected' })
        }
    },[])

    useEffect(()=>{
        if(hasFocus){
            setFocus()
        }
    },[hasFocus])

    return(
        <div className={`${hasFocus ? className.selected : className.unselected}`}>
            {
                data.Icon && 
                <data.Icon className={'astro-button-svg'}  />
            }
            {
                data.icon && 
                <img className="astro-button-icon" src={data.icon} />
            }
            
            <p className="astro-button-text" >
                {data.text}
            </p>
        </div>
    )
}

export default AstroButton

