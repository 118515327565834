export function setStorageItem(key, value, encoded = false, json = false, inSession = false) {
    const storage = inSession ? sessionStorage : localStorage;
    let val = value;
    if(json) val = JSON.stringify(val);
    if(encoded) val = window.btoa(val);
    storage.setItem(key, val);
  };
  
  export function getStorageItem(key, encoded = false, json = false, inSession = false) {
    const storage = inSession ? sessionStorage : localStorage;
    let value = storage.getItem(key);
    if(value === null) return null;
    if(encoded) value = window.atob(value);
    if(json) {
      try {
        value = JSON.parse(value);
      } catch(err) {
        return null;
      }
    }
    return value;
  };
  
  export function removeStorageItem(key, inSession = false) {
    const storage = inSession ? sessionStorage : localStorage;
    storage.removeItem(key);
  };

  export function titleCase(str) {
    let splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
 }

 export function resetScroll(){
  if(window.pageYOffset > 0) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'})
  }
 }

 const ratingAgeMap = {
   "R21" : 21,
   "18" : 18,
   "PG13" : 13,
   "U" : 0
 }



 export function isContentPGLocked(existingLevel, contentLevelAge){
   let existingLevelAge = ratingAgeMap[existingLevel]
   if(contentLevelAge > existingLevelAge){
     return true
   }
   return false
 }