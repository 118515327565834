import { useState,useEffect } from "react"
import './PinKeyBoard.css';
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';
import { getStorageItem,removeStorageItem,setStorageItem } from '../../utils/utils';
import Modal from '../Modals/Modal';
import DeleteImage from "../../assets/images/DeleteKey.png";

const PinKeyBoard = (props) => {
    const [pinText, setPinText ] = useState(["","","",""])
    const [showDiv,setShowDiv] = useState(false)
    const [changeButtonColor,setChangeButtonColor] = useState(false)
    const [cursor, setCursor ] = useState(0)
    const [isModalOpen,setOpen] = useState(false);
    var [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    const pinKeys = [
        [ 
            { "keycode": "1", "xIndex": 0, "yIndex": 0 },
            { "keycode": "2", "xIndex": 1, "yIndex": 0 },
            { "keycode": "3", "xIndex": 2, "yIndex": 0 },
            { "keycode": "4", "xIndex": 3, "yIndex": 0 },
            { "keycode": "5", "xIndex": 4, "yIndex": 0 },
            { "keycode": "6", "xIndex": 5, "yIndex": 0 },],
        [
            { "keycode": "7", "xIndex": 0, "yIndex": 1 },
            { "keycode": "8", "xIndex": 1, "yIndex": 1 },
            { "keycode": "9", "xIndex": 2, "yIndex": 1 },
            { "keycode": "0", "xIndex": 3, "yIndex": 1 },
            { "keycode": "<", "xIndex": 4, "yIndex": 1, "span": 2 }
        ]
    ]

    var localSettings = getStorageItem("pinSettings", true, true);

    useEffect(() => {
       console.log("Getting the local value:"+JSON.stringify(localSettings))
    }, [localSettings])
    
    if(props.screenState.showChoosePin["changeConfirmation"]){
        pinKeys.push([{ "keycode": "Next", "xIndex": 0, "yIndex": 2, "span":6 }, 
        {"keycode": "Forgotten your PIN?", "xIndex": 0, "yIndex": 3, "span":6}])
    } else if(props.screenState.showChoosePin["disable"]) {
        pinKeys.push([{ "keycode": "Continue and turn off PIN", "xIndex": 0, "yIndex": 2 , "span":6}])
        pinKeys.push([{"keycode": "Forgotten your PIN?", "xIndex": 0, "yIndex": 3, "span":6}])
    }else if (props.screenState.showChoosePin["checkChangePin"]){
        pinKeys.push([{ "keycode": "Next", "xIndex": 0, "yIndex": 2, "span":6 }])
        pinKeys.push([{"keycode": "Forgotten your PIN?", "xIndex": 0, "yIndex": 3, "span":6}])
    }else if (props.screenState.showChoosePin["changeNew"]){
        pinKeys.push([{ "keycode": "Set new Pin", "xIndex": 0, "yIndex": 2, "span":6 }])
        pinKeys.push([{"keycode": "Forgotten your PIN?", "xIndex": 0, "yIndex": 3, "span":6}])
    }else if (props.screenState.showChoosePin["createNew"]) {
        //When user freshly creates a pin this is called
        pinKeys.push([{ "keycode": "Next", "xIndex": 0, "yIndex": 2 , "span":6}])
    }else if (props.screenState.showChoosePin["forgotten"]){
        pinKeys.push([{ "keycode": "Confirm and complete", "xIndex": 0, "yIndex": 2, "span":6 }])
    }else if (props.screenState.showChoosePin["changeRatingConfirmation"]){
        //This is when user wants to change his rating levels
        pinKeys.push([{ "keycode": "Next", "xIndex": 0, "yIndex": 2, "span":6 }])
        pinKeys.push([{"keycode": "Forgotten your PIN?", "xIndex": 0, "yIndex": 3, "span":6}])
    }
    
    useEffect(() => {
        console.log("Inside Pin Keyboard:"+props.hasFocus)
        if (props.hasFocus) {
            console.log("Pin Keyboard gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
        } else {
            setFocusPoint({ "x": -1, "y": -1})
            console.log('Pin Key Board Lost Focus')
        }
    }, [props.hasFocus])

    useEffect(() => {
        if (cursor >= 0){
            var newArray = [...pinText]
            if (pinKeys[focusPoint.y][focusPoint.x].keycode === "<"){
                newArray[cursor] = ""
            }else {
                newArray[cursor - 1] = pinKeys[focusPoint.y][focusPoint.x].keycode
            }
            setPinText(newArray);
        }
    }, [cursor])

    useEffect(() => {
        if(changeButtonColor){
            setFocusPoint({"y":2,"x":0})
        }
    }, [changeButtonColor])

    useEffect(() => {
        if (showDiv && cursor === 1){
            setShowDiv(false)
        }
        //To Do fetch the pin value here
        var pinToCheck = (localSettings && localSettings["pintoSave"]) ? localSettings["pintoSave"] : null
        if (props.screenState.showChoosePin["disable"] && pinToCheck && pinText.join("").length > 0){
            if (pinText.join("") === pinToCheck){
                setShowDiv(false)
                setChangeButtonColor(true)
            }else {
                setShowDiv(true)
            }
        }else {
            //For adding pin
        }
        
    }, [pinText])

    const removePinScreen = () => {
        let oldState = props.screenState
        oldState.showChoosePin["visibility"] = false
        oldState.showSecurity["visibility"] = true
        props.setScreenState({...oldState})
    }

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        if (keyState.RIGHT) {
            let newFocusPoint = {}
            if ((currentFocuspoint.x < 5 && currentFocuspoint.y === 0) || (currentFocuspoint.x < 4 && currentFocuspoint.y === 1) || (currentFocuspoint.x === 0 && (currentFocuspoint.y < 2))){
                newFocusPoint.x = currentFocuspoint.x + 1
                newFocusPoint.y = currentFocuspoint.y
                setFocusPoint(newFocusPoint)
            }
            
        }
        else if (keyState.LEFT) {
            let newFocusPoint = {}
            if (currentFocuspoint.x > 0) {
                newFocusPoint.x = currentFocuspoint.x - 1
                newFocusPoint.y = currentFocuspoint.y
                setFocusPoint(newFocusPoint)
            }else{
                props.bubbleFocusUP({relieveFocus : true, action : "LEFT"})
            }
        }
        else if (keyState.DOWN) {
            let newFocusPoint = {}
            if (currentFocuspoint.y === 0) {
                newFocusPoint.y = currentFocuspoint.y + 1
                if (currentFocuspoint.x === 5){
                    newFocusPoint.x = 4
                }else {
                    newFocusPoint.x = currentFocuspoint.x
                }
                setFocusPoint(newFocusPoint)
            }else if ((currentFocuspoint.y > 0 && currentFocuspoint.y < 3) && (props.screenState.showChoosePin["changeNew"] || props.screenState.showChoosePin["checkChangePin"] || props.screenState.showChoosePin["disable"] || props.screenState.showChoosePin["changeRatingConfirmation"])){
                newFocusPoint.y = currentFocuspoint.y + 1
                newFocusPoint.x = 0
                setFocusPoint(newFocusPoint)
            }else if (currentFocuspoint.y === 1 && (props.screenState.showChoosePin["createNew"] || props.screenState.showChoosePin["forgotten"] || props.screenState.showChoosePin["changeNew"])){
                if (cursor === 4){
                    newFocusPoint.y = currentFocuspoint.y + 1
                    newFocusPoint.x = 0
                    setFocusPoint(newFocusPoint)
                }
                
            }
            console.log("The new focus point is:"+JSON.stringify(newFocusPoint))
        }
        else if (keyState.UP) {
            var newFocusPoint = {}
            console.log("Inside UP the current focus point is:"+JSON.stringify(currentFocuspoint))
            if (currentFocuspoint.y > 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            console.log("The Key List is :"+JSON.stringify(pinKeys))
            //To Handle Deletion
            if (pinKeys[focusPoint.y][focusPoint.x].keycode === "<"){
                if (cursor >= 0){
                    setShowDiv(false)
                    setCursor(cursor - 1)
                    setChangeButtonColor(false)
                }
            }else if (pinKeys[focusPoint.y][focusPoint.x].keycode === "Forgotten your PIN?" ){
                var oldState = props.screenState
                oldState.showChoosePin["visibility"] = false
                oldState.showChoosePin["checkChangePin"] = false
                oldState.showChangePinSecurity["visibility"] = false
                oldState.showChoosePin["checkChangePin"] = false
                oldState.showAnswers["visibility"] = true
                oldState.showAnswers["forgotten"] = true
                //Adding this for the case when forgotten pin is clicked from Disable PIN page
                oldState.showChoosePin["disable"] = false
                oldState.showAnswers["labelData"] = {
                    "lineOne":"Please answer the security questions below to reset your PIN",
                    "parentLabel":(localSettings && localSettings["securityQuestion"]) ? localSettings["securityQuestion"] : ""
                }
                props.setScreenState({...oldState}) 
            }else if (pinKeys[focusPoint.y][focusPoint.x].keycode === "Set new Pin" || pinKeys[focusPoint.y][focusPoint.x].keycode === "Next" || pinKeys[focusPoint.y][focusPoint.x].keycode === "Confirm and complete" || pinKeys[focusPoint.y][focusPoint.x].keycode === "Continue and turn off PIN"){
                //Handle Next page Movements
                if(cursor === 4){
                    if (props.screenState.showChoosePin["checkChangePin"]){
                        let dataToCheck = (localSettings && localSettings["pintoSave"]) ? localSettings["pintoSave"] : null
                        if (dataToCheck && dataToCheck === pinText.join("")){
                            if (props.screenState.showChoosePin["checkChangePin"]){
                                setChangeButtonColor(false);
                                var oldState = props.screenState
                                oldState.showChoosePin["visibility"] = true
                                oldState.showChoosePin["checkChangePin"] = false
                                oldState.showChoosePin["changeNew"] = true
                                oldState.showChoosePin["labelData"] = {
                                    "lineOne":"2 of 2 Change PIN",
                                    "parentLabel":"Please enter your new PIN"
                                }
                                props.setScreenState({...oldState})
                                setCursor(0)
                                setPinText(["","","",""]);
                                setFocusPoint({ "x": 0, "y": 0})
                            }
                            //Pin check successfull show user the pin screen again to save new pin data
                        }else {
                            setCursor(0)
                            setPinText(["","","",""]);
                            setShowDiv(true)
                        }
                        
                    }else if (props.screenState.showChoosePin["changeRatingConfirmation"]){
                        //To do check if pin is correct
                        let dataToCheck = (localSettings && localSettings["pintoSave"]) ? localSettings["pintoSave"] : null
                        console.log("The Pin is:"+ JSON.stringify(localSettings))
                        if (dataToCheck && dataToCheck === pinText.join("")){
                            var oldState = props.screenState
                            oldState.showChoosePin["visibility"] = false
                            oldState.showChoosePin["changeRatingConfirmation"] = false
                            oldState.showRatingSelector["visibility"] = true
                            oldState.showRatingSelector["forgotten"] = true
                            oldState.showRatingSelector["labelData"] = {
                                "lineOne":"2 of 2: Set new rating level ",
                                "lineTwo":""
                            }
                            props.setScreenState({...oldState})
                        }
                    }else if (props.screenState.showChoosePin["forgotten"]){
                        localSettings["pintoSave"] = pinText.join("")
                        setStorageItem("pinSettings",localSettings,true,true)
                        setOpen(true);
                        setSuppressed(true); 
                    }
                    else if (props.screenState.showChoosePin["disable"] && !showDiv){
                        setOpen(true)
                        setSuppressed(true)
                        //Remove The Local data here
                        removeStorageItem("pinSettings")
                    }else if (props.screenState.showChoosePin["changeNew"]) {
                        setOpen(true)
                        setSuppressed(true)
                        if (localSettings){
                            localSettings["pintoSave"] = pinText.join("")
                            setStorageItem("pinSettings",localSettings,true,true)
                        }
                        
                    }
                    else {
                        if (props.screenState.showChoosePin["createNew"] || props.screenState.showChoosePin["changeNew"]){
                            var oldState = props.screenState
                            oldState.showChoosePin["visibility"] = false
                            oldState.showChoosePin["createNew"] = false
                            oldState.showChoosePin["changeNew"] = false
                            //Save pin here to local storage if the setting exists already.. else just save it in the map 
                            //and only save it once the Flow 1 is complete
                            if (localSettings){
                                //Flow 2 and 3 
                                oldState.showChoosePin["forgotten"] = false
                                oldState.showChoosePin["visibility"] = false
                                oldState.showChangePinSecurity["visibility"] = true
                                localSettings["pintoSave"] = pinText.join("")
                                setStorageItem("pinSettings",localSettings,true,true)
                            }else {
                                //Flow 1
                                oldState.showSecurity["visibility"] = true
                                oldState.tempSetting["pintoSave"] = pinText.join("")
                            }
                            props.setScreenState({...oldState})
                           
                        }
                    }
                }   
            } else {
                if (cursor < 4){
                    setCursor(cursor + 1)
                    //Handle next key presses here
                    console.log("The data is:"+JSON.stringify(props.screenState.showChoosePin))
                    if(cursor === 3){
                        //Change keyboard text colour if the typed count reaches 4 while setting up the new pin
                        if (props.screenState.showChoosePin["createNew"] || props.screenState.showChoosePin["checkChangePin"] || props.screenState.showChoosePin["forgotten"] || props.screenState.showChoosePin["disable"] || props.screenState.showChoosePin["changeRatingConfirmation"] || props.screenState.showChoosePin["changeNew"]){
                            setChangeButtonColor(true)
                        }
                    }
                }
            }
        
        }  else if (keyState.BACK) {
            props.backNavFromSettings()
        }
    }, [keyState]);

    return (
        <div>
            <div className = "pinTextContainer">{
                pinText.map((item , index) => {
                    return (index === cursor) ? <div className="cursorFocused">{item}</div>  : <div>{item}</div> 
                })
                }
            </div>
            <div  className={`${showDiv ? 'errorDiv' : 'errorDivHide'}`}>
                <div className="errorLineOne">Sorry that doesn't look right</div>
                <div className="errorLineTwo">Please try entering again</div>
            </div>
            <div className = "keyboardPanel"> {
                 pinKeys.map((row, rowIndex) => {
                    return (row.map((item, colIndex) => {
                        var style = "pinKeyBoardButton"
                        
                        if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                            style = style + " focusedElement"
                        }

                        if (item.span === 2 ) {
                            style = style + " deleteKeyForPin"
                            return <div className={style}>
                                <img className="deleteImage" src ={DeleteImage}/>
                            </div>
                        }
                       
                        if (item.span === 6){
                            if (item.keycode === "Set new Pin" || item.keycode === "Next" || item.keycode === "Confirm and complete" || item.keycode === "Continue and turn off PIN"){
                                style = style + " nextKey"
                                if (changeButtonColor){
                                    style = style + " selectedKey"
                                }
                            }else {
                                style = style + " forgottenKey"
                            }
                            
                        }
                        return <div key={'key' + rowIndex + "_" + colIndex} className={style}>{item.keycode}</div>
                            
                    }))
                })
            }
            </div>
            <Modal open={isModalOpen}  hasFocus={isModalOpen} onClose={() => {
                setOpen(false);
                setSuppressed(false); 
                var oldState = props.screenState 
                if (props.screenState.showChoosePin["forgotten"] || props.screenState.showChoosePin["changeNew"]){
                    //For forgotten pin flow
                    oldState.showChoosePin["visibility"] = false
                    oldState.showChoosePin["forgotten"] = false
                    oldState.showChangePinSecurity["visibility"] = true
                    oldState.showChoosePin["changeNew"] = false
                }else {
                    //For disable pin flow
                    oldState.showChoosePin["visibility"] = false
                    oldState.showSetpin["visibility"] = true
                    oldState.showChoosePin["disable"] = false
                }
                props.setScreenState({...oldState})
                }} showBtwo = {false} buttonOneText = {"Continue"} title = {"Your Settings have successfully been updated"}>
            </Modal>
        </div>
        
    )
}

export default PinKeyBoard