import './CustomButton.css';
import {useEffect, useState} from 'react'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';


const CustomButton = ({name,hasFocus, bubbleFocusUP, onPress}) => {

    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['OK'], bubbleFocusUP, hasFocus)


    useEffect(() => {
        if (hasFocus) {
            setFocus()
        } else {

        }
    }, [hasFocus])

    useEffect(() => {
       
        if (keyState.OK) {
            console.log("press play")
            onPress()
        }
    }, [keyState]);


    return(
     <div className="custom-button-container">
      <button className={`${hasFocus ? 'custom-button-selected' : 'custom-button-unselected'}`}>{name}</button>
     </div>
    )
}

export default CustomButton