import React, { useEffect, useState } from 'react'
import './keyboard.css';
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';
import DeleteSearchImage from '../../assets/images/DeleteKey.png'
import SpaceSearch from '../../assets/images/spaceImage.png'


const KeyBoard = (props) => {

    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    let [smallKBFLag, setsmallKBFLag] = useState(true);
    let [prevXpoint, setprevXpoint] = useState(null);
    let [specialKBFlag, setspecialKBFlag] = useState(false);
    let [keyBoardKeys, setNewKeys] = useState([
        [
            { "keycode": "1", "xIndex": 0, "yIndex": 0 },
            { "keycode": "2", "xIndex": 1, "yIndex": 0 },
            { "keycode": "3", "xIndex": 2, "yIndex": 0 },
            { "keycode": "a", "xIndex": 3, "yIndex": 0 },
            { "keycode": "b", "xIndex": 4, "yIndex": 0 },
            { "keycode": "c", "xIndex": 5, "yIndex": 0 },
            { "keycode": "d", "xIndex": 6, "yIndex": 0 },
            { "keycode": "e", "xIndex": 7, "yIndex": 0 },
            { "keycode": "f", "xIndex": 8, "yIndex": 0 },
            { "keycode": "g", "xIndex": 9, "yIndex": 0 },
            { "keycode": "h", "xIndex": 10, "yIndex": 0 },
            { "keycode": "i", "xIndex": 11, "yIndex": 0 },
        ],
        [
            { "keycode": "4", "xIndex": 0, "yIndex": 1 },
            { "keycode": "5", "xIndex": 1, "yIndex": 1 },
            { "keycode": "6", "xIndex": 2, "yIndex": 1 },
            { "keycode": "j", "xIndex": 3, "yIndex": 1 },
            { "keycode": "k", "xIndex": 4, "yIndex": 1 },
            { "keycode": "l", "xIndex": 5, "yIndex": 1 },
            { "keycode": "m", "xIndex": 6, "yIndex": 1 },
            { "keycode": "n", "xIndex": 7, "yIndex": 1 },
            { "keycode": "o", "xIndex": 8, "yIndex": 1 },
            { "keycode": "p", "xIndex": 9, "yIndex": 1 },
            { "keycode": "q", "xIndex": 10, "yIndex": 1 },
            { "keycode": "r", "xIndex": 11, "yIndex": 1 },

        ],
        [
            { "keycode": "7", "xIndex": 0, "yIndex": 2 },
            { "keycode": "8", "xIndex": 1, "yIndex": 2 },
            { "keycode": "9", "xIndex": 2, "yIndex": 2 },
            { "keycode": "s", "xIndex": 3, "yIndex": 2 },
            { "keycode": "t", "xIndex": 4, "yIndex": 2 },
            { "keycode": "u", "xIndex": 5, "yIndex": 2 },
            { "keycode": "v", "xIndex": 6, "yIndex": 2 },
            { "keycode": "w", "xIndex": 7, "yIndex": 2 },
            { "keycode": "x", "xIndex": 8, "yIndex": 2 },
            { "keycode": "y", "xIndex": 9, "yIndex": 2 },
            { "keycode": "z", "xIndex": 10, "yIndex": 2 },
            { "keycode": "^", "xIndex": 11, "yIndex": 2 },
        ],
        [
            { "keycode": ",", "xIndex": 0, "yIndex": 3 },
            { "keycode": "0", "xIndex": 1, "yIndex": 3 },
            { "keycode": ".", "xIndex": 2, "yIndex": 3 },
            { "keycode": "@?#", "xIndex": 3, "yIndex": 3, "span": 3 },
            { "keycode": "$", "xIndex": 4, "yIndex": 3, "span": 3 },
            { "keycode": "<", "xIndex": 5, "yIndex": 3, "span": 3 },
        ]

    ])
    const MAX_ROW = 11;
    const MAX_COL = 3;

    let specialCharKeys = [
        [
            { "keycode": "1", "xIndex": 0, "yIndex": 0 },
            { "keycode": "2", "xIndex": 1, "yIndex": 0 },
            { "keycode": "3", "xIndex": 2, "yIndex": 0 },
            { "keycode": "!", "xIndex": 3, "yIndex": 0 },
            { "keycode": "@", "xIndex": 4, "yIndex": 0 },
            { "keycode": "#", "xIndex": 5, "yIndex": 0 },
            { "keycode": "$", "xIndex": 6, "yIndex": 0 },
            { "keycode": "%", "xIndex": 7, "yIndex": 0 },
            { "keycode": "&", "xIndex": 8, "yIndex": 0 },
            { "keycode": "*", "xIndex": 9, "yIndex": 0 },
            { "keycode": "(", "xIndex": 10, "yIndex": 0 },
            { "keycode": ")", "xIndex": 11, "yIndex": 0 },
        ],
        [
            { "keycode": "4", "xIndex": 0, "yIndex": 1 },
            { "keycode": "5", "xIndex": 1, "yIndex": 1 },
            { "keycode": "6", "xIndex": 2, "yIndex": 1 },
            { "keycode": "_", "xIndex": 3, "yIndex": 1 },
            { "keycode": "-", "xIndex": 4, "yIndex": 1 },
            { "keycode": "+", "xIndex": 5, "yIndex": 1 },
            { "keycode": "=", "xIndex": 6, "yIndex": 1 },
            { "keycode": "{", "xIndex": 7, "yIndex": 1 },
            { "keycode": "}", "xIndex": 8, "yIndex": 1 },
            { "keycode": "[", "xIndex": 9, "yIndex": 1 },
            { "keycode": "]", "xIndex": 10, "yIndex": 1 },
            { "keycode": "|", "xIndex": 11, "yIndex": 1 },

        ],
        [
            { "keycode": "7", "xIndex": 0, "yIndex": 2 },
            { "keycode": "8", "xIndex": 1, "yIndex": 2 },
            { "keycode": "9", "xIndex": 2, "yIndex": 2 },
            { "keycode": ":", "xIndex": 3, "yIndex": 2 },
            { "keycode": ";", "xIndex": 4, "yIndex": 2 },
            { "keycode": "'", "xIndex": 5, "yIndex": 2 },
            { "keycode": ",", "xIndex": 6, "yIndex": 2 },
            { "keycode": ".", "xIndex": 7, "yIndex": 2 },
            { "keycode": "?", "xIndex": 8, "yIndex": 2 },
            { "keycode": "/", "xIndex": 9, "yIndex": 2 },
            { "keycode": "z", "xIndex": 10, "yIndex": 2 },
            { "keycode": "~", "xIndex": 11, "yIndex": 2 },
        ],
        [
            { "keycode": ",", "xIndex": 0, "yIndex": 3 },
            { "keycode": "0", "xIndex": 1, "yIndex": 3 },
            { "keycode": ".", "xIndex": 2, "yIndex": 3 },
            { "keycode": "@?#", "xIndex": 3, "yIndex": 3, "span": 3 },
            { "keycode": "$", "xIndex": 4, "yIndex": 3, "span": 3 },
            { "keycode": "<", "xIndex": 5, "yIndex": 3, "span": 3 },
        ]
    ]

    let capKeys = [
        [
            { "keycode": "1", "xIndex": 0, "yIndex": 0 },
            { "keycode": "2", "xIndex": 1, "yIndex": 0 },
            { "keycode": "3", "xIndex": 2, "yIndex": 0 },
            { "keycode": "A", "xIndex": 3, "yIndex": 0 },
            { "keycode": "B", "xIndex": 4, "yIndex": 0 },
            { "keycode": "C", "xIndex": 5, "yIndex": 0 },
            { "keycode": "D", "xIndex": 6, "yIndex": 0 },
            { "keycode": "E", "xIndex": 7, "yIndex": 0 },
            { "keycode": "F", "xIndex": 8, "yIndex": 0 },
            { "keycode": "G", "xIndex": 9, "yIndex": 0 },
            { "keycode": "H", "xIndex": 10, "yIndex": 0 },
            { "keycode": "I", "xIndex": 11, "yIndex": 0 },
        ],
        [
            { "keycode": "4", "xIndex": 0, "yIndex": 1 },
            { "keycode": "5", "xIndex": 1, "yIndex": 1 },
            { "keycode": "6", "xIndex": 2, "yIndex": 1 },
            { "keycode": "J", "xIndex": 3, "yIndex": 1 },
            { "keycode": "K", "xIndex": 4, "yIndex": 1 },
            { "keycode": "L", "xIndex": 5, "yIndex": 1 },
            { "keycode": "M", "xIndex": 6, "yIndex": 1 },
            { "keycode": "N", "xIndex": 7, "yIndex": 1 },
            { "keycode": "O", "xIndex": 8, "yIndex": 1 },
            { "keycode": "P", "xIndex": 9, "yIndex": 1 },
            { "keycode": "Q", "xIndex": 10, "yIndex": 1 },
            { "keycode": "R", "xIndex": 11, "yIndex": 1 },

        ],
        [
            { "keycode": "7", "xIndex": 0, "yIndex": 2 },
            { "keycode": "8", "xIndex": 1, "yIndex": 2 },
            { "keycode": "9", "xIndex": 2, "yIndex": 2 },
            { "keycode": "S", "xIndex": 3, "yIndex": 2 },
            { "keycode": "T", "xIndex": 4, "yIndex": 2 },
            { "keycode": "U", "xIndex": 5, "yIndex": 2 },
            { "keycode": "V", "xIndex": 6, "yIndex": 2 },
            { "keycode": "W", "xIndex": 7, "yIndex": 2 },
            { "keycode": "X", "xIndex": 8, "yIndex": 2 },
            { "keycode": "Y", "xIndex": 9, "yIndex": 2 },
            { "keycode": "Z", "xIndex": 10, "yIndex": 2 },
            { "keycode": "^", "xIndex": 11, "yIndex": 2 },
        ],
        [
            { "keycode": ",", "xIndex": 0, "yIndex": 3 },
            { "keycode": "0", "xIndex": 1, "yIndex": 3 },
            { "keycode": ".", "xIndex": 2, "yIndex": 3 },
            { "keycode": "@?#", "xIndex": 3, "yIndex": 3, "span": 3 },
            { "keycode": "$", "xIndex": 4, "yIndex": 3, "span": 3 },
            { "keycode": "<", "xIndex": 5, "yIndex": 3, "span": 3 },
        ]

    ]
    let keys = [
        [
            { "keycode": "1", "xIndex": 0, "yIndex": 0 },
            { "keycode": "2", "xIndex": 1, "yIndex": 0 },
            { "keycode": "3", "xIndex": 2, "yIndex": 0 },
            { "keycode": "a", "xIndex": 3, "yIndex": 0 },
            { "keycode": "b", "xIndex": 4, "yIndex": 0 },
            { "keycode": "c", "xIndex": 5, "yIndex": 0 },
            { "keycode": "d", "xIndex": 6, "yIndex": 0 },
            { "keycode": "e", "xIndex": 7, "yIndex": 0 },
            { "keycode": "f", "xIndex": 8, "yIndex": 0 },
            { "keycode": "g", "xIndex": 9, "yIndex": 0 },
            { "keycode": "h", "xIndex": 10, "yIndex": 0 },
            { "keycode": "i", "xIndex": 11, "yIndex": 0 },
        ],
        [
            { "keycode": "4", "xIndex": 0, "yIndex": 1 },
            { "keycode": "5", "xIndex": 1, "yIndex": 1 },
            { "keycode": "6", "xIndex": 2, "yIndex": 1 },
            { "keycode": "j", "xIndex": 3, "yIndex": 1 },
            { "keycode": "k", "xIndex": 4, "yIndex": 1 },
            { "keycode": "l", "xIndex": 5, "yIndex": 1 },
            { "keycode": "m", "xIndex": 6, "yIndex": 1 },
            { "keycode": "n", "xIndex": 7, "yIndex": 1 },
            { "keycode": "o", "xIndex": 8, "yIndex": 1 },
            { "keycode": "p", "xIndex": 9, "yIndex": 1 },
            { "keycode": "q", "xIndex": 10, "yIndex": 1 },
            { "keycode": "r", "xIndex": 11, "yIndex": 1 },

        ],
        [
            { "keycode": "7", "xIndex": 0, "yIndex": 2 },
            { "keycode": "8", "xIndex": 1, "yIndex": 2 },
            { "keycode": "9", "xIndex": 2, "yIndex": 2 },
            { "keycode": "s", "xIndex": 3, "yIndex": 2 },
            { "keycode": "t", "xIndex": 4, "yIndex": 2 },
            { "keycode": "u", "xIndex": 5, "yIndex": 2 },
            { "keycode": "v", "xIndex": 6, "yIndex": 2 },
            { "keycode": "w", "xIndex": 7, "yIndex": 2 },
            { "keycode": "x", "xIndex": 8, "yIndex": 2 },
            { "keycode": "y", "xIndex": 9, "yIndex": 2 },
            { "keycode": "z", "xIndex": 10, "yIndex": 2 },
            { "keycode": "^", "xIndex": 11, "yIndex": 2 },
        ],
        [
            { "keycode": ",", "xIndex": 0, "yIndex": 3 },
            { "keycode": "0", "xIndex": 1, "yIndex": 3 },
            { "keycode": ".", "xIndex": 2, "yIndex": 3 },
            { "keycode": "@?#", "xIndex": 3, "yIndex": 3, "span": 3 },
            { "keycode": "$", "xIndex": 4, "yIndex": 3, "span": 3 },
            { "keycode": "<", "xIndex": 5, "yIndex": 3, "span": 3 },
        ]
    ]
    useEffect(() => {

    }, []);

    useEffect(() => {
        if (specialKBFlag) {
            setNewKeys(specialCharKeys)
        } else {
            if (!smallKBFLag) {
                setNewKeys(capKeys)
            } else {
                setNewKeys(keys)
            }
        }
    }, [specialKBFlag]);

    useEffect(() => {
        console.log("Updating X point")
    }, [prevXpoint]);

    useEffect(() => {
        if (smallKBFLag) {
            setNewKeys(keys)
        } else {
            if (!specialKBFlag) {
                setNewKeys(capKeys)
            } else {
                setNewKeys(specialCharKeys)
            }
        }
    }, [smallKBFLag])

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        if (keyState.RIGHT) {
            let newFocusPoint = {}
            if (currentFocuspoint.x < MAX_ROW && currentFocuspoint.y < 4) {
                newFocusPoint.x = currentFocuspoint.x + 1
                newFocusPoint.y = currentFocuspoint.y
                //Handling Last row focus
                if (newFocusPoint.x < 6 && newFocusPoint.y < 4) {
                    if (newFocusPoint.y === 3) {
                        if (keyBoardKeys[newFocusPoint.y][newFocusPoint.x].keycode === "@?#") {
                            setprevXpoint(3)
                        } else if (keyBoardKeys[newFocusPoint.y][newFocusPoint.x].keycode === "$") {
                            setprevXpoint(6)
                        } else if (keyBoardKeys[newFocusPoint.y][newFocusPoint.x].keycode === "<") {
                            setprevXpoint(9)
                        }
                    }
                    setFocusPoint(newFocusPoint)
                } else {
                    //Handling Previous row focus
                    if (newFocusPoint.y < 3) {
                        setFocusPoint(newFocusPoint)
                    }
                }

            }
        }
        else if (keyState.LEFT) {
            let newFocusPoint = {}
            console.log(currentFocuspoint.x)
            if (currentFocuspoint.x > 0) {
                newFocusPoint.x = currentFocuspoint.x - 1
                newFocusPoint.y = currentFocuspoint.y
                if (newFocusPoint.y === 3) {
                    if (keyBoardKeys[newFocusPoint.y][newFocusPoint.x].keycode === "@?#") {
                        setprevXpoint(3)
                    } else if (keyBoardKeys[newFocusPoint.y][newFocusPoint.x].keycode === "$") {
                        setprevXpoint(6)
                    } else if (keyBoardKeys[newFocusPoint.y][newFocusPoint.x].keycode === "<") {
                        setprevXpoint(9)
                    }
                }
                setFocusPoint(newFocusPoint)
            } else {
                console.log('bubble focus mgmt to parent')
                props.bubbleFocusUP({ relieveFocus: true, action: "LEFT" })
            }
        }
        else if (keyState.DOWN) {
            let newFocusPoint = {}

            if (currentFocuspoint.y < MAX_COL) {
                newFocusPoint.y = currentFocuspoint.y + 1
                if (newFocusPoint.y === 3) {
                    setprevXpoint(currentFocuspoint.x)
                    if (currentFocuspoint.x === 3 || currentFocuspoint.x === 4 || currentFocuspoint.x === 5) {
                        newFocusPoint.x = 3
                    } else if (currentFocuspoint.x === 6 || currentFocuspoint.x === 7 || currentFocuspoint.x === 8) {
                        newFocusPoint.x = 4
                    } else if (currentFocuspoint.x === 9 || currentFocuspoint.x === 10 || currentFocuspoint.x === 11) {
                        newFocusPoint.x = 5
                    } else {
                        newFocusPoint.x = currentFocuspoint.x
                    }
                }
                else {
                    newFocusPoint.x = currentFocuspoint.x
                }
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y > 0) {
                if (currentFocuspoint.y === 3 && prevXpoint != null) {
                    if (currentFocuspoint.x === 3) {
                        newFocusPoint.x = prevXpoint
                    } else if (currentFocuspoint.x === 4) {
                        newFocusPoint.x = prevXpoint
                    } else if (currentFocuspoint.x === 5) {
                        newFocusPoint.x = prevXpoint
                    }
                    else {
                        newFocusPoint.x = currentFocuspoint.x
                    }
                } else {
                    newFocusPoint.x = currentFocuspoint.x
                }
                newFocusPoint.y = currentFocuspoint.y - 1
                setFocusPoint(newFocusPoint)
            } else {
                if (props.shouldGoUp){
                    props.bubbleFocusUP({ relieveFocus: true, action: "UP" })
                }
            }
        }
        else if (keyState.OK) {
            if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "^") {
                if (smallKBFLag) {
                    setsmallKBFLag(false)
                } else {
                    setsmallKBFLag(true)
                }
            } else if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "<") {
                props.setTypedText(prevState => ((prevState !== undefined ? prevState : '').slice(0, -1)))
            }
            else if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "$") {
                props.setTypedText(prevState => ((prevState !== undefined ? prevState : '') + " "))
            } else if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "@?#") {
                if (specialKBFlag) {
                    setspecialKBFlag(false)
                } else {
                    setspecialKBFlag(true)
                }
            }
            else {
                props.setTypedText(prevState => ((prevState !== undefined ? prevState : '') + keyBoardKeys[focusPoint.y][focusPoint.x].keycode))
            }
        }
        else if(keyState.BACK){
            props.bubbleFocusUP({relieveFocus : true, action : 'LEFT'})
        }

    }, [keyState]);

    useEffect(() => {

    }, [keyBoardKeys])

    useEffect(() => {
        if (props.hasFocus) {
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
            console.log('Key Board Gained Focus')
        } else {
            setFocusPoint({ "x": -1, "y": -1 })
            console.log('Key Board Lost Focus')
        }
    }, [props.hasFocus])

    let buttons = []
    return (
        <div>
            <div className="buttonPanel">
                {
                    keyBoardKeys.map((row, rowIndex) => {
                        return (row.map((item, colIndex) => {
                            let style = "keyBoardButton"
                            if (colIndex === 2) {
                                style = style + " gap"
                            }

                            if (item.span === 3 && item.keycode === "@?#") {
                                style = style + "  special"
                            }

                            if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                                style = style + "  btnFocused"
                            }
                            
                            if (item.span === 3 && item.keycode === "$") {
                                style = style + "  space"
                                return <div className={style}>
                                    <img className="spaceImageDiv" src ={SpaceSearch}/>
                                </div>
                            }

                            if (item.span === 3 && item.keycode === "<") {
                                style = style + "  delete"
                                return <div className={style}>
                                    <img className="deleteImageForSearch" src ={DeleteSearchImage}/>
                                </div>
                            }

                            
                            let keyContainerStyle = style.replace("keyBoardButton", "keyBoardButtonContainer")
                            return <div key={'key' + rowIndex + "_" + colIndex} className={style}>{item.keycode}</div>

                        }))
                    })
                }
            </div>
        </div>
    )
}

export default KeyBoard

