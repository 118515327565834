import SecurityAnswerTextBox from './SecurityAnswerTextBox'
import './SecurityAnswerKeyBoard.css'
import { useEffect, useState } from 'react'
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import { setStorageItem,getStorageItem,removeStorageItem } from '../../../utils/utils';
import DeleteAnswerImage from '../../../assets/images/DeleteKey.png'
import SpaceImage from '../../../assets/images/spaceImage.png'

const SecurityAnswerKeyBoard = (props) => {
    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    let [prevXpoint, setprevXpoint] = useState(null);
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT","BACK", "LEFT"], props.bubbleFocusUP, props.hasFocus)
    let [typedText, setTypedText] = useState("");
    const [showDiv,setShowDiv] = useState(false)
    let [wordCountFlag,setWordCountFlag] = useState(false)
    let [showInfoLabel,setShowLinfoLabel] = useState(false)

    const specialCharKeys = [
        [
            { "keycode": "~", "xIndex": 0, "yIndex": 0 },
            { "keycode": "!", "xIndex": 1, "yIndex": 0 },
            { "keycode": "@", "xIndex": 2, "yIndex": 0 },
            { "keycode": "#", "xIndex": 3, "yIndex": 0 },
            { "keycode": "$", "xIndex": 4, "yIndex": 0 },
            { "keycode": "%", "xIndex": 5, "yIndex": 0 },
            { "keycode": "^", "xIndex": 6, "yIndex": 0 },
            { "keycode": "&", "xIndex": 7, "yIndex": 0 },
            { "keycode": "*", "xIndex": 8, "yIndex": 0 },
        ], 
        [
            { "keycode": "(", "xIndex": 0, "yIndex": 1 },
            { "keycode": ")", "xIndex": 1, "yIndex": 1 },
            { "keycode": "_", "xIndex": 2, "yIndex": 1 },
            { "keycode": "-", "xIndex": 3, "yIndex": 1 },
            { "keycode": "+", "xIndex": 4, "yIndex": 1 },
            { "keycode": "=", "xIndex": 5, "yIndex": 1 },
            { "keycode": "{", "xIndex": 6, "yIndex": 1 },
            { "keycode": "}", "xIndex": 7, "yIndex": 1 },
            { "keycode": "|", "xIndex": 8, "yIndex": 1 }
        ],
        [
            { "keycode": "[", "xIndex": 0, "yIndex": 2},
            { "keycode": "]", "xIndex": 1, "yIndex": 2},
            { "keycode": ":", "xIndex": 2, "yIndex": 2},
            { "keycode": ";", "xIndex": 3, "yIndex": 2},
            { "keycode": "'", "xIndex": 4, "yIndex": 2},
            { "keycode": "<", "xIndex": 5, "yIndex": 2},
            { "keycode": ">", "xIndex": 6, "yIndex": 2},
            { "keycode": "?", "xIndex": 7, "yIndex": 2},
            { "keycode": ".", "xIndex": 8, "yIndex": 2}
        ],
        [
            { "keycode": "@?#", "xIndex": 0, "yIndex": 3, "span": 3},
            { "keycode": "___", "xIndex": 1, "yIndex": 3, "span": 3},
            { "keycode": "<", "xIndex": 2, "yIndex": 3, "span": 3}
        ] 
    
    ]

    const smallAnswerKeys = [
        [
            { "keycode": "a", "xIndex": 0, "yIndex": 0 },
            { "keycode": "b", "xIndex": 1, "yIndex": 0 },
            { "keycode": "c", "xIndex": 2, "yIndex": 0 },
            { "keycode": "d", "xIndex": 3, "yIndex": 0 },
            { "keycode": "e", "xIndex": 4, "yIndex": 0 },
            { "keycode": "f", "xIndex": 5, "yIndex": 0 },
            { "keycode": "g", "xIndex": 6, "yIndex": 0 },
            { "keycode": "h", "xIndex": 7, "yIndex": 0 },
            { "keycode": "i", "xIndex": 8, "yIndex": 0 }
        ],
        [
            { "keycode": "j", "xIndex": 0, "yIndex": 1 },
            { "keycode": "k", "xIndex": 1, "yIndex": 1 },
            { "keycode": "l", "xIndex": 2, "yIndex": 1 },
            { "keycode": "m", "xIndex": 3, "yIndex": 1 },
            { "keycode": "n", "xIndex": 4, "yIndex": 1 },
            { "keycode": "o", "xIndex": 5, "yIndex": 1 },
            { "keycode": "p", "xIndex": 6, "yIndex": 1 },
            { "keycode": "q", "xIndex": 7, "yIndex": 1 },
            { "keycode": "r", "xIndex": 8, "yIndex": 1 }  
        ],
        [
            { "keycode": "s", "xIndex": 0, "yIndex": 2},
            { "keycode": "t", "xIndex": 1, "yIndex": 2},
            { "keycode": "u", "xIndex": 2, "yIndex": 2},
            { "keycode": "v", "xIndex": 3, "yIndex": 2},
            { "keycode": "w", "xIndex": 4, "yIndex": 2},
            { "keycode": "x", "xIndex": 5, "yIndex": 2},
            { "keycode": "y", "xIndex": 6, "yIndex": 2},
            { "keycode": "z", "xIndex": 7, "yIndex": 2},
            { "keycode": "^", "xIndex": 8, "yIndex": 2}
        ],
        [
            { "keycode": "@?#", "xIndex": 0, "yIndex": 3, "span": 3},
            { "keycode": "___", "xIndex": 1, "yIndex": 3, "span": 3},
            { "keycode": "<", "xIndex": 2, "yIndex": 3, "span": 3}
        ]
    ]

    const captialKeys = [
        [
            { "keycode": "A", "xIndex": 0, "yIndex": 0 },
            { "keycode": "B", "xIndex": 1, "yIndex": 0 },
            { "keycode": "C", "xIndex": 2, "yIndex": 0 },
            { "keycode": "D", "xIndex": 3, "yIndex": 0 },
            { "keycode": "E", "xIndex": 4, "yIndex": 0 },
            { "keycode": "F", "xIndex": 5, "yIndex": 0 },
            { "keycode": "G", "xIndex": 6, "yIndex": 0 },
            { "keycode": "H", "xIndex": 7, "yIndex": 0 },
            { "keycode": "I", "xIndex": 8, "yIndex": 0 }
        ],
        [
            { "keycode": "J", "xIndex": 0, "yIndex": 1 },
            { "keycode": "K", "xIndex": 1, "yIndex": 1 },
            { "keycode": "L", "xIndex": 2, "yIndex": 1 },
            { "keycode": "M", "xIndex": 3, "yIndex": 1 },
            { "keycode": "N", "xIndex": 4, "yIndex": 1 },
            { "keycode": "O", "xIndex": 5, "yIndex": 1 },
            { "keycode": "P", "xIndex": 6, "yIndex": 1 },
            { "keycode": "Q", "xIndex": 7, "yIndex": 1 },
            { "keycode": "R", "xIndex": 8, "yIndex": 1 }  
        ],
        [
            { "keycode": "S", "xIndex": 0, "yIndex": 2},
            { "keycode": "T", "xIndex": 1, "yIndex": 2},
            { "keycode": "U", "xIndex": 2, "yIndex": 2},
            { "keycode": "V", "xIndex": 3, "yIndex": 2},
            { "keycode": "W", "xIndex": 4, "yIndex": 2},
            { "keycode": "X", "xIndex": 5, "yIndex": 2},
            { "keycode": "Y", "xIndex": 6, "yIndex": 2},
            { "keycode": "Z", "xIndex": 7, "yIndex": 2},
            { "keycode": "^", "xIndex": 8, "yIndex": 2}
        ],
        [
            { "keycode": "@?#", "xIndex": 0, "yIndex": 3, "span": 3},
            { "keycode": "___", "xIndex": 1, "yIndex": 3, "span": 3},
            { "keycode": "<", "xIndex": 2, "yIndex": 3, "span": 3}
        ]
    ]

    if(props.forgotten){
        smallAnswerKeys.push([{"keycode": "Continue", "xIndex": 0, "yIndex": 4, "span": 6}])
        specialCharKeys.push([{"keycode": "Continue", "xIndex": 0, "yIndex": 4, "span": 6}])
        captialKeys.push([{"keycode": "Continue", "xIndex": 0, "yIndex": 4, "span": 6}])

        smallAnswerKeys.push([{"keycode": "Forgotten answer?", "xIndex": 0, "yIndex": 5, "span": 6}])
        specialCharKeys.push([{"keycode": "Forgotten answer?", "xIndex": 0, "yIndex": 5, "span": 6}])
        captialKeys.push([{"keycode": "Forgotten answer?", "xIndex": 0, "yIndex": 5, "span": 6}])

        smallAnswerKeys.push([{"keycode": "To reset please Sign out", "xIndex": 1, "yIndex": 5, "span": 3}])
        specialCharKeys.push([{"keycode": "To reset please Sign out", "xIndex": 1, "yIndex": 5, "span": 3}])
        captialKeys.push([{"keycode": "To reset please Sign out", "xIndex": 1, "yIndex": 5, "span": 3}])
        
    }else {
        smallAnswerKeys.push([{"keycode": "Next", "xIndex": 0, "yIndex": 4, "span": 6}])
        specialCharKeys.push([{"keycode": "Next", "xIndex": 0, "yIndex": 4, "span": 6}])
        captialKeys.push([{"keycode": "Next", "xIndex": 0, "yIndex": 4, "span": 6}])
    }

    let [keyBoardKeys, setNewKeys] = useState(smallAnswerKeys)
    let [smallKBFLag, setsmallKBFLag] = useState(true);
    let [specialKBFlag, setspecialKBFlag] = useState(false);

    const MAX_ROW = 8
    const MAX_COL = 3

    useEffect(() => {
        console.log("Inside setTypedText")
        if (showInfoLabel){
            setShowLinfoLabel(false)
        }
        if (props.screenState["showAnswers"].forgotten){
            //For flow 3 we need to dynamically check if the answer typed in is correct else we have to show the error div
            let localSettings = getStorageItem("pinSettings",true,true)
            console.log("The security answer is:"+localSettings["securityAnswer"])
            if (localSettings && localSettings["securityAnswer"] && typedText === localSettings["securityAnswer"] && typedText.length > 0){
                setShowDiv(false)
                setWordCountFlag(true)
            }else {
                if (typedText.length > 0){
                    setShowDiv(true)
                }
            }
        }else {
            //For Flow 1 : Enable button when we have data in text box
            if(typedText.length > 0){
                if (!wordCountFlag){
                    setWordCountFlag(true) 
                }
            }else {
                if (wordCountFlag){
                    setWordCountFlag(false) 
                } 
            }
        }
    }, [typedText]);

    useEffect(() => {
        if (specialKBFlag){
            setNewKeys(specialCharKeys)
        }else {
            if (!smallKBFLag){
                setNewKeys(captialKeys)
            }else {
                setNewKeys(smallAnswerKeys)
            }  
        }
    }, [specialKBFlag]);

    useEffect(() => {
        if (smallKBFLag){
            setNewKeys(smallAnswerKeys)
        }else {
            if (!specialKBFlag){
                setNewKeys(captialKeys)
            }else {
                setNewKeys(specialCharKeys)
            }
        }
     }, [smallKBFLag])

    useEffect(() => {
        if (props.hasFocus) {
            console.log("Jenix Answer Keyboard gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
        } else {
            setFocusPoint({ "x": -1, "y": -1})
            console.log('Answer Key Board Lost Focus')
        }
    }, [props.hasFocus])

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        
        if (keyState.RIGHT) {
            let newFocusPoint = {}
            if (currentFocuspoint.x < MAX_ROW && currentFocuspoint.y < 4) {
                //Handling focus for the last row right key press
                if (!(currentFocuspoint.x === 2 && currentFocuspoint.y === 3)){
                    newFocusPoint.x = currentFocuspoint.x + 1
                    newFocusPoint.y = currentFocuspoint.y 
                    setFocusPoint(newFocusPoint) 
                }  
            }
        }
        else if (keyState.LEFT) {
            let newFocusPoint = {}
            console.log(currentFocuspoint.x )
            if (currentFocuspoint.x > 0) {
                newFocusPoint.x = currentFocuspoint.x - 1
                newFocusPoint.y = currentFocuspoint.y
                setFocusPoint(newFocusPoint)
            }else{
                console.log('bubble focus mgmt to parent')
                props.bubbleFocusUP({relieveFocus : true, action : "LEFT"})
            }
        }
        else if (keyState.DOWN) {
            let newFocusPoint = {}
            console.log("Jenix the curr focus point is:"+currentFocuspoint.y)
            if (currentFocuspoint.y < MAX_COL) {
                newFocusPoint.y = currentFocuspoint.y + 1 
                if (newFocusPoint.y === 3){
                     if (currentFocuspoint.x === 3 || currentFocuspoint.x === 4 || currentFocuspoint.x === 5){
                        newFocusPoint.x = 1
                     } else if (currentFocuspoint.x === 6 || currentFocuspoint.x === 7 || currentFocuspoint.x === 8){
                        newFocusPoint.x = 2
                     }else{
                        newFocusPoint.x = currentFocuspoint.x
                     }

                     setFocusPoint(newFocusPoint)
                }    
                else {
                    newFocusPoint.x = currentFocuspoint.x
                    setFocusPoint(newFocusPoint)
               }
                
            } else if (currentFocuspoint.y === 3) {
                if (typedText.length !== 0){
                    newFocusPoint.y = currentFocuspoint.y + 1 
                    newFocusPoint.x = 0
                    setFocusPoint(newFocusPoint)
                }else if (props.forgotten){
                    newFocusPoint.y = currentFocuspoint.y + 1 
                    newFocusPoint.x = 0
                    setFocusPoint(newFocusPoint)
                }
            } else if (currentFocuspoint.y === 4 && props.forgotten){
                newFocusPoint.y = currentFocuspoint.y + 1 
                newFocusPoint.x = 0
                setFocusPoint(newFocusPoint)
            }else if (newFocusPoint.y === 4 && newFocusPoint.x === 0){
                setFocusPoint(newFocusPoint)
            }
            console.log("The new focus point is:"+JSON.stringify(newFocusPoint))
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y > 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            //Hiding the error div when user starts typing
            //setShowDiv(false)

            if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "Next" || keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "Continue" && typedText.length > 0){
                //Flow 3
                if (props.forgotten){
                    
                    let localSettings = getStorageItem("pinSettings",true,true)
                    if (localSettings && localSettings["securityAnswer"] && localSettings["securityAnswer"] === typedText){
                        let oldState = props.screenState
                        oldState.showAnswers["visibility"] = false
                        oldState.showAnswers["forgotten"] = false
                        oldState.showChoosePin["visibility"] = true
                        oldState.showChoosePin["forgotten"] = true
                        oldState.showChoosePin["changeRatingConfirmation"] = false
                        oldState.showChoosePin["labelData"] = {
                            "lineOne":"Now please add your new PIN",
                            "parentLabel":""
                        }
                        props.setScreenState({...oldState}) 
                    }else {
                        setShowDiv(true)
                    } 
                }else {
                    //Flow 1
                    if (typedText.length > 0){
                        let oldState = props.screenState
                        oldState.showAnswers["visibility"] = false
                        oldState.showRatingSelector["visibility"] = true
                        oldState.tempSetting["securityAnswer"] = typedText
                        oldState.showRatingSelector["labelData"] = {
                            "lineOne":"4 of 4: Set PIN rating level",
                            "lineTwo":"Your PIN is now set. Next please set a rating level"
                        }
                        props.setScreenState({...oldState}) 
                    }
                }
            }

            if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "^"){
                if(smallKBFLag){
                    setsmallKBFLag(false)
                }else {
                    setsmallKBFLag(true)
                }
            }else if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "@?#"){
                if(specialKBFlag){
                    setspecialKBFlag(false)
                }else {
                    setspecialKBFlag(true)
                }
            } else if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "<"){
                setTypedText(prevState => ((prevState!==undefined?prevState:'').slice(0,-1)))
            }
            else if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "___"){
                setTypedText(prevState => ((prevState!==undefined?prevState:'')+ " "))
            }else {
                if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode !== "Next" && keyBoardKeys[focusPoint.y][focusPoint.x].keycode !== "Continue" && keyBoardKeys[focusPoint.y][focusPoint.x].keycode !== "Forgotten answer" && keyBoardKeys[focusPoint.y][focusPoint.x].keycode !== "Forgotten answer?"  ){
                    setTypedText(prevState => ((prevState!==undefined?prevState:'')+ keyBoardKeys[focusPoint.y][focusPoint.x].keycode))
                }
                if (keyBoardKeys[focusPoint.y][focusPoint.x].keycode === "Forgotten answer?"){
                    setShowLinfoLabel(true)
                }
            }
        }
        else if (keyState.BACK){
            props.backNavFromSettings()
        }


    }, [keyState]);

    return <div className='keyboardContainer'>
        <SecurityAnswerTextBox typedText = {typedText}/>
        <div  className={`${showDiv ? 'errorDiv' : 'errorDivHide'}`}>
            <div className="errorLineOne">Sorry that doesn't look right</div>
            <div className="errorLineTwo">Please try entering again</div>
        </div>
        <div className='answerKeyBoardPanel'>
            {
                keyBoardKeys.map((row, rowIndex) => {
                    return (row.map((item, colIndex) => {
                        let style = "keyBoardButton"
                
                        if (item.span === 3 && item.keycode === "@?#") {
                            style = style + "  special"
                        }

                        if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y ) {
                            if (item.keycode === "Next" || item.keycode === "Continue"){
                                style = style + "  nextKeyFocused"
                            }else {
                                style = style + "  keyFocused"
                            }
                        }

                        if (item.span === 3 && item.keycode === "___") {
                            style = style + "  space"
                            return <div className={style}>
                                <img className="spaceImageDiv" src ={SpaceImage}/>
                            </div>
                        }

                        if (item.span === 3 && item.keycode === "<") {
                            style = style + "  delete"
                            return <div className={style}>
                                <img className="deleteImageForSecAnswer" src ={DeleteAnswerImage}/>
                            </div>
                        }

                        if (item.span === 6 && item.keycode === "Continue" || item.span === 6 && item.keycode === "Next") {
                            style = style + "  buttonOne"
                            if (wordCountFlag){
                                style = style + "  bgChange"
                            }
                        }

                        if (item.span === 6 && item.keycode === "Forgotten answer?") {
                            style = style + "  buttonTwo"
                        }

                        if (item.span === 3 && item.keycode === "To reset please Sign out") {
                            style = style + "  infolabel"
                            console.log("Info label is:"+showInfoLabel)
                            if (showInfoLabel){
                                style = style + " showInfolabel"
                            }
                        }
                        
                        return <div key={'key' + rowIndex + "_" + colIndex} className={style}>{item.keycode}</div>
                            
                    }))
                })
            }
        </div>
    </div>
}

export default SecurityAnswerKeyBoard
