import React, { useEffect } from 'react'
import './event-vertical-list.css';

const EventDetail = (props) => {

    useEffect(()=>{
        console.log(props.event)
    },[props])

    return (
       
        <div className="eventDetailContainer">
            <div className="eventDetailHeading">
                {props.event.name}
            </div>
            <div className="eventDetailMetadata">
                <div className="eventDetailDuration"> {Math.round(props.event.duration/60)}</div>
                <div className="eventDetailUnit">Min</div>
                {
                    true && props.event?.certification?.name &&
                    <div className="eventDetailPG">{props.event?.certification?.name}</div>
                }
                {
                    true &&
                    <div className="eventDetailVideoFormat">HD</div>
                }
               
            </div>
            <div className="eventDetailSynopis">
                {props.event.description}
            </div>

        </div>
    )
}

export default EventDetail