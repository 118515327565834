import React , {useEffect, useState} from 'react';
import './PlayButton.css'
import PlayIconOutline from '../../assets/icons/player/circle-outline.png'

const PlayButton = ({data,bubbleFocusUP, hasFocus, clickHandler, paused }) =>{

    const [buttonIcon, setButtonIcon] = useState('')

    useEffect(()=>{
        console.log("swap icon")
        if(paused){
            if(hasFocus){
                setButtonIcon(data.activeIcon)
            }else{
                setButtonIcon(PlayIconOutline)
            }
            
        }else{
            if(hasFocus){
                setButtonIcon(data.pauseActiveIcon)
            }else{
                setButtonIcon(PlayIconOutline)
            }
        }
    },[hasFocus])

    useEffect(()=>{
        const playButton = document.getElementById("play-button");
        playButton.classList.add("button-zoom-out");
        setTimeout(() => {
            playButton.classList.remove("button-zoom-out");
            playButton.classList.add("boundary-circle");
            if(hasFocus){
                if(paused){
                    setButtonIcon(data.activeIcon)
                }else{
                    setButtonIcon(data.pauseActiveIcon)
                }
            } else {
                setButtonIcon(PlayIconOutline)
            }
            setTimeout(() => {
                playButton.classList.remove("boundary-circle");
            }, 250);
          }, 250);
      
    }, [paused])


    return(
        <div onClick={clickHandler} id="play-button" className={`${hasFocus ? 'play-button-selected' : 'play-button-unselected'}`}>
            
            {
                data.icon && 
                <img  className="play-button-icon" src={ paused ? data.icon : data.pauseIcon} />
            }
            <img className="play-button-outline" src={buttonIcon} />
        </div>
    )
}

export default PlayButton

