import React, { useEffect, useState } from "react";
import useRemoteKeyEvent from "../../hooks/useRemoteKeyEvent";
import "./textbox.css";
const TextBox = (props) => {
  const [keyState, setFocus] = useRemoteKeyEvent(
    ["OK"],
    props.bubbleFocusUP,
    props.hasFocus
  );

  let [focusIndex, setfocusIndex] = useState(0);
  let [isPlaceholderVisible, setIsPlaceholderVisible] = useState(false);
  useEffect(() => {
    if (props.text === "") {
      setIsPlaceholderVisible(true);
    } else {
      setIsPlaceholderVisible(false);
    }
  }, [props]);

  useEffect(() => {
    if (keyState.OK) {
      props.changeKeyBoardVisibility(true);
      //nextPath(MenuItems[focusIndex].path)
      //setMenuSelection(focusIndex)
    }
  }, [keyState]);

  useEffect(() => {
    if (props.hasFocus) {
      setFocus();
      props.changeKeyBoardVisibility(true);
      console.log("Text Box Gained Focus");
    } else {
      console.log("Text Box Lost Focus");
    }
  }, [props.hasFocus]);

  let style = "textBox";
  if (props.hasFocus) {
    style = style + " focused";
  }
  return (
    <div className={style}>
      {props.text}
      <p className="caret"></p>
      {isPlaceholderVisible && <p>Search</p>}
    </div>
  );
};

export default TextBox;
