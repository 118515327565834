import './SettingsSideMenu.css'
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent'
import React , {useEffect,useState,useRef, useContext} from 'react'
import useFocusParent from '../../../hooks/useFocusParent'
import { useNavigate } from 'react-router-dom'
import {AstroBBCContext} from '../../../App'

const SettingsSideMenu = (props) => {
    const navigate = useNavigate()
    const shouldSetStartFocus = useRef(false);
    let focusNavMap = {
       
    }
    const [currentFocusOwner, setCurrentFocusOWner] = useState('')
   // const [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction] = useFocusParent('', focusNavMap)
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK','LEFT','RIGHT', 'BACK'],props.bubbleFocusUP, props.hasFocus)
    const [focusIndex, setFocusIndex] = useState(0)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const appData = useContext(AstroBBCContext);
    const [signInOrSignOut, setSignInOrSignOut] = useState('Sign In')
    const MenuItems = [
        {
            title: "AV Settings",
        },
        {
            title: "PIN Management",
        },
        {
            title: signInOrSignOut,
        },
        {
            title: "About",
        },
        {
            title: "Help / FAQs",
        },
        {
            title: "T&Cs",
        }
    ]

    useEffect(() => {
        console.log("CURRENT FOCUS OWNER IS CHANGED NOW INSIDE Sidemnu setting")
       
    }, [currentFocusOwner]);

    useEffect(() => {
        
        if(props.hasFocus){
            console.log("Gaining focus inside the sidemenu settings screen")
            setFocus()
            if (shouldSetStartFocus.current){
                setFocusIndex(0); 
                shouldSetStartFocus.current = false
            }else {
                setFocusIndex(selectedIndex);
            }
            
            setSelectedIndex(selectedIndex);

            if(appData.auth.isUserSignedIn()){
                setSignInOrSignOut('Sign Out')
            }else{
                setSignInOrSignOut('Sign In')
            }

        }else {
            console.log("Losing focus inside sidemenu setting screen")
            setCurrentFocusOWner("");
           // setUnHandledAction("");
        }
  
        //
    }, [props.hasFocus]);


    useEffect(() => {
        if (keyState.UP) {
            if (focusIndex > 0){
                setFocusIndex(focusIndex - 1)
            }else {
                setFocusIndex(-1);
                shouldSetStartFocus.current = true
                props.bubbleFocusUP({ relieveFocus : true, action : "UP"})
            }
        }

        if (keyState.DOWN) {
            if (focusIndex < 5){
                setFocusIndex(focusIndex + 1)
            }
        }

        if (keyState.OK) {

            if (MenuItems[focusIndex].title === 'Sign In') {
                navigate('/', {replace : true})
                props.changeFocusOwnerApp('Splash')
            } else {
                //This is for updating the side menu UI
                setSelectedIndex(focusIndex)
                //This is to select the component in settings screen
                props.setComponentIndex(focusIndex)
            }

        }

        if (keyState.RIGHT) {
            //SendMessageToParentAsking to change Focus to the active right component
            props.changeComponentFocus()
            setFocusIndex(-1);
        }


        if (keyState.BACK) {
            //SendMessageToParentAsking to change Focus to the active right component
            navigate(-1)
            props.changeFocusOwnerApp("SideMenu")
        }

    }, [keyState]);

    return <div className="side-menu-settings">
                
                {
                        MenuItems.map((item, index) => {
                            let classVal = "SettingsMenuItem"
                            if (focusIndex === index){
                                classVal = classVal + " focusedMenuItem"
                            }
                            if (selectedIndex === index){
                                classVal = classVal + " selectedMenuItem"
                            }
                            return <div className= {classVal} key={index} >{item.title}</div>
                        })

                }
            </div>
}


export default SettingsSideMenu