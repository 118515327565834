import { useState } from 'react'


function useFocusManager(focusMap){
    //resolve navmap to find the next focusable component in a screen
    //console.log("The focus map is:"+JSON.stringify(focusMap))
    const [currentFocusMap, setCurrentFocusMap] = useState(null)
    const [nextFocusableItem, setNextFocusableItem] = useState('');
    const [currentFocusedItem, setCurrentFocusedItem] = useState('');

    const getNextFocusableItem = (currentFocusItem, userAction) => {
        console.log("The curr fpcsu item is:"+currentFocusItem)
        console.log("The user action is:"+userAction)
        if( currentFocusItem === undefined || currentFocusItem === '' || focusMap[currentFocusItem] === undefined){
            return 'SideMenu'
        }
        return focusMap[currentFocusItem][userAction]
    }

    return [getNextFocusableItem]
}


export default useFocusManager