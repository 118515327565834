import './BackButton.css'
import BackImage from '../../../assets/images/back.png'
import React, { useState,useEffect } from 'react';
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import { useNavigate } from 'react-router-dom'

const BackButton = (props) => {
    let [focusbackImage, setFocusOnBackImage] = useState(false);
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN','OK','LEFT','RIGHT', 'BACK'], props.bubbleFocusUP, props.hasFocus)
    const navigate = useNavigate()

    useEffect(() => {
        console.log("The focus inside BACK BUTTON IS:"+props.hasFocus)
        if (props.hasFocus){
            setFocus()
            setFocusOnBackImage(true)
        }else {
            setFocusOnBackImage(false)
        }
    },[props.hasFocus])

    useEffect(() => {
        console.log("Inside back of backbutton")
        if (keyState.DOWN) {
            props.bubbleFocusUP({ relieveFocus : true, action : "DOWN"})
        }else if (keyState.OK){
            navigate(-1)
            props.changeFocusOwnerApp("SideMenu")
        }else if(keyState.LEFT){

        }else if(keyState.BACK){
            props.backNavFromSettings()
        }
    }, [keyState]);

    return <div className='backContainer'>{
        <div className={focusbackImage ? 'imageDiv showBorder' : 'imageDiv'}>
            <img className="settings-back-image" src = {BackImage}></img>
        </div>
    }
    </div>
}


export default BackButton