import React, { useState,useEffect } from 'react';
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import './CreatePin.css'



const CreatePin = (props) => {

    const [keyState, setFocus] = useRemoteKeyEvent(['OK', "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    
    useEffect(() => {
        if (props.hasFocus) {
            setFocus()
            console.log('Create Pin Gained Focus')
        }else{
            console.log('Create Pin Lost Focus')
        }
    }, [props.hasFocus])

    useEffect(() => {
        if (keyState.OK) {
            //Informing the parent to hide the current screen and show the choose pin screen
            let existingState = props.screenState  
            existingState.showSetpin["visibility"] = false
            existingState.showChoosePin["visibility"] = true
            existingState.showChoosePin["createNew"] = true
            existingState.showChoosePin["labelData"] = {
                    "lineOne":"1 of 4: Choose PIN",
                    "parentLabel":"The Parental Guidance Lock helps you control what people in your household can and can't watch on Player."
            }
           // props.setScreenState((prevState) => ({...prevState,existingState})) 
           props.setScreenState({...existingState}) 
        } else if (keyState.LEFT){
            console.log("Jenix Left pressed inside Create Pin")      
            props.bubbleFocusUP({ relieveFocus : true, action : "LEFT"})
        }
        else if (keyState.BACK){
            props.backNavFromSettings()
        }

    }, [keyState]);

    let style = "setPin";
    if(props.hasFocus){
        style = style+" focusedItem"
    }

    return <div className='createPinContainer'>
        <div className ='startLabel'>To protect your account, please set a rating level and a PIN</div>
        <div className = {style}>Set PIN</div>
    </div>

}

export default CreatePin