import './SeasonNumber.css'

const SeasonNumber = ({number, hasFocus, isSelected}) => {
    return(
        <li className={ hasFocus ?  ((hasFocus && isSelected) ? "season-number-container-focused selectedSeason" : "season-number-container-focused") : isSelected ? "season-number-container selectedSeason" : "season-number-container"}>
            <p className="season-number">
                {number}
             </p>
        </li>
    )
}

export default SeasonNumber