import Slider from "./Slider";
import HeroSlider from "./HeroSlider";
import React, { useEffect, useReducer, useRef } from "react";
import useRemoteKeyEvent from "../../hooks/useRemoteKeyEvent";
import "./Slider.css";
import {removeStorageItem,getStorageItem} from "../../utils/utils";
import focusIndexOkPressed from '../../utils/IndexHandler'
import { memo } from "react"; 
import BBCLogo from '../../assets/images/logo-player.png'

function reducer(state, action) {
  let newisVisibleTracker = [];
  let selected = Number(getStorageItem('savedCaurosalIndex'));
  removeStorageItem('savedCaurosalIndex',false)
  switch (action.type) {
    case "arrowUp":
      removeStorageItem('savedCardIndex',false)
      newisVisibleTracker = [...state.isVisibleTracker];
      for (let i = 0; i < state.items.length; i++) {
        if(i <= state.selectedCarousal && i > state.selectedCarousal - state.totalVisibleRow && state.selectedCarousal - state.totalVisibleRow >= 0){
          newisVisibleTracker[i] = true
        }else if( state.selectedCarousal - state.totalVisibleRow < 0 && i <=state.totalVisibleRow ){
          newisVisibleTracker[i] = true
        }
        else{
          newisVisibleTracker[i] = false
        }
      }

      return {
        ...state,
        selectedCarousal:
          state.selectedCarousal !== -1
            ? state.selectedCarousal - 1
            : state.selectedCarousal,
        isVisibleTracker : newisVisibleTracker,
        isBackKeyPressed : false    
      };
    case "arrowDown":
      removeStorageItem('savedCardIndex',false)
      newisVisibleTracker = [...state.isVisibleTracker];
      for (let i = 0; i < state.items.length; i++) {
        if( i>= state.selectedCarousal && i < state.selectedCarousal + state.totalVisibleRow){
          newisVisibleTracker[i] = true
        }else if( state.selectedCarousal + state.totalVisibleRow >= state.items.length && i > state.items.length - state.totalVisibleRow){
          newisVisibleTracker[i] = true
        }else {
          newisVisibleTracker[i] = false
        }
      }
      return {
        ...state,
        selectedCarousal:
          state.selectedCarousal !== action.payload.componentsLength - 1
            ? state.selectedCarousal + 1
            : state.selectedCarousal,
        isVisibleTracker : newisVisibleTracker,
        isBackKeyPressed : false        
      };
    case "hasFocus":
      //The focus should be first carousal
      for (let i = 0; i < state.items.length; i++) {
        i <= state.totalVisibleRow
          ? (newisVisibleTracker[i] = true)
          : (newisVisibleTracker[i] = false);
      }
      if (selected) {
        for (let i = 0; i < state.items.length; i++) {
          if( i>= selected && i < selected + state.totalVisibleRow){
            newisVisibleTracker[i] = true
          }else if( selected + state.totalVisibleRow >= state.items.length && i > state.items.length - state.totalVisibleRow){
            newisVisibleTracker[i] = true
          }else {
            newisVisibleTracker[i] = false
          }
        }
      }
      return { ...state, selectedCarousal: selected || 0, isVisibleTracker : newisVisibleTracker };
      case "UPDATE_ITEMS":
        let isBackKeyPressed = false;
        for (let i = 0; i < action.payload.items.length; i++) {
          i <= state.totalVisibleRow
            ? (newisVisibleTracker[i] = true)
            : (newisVisibleTracker[i] = false);
        }
        if (state.selectedCarousal) {
          isBackKeyPressed = true
          for (let i = 0; i < action.payload.items.length; i++) {
            if( i>= state.selectedCarousal && i < state.selectedCarousal + state.totalVisibleRow){
              newisVisibleTracker[i] = true
            }else if( state.selectedCarousal + state.totalVisibleRow >= action.payload.items.length && i > action.payload.items.length - state.totalVisibleRow){
              newisVisibleTracker[i] = true
            }else {
              newisVisibleTracker[i] = false
            }
          }
        }
        return {
          ...state,
          items: action.payload.items,
          isVisibleTracker: newisVisibleTracker,
          isBackKeyPressed: isBackKeyPressed,
          selectedCarousal: state.selectedCarousal || 0,
        };
    default:
      //console.log("Inside Default")
      return state;
  }
}

//to scroll the component on keyboard down and up
function scrollElement(scrollDirection, scrollDistance) {
  scrollDirection = scrollDirection === "UP" ? 1 : -1;
  const pos = window.pageYOffset;
  window.scroll(0, pos - scrollDistance * scrollDirection);
}

const SliderList = (props) => {
  const { hasFocus, bubbleFocusUP, components, holdFocus, setPlayerSource, changeFocusOwnerApp, source, suppressKeys, overridVisibleRows} = props;
  const { innerHeight: viewportHeight } = window;
  // reference to the whole carousal list div
  const scrollRef = useRef(null);
  // reference to individual carousals
  const elementRefs = useRef({});
  // no carousal is selected intially without focus
  const initialState = { items : [], selectedCarousal: -1, isVisibleTracker : [], totalVisibleRow : overridVisibleRows ? overridVisibleRows : 3 };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(
    ["UP", "DOWN", "RIGHT", "LEFT"],
    bubbleFocusUP,
    hasFocus
  );


    //Update carousal content
    useEffect(() => {
      //Getting the contents of the collection prop
      let items = components
      let payload = { items };
      if (items.length > 0) {
        dispatch({ type: "UPDATE_ITEMS", payload });
      }
    }, [components]);

    useEffect(() => {
      if (state.isBackKeyPressed) {
        const currentElement = elementRefs.current[state.selectedCarousal];
        if (currentElement) {
          let apparentHeight = viewportHeight - currentElement.offsetTop;
          if (apparentHeight > 0) {
            let heightDifference = currentElement.clientHeight - apparentHeight;
            scrollElement("DOWN", currentElement.clientHeight + heightDifference);
          } else {
            scrollElement("DOWN", currentElement.clientHeight);
          }
        }
      }
    }, [state.items]);


  const handleFocuseLose = (data) => {
    console.log(data)
    if(data.action !== 'UP'){
      bubbleFocusUP(data)
    }
   // 
  }

  useEffect(()=>{
    if(suppressKeys){
        setSuppressed(suppressKeys)
    }else{
        setSuppressed(suppressKeys)
    }
}, [suppressKeys])
  // Keyboard event handlers
  useEffect(() => {

    if (keyState.LEFT) {
      if(components.length === 0){
        bubbleFocusUP({ relieveFocus: true, action: "LEFT" });
      }
    }

    if (keyState.UP) {
      if (state.selectedCarousal === 0) {
        if (props.shouldSetFocus){
          props.shouldSetFocus(true)
        }
        const firstElement = elementRefs?.current[0];
        if (firstElement) {
          scrollElement("UP", firstElement.clientHeight + 200);
        }
        bubbleFocusUP({ relieveFocus: true, action: "UP" });
      } else {
        const prevElement = elementRefs.current[state.selectedCarousal - 1];
        if (prevElement) {
          let offset = window.pageYOffset + prevElement.offsetTop;
          if(offset > viewportHeight){
            let heightDifference = offset - viewportHeight;
            console.log(heightDifference)
            scrollElement("UP", 400);
          }else{
            scrollElement("UP", 0);
          }
          
        }
        dispatch({
          type: "arrowUp",
          payload: { componentsLength: components.length },
        });
      }
    }

    if (keyState.DOWN) {
      const currentElement = elementRefs.current[state.selectedCarousal];
      if (currentElement) {
        let apparentHeight = viewportHeight - currentElement.offsetTop;
        if (apparentHeight > 0) {
          let heightDifference = currentElement.clientHeight - apparentHeight;
          scrollElement("DOWN", currentElement.clientHeight + heightDifference);
        } else {
          scrollElement("DOWN", currentElement.clientHeight);
        }
      }
      dispatch({
        type: "arrowDown",
        payload: { componentsLength: components.length },
      });
    }
  }, [keyState]);

  function getOffsetValue(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
  // hasFocus event handler
  useEffect(() => {
  
    if (hasFocus) {

      if (props.shouldSetFocus){
        props.shouldSetFocus(false)
      }
      setFocus();
      if (state.selectedCarousal === -1 || focusIndexOkPressed["didSelect"]){
        dispatch({
          type: "hasFocus",
        });
      }
    }else {
      focusIndexOkPressed["didSelect"] = false
    }
  }, [hasFocus]);

  return (
    <div
      className={`carousalList${hasFocus ? "" : " carousalListFilter"}`}
      ref={scrollRef}
    >
      {components.map((component, i) =>  state.isVisibleTracker[i] && (
        <div
          className="carousel"
          key={i}
          ref={(ref) => {
            elementRefs.current = { ...elementRefs.current, [i]: ref };
          }}
        >
          <header className="carousel__header">
            { component.__typename !== "Hero" &&
              <h2> {component.header}</h2>
            }
            { component.__typename === "Hero" &&
              <img  className="hero-carousel-bbc-logo" src={BBCLogo} />
            }
            
          </header>
          {component.__typename !== "Hero" && state.isVisibleTracker[i] &&
            <Slider
            key={i}
            selectedCaurosalIndex={i === state.selectedCarousal ? state.selectedCarousal : 0}
            hasFocus={i === state.selectedCarousal && hasFocus}
            bubbleFocusUP={handleFocuseLose}
            holdFocus={holdFocus}
            collection={component.collection}
            changeFocusOwnerApp={changeFocusOwnerApp}
            suppressKeys={suppressKeys}
            suppressList={setSuppressed}
            setPlayerSource={setPlayerSource}
            source = {source}
            ></Slider>
          }
          { component.__typename === "Hero" && state.isVisibleTracker[i] &&
            <HeroSlider
            key={i}
            selectedCaurosalIndex={i === state.selectedCarousal ? state.selectedCarousal : 0}
            hasFocus={i === state.selectedCarousal && hasFocus}
            bubbleFocusUP={handleFocuseLose}
            holdFocus={holdFocus}
            metadata={component}
            setPlayerSource={setPlayerSource}
            changeFocusOwnerApp={changeFocusOwnerApp}
            suppressKeys={suppressKeys}
            suppressList={setSuppressed}
            source = {source}
            />


          }
          
        </div>
      ))}
    </div>
  );
};

export default memo(SliderList);
