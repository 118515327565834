import eventBus from "./EventBus";


const registerForKeyEvents = () => {
  console.log("Adding master key listener");
  window.addEventListener('keydown', e => {
    switch (e.keyCode) {
      case 37:
        console.log('LEFT')
        eventBus.dispatch('AstroKeyEvent', 'LEFT');
        e.preventDefault();
        break
      case 38:
        console.log('UP')
        eventBus.dispatch('AstroKeyEvent', 'UP');
        e.preventDefault();
        break
      case 39:
        eventBus.dispatch('AstroKeyEvent', 'RIGHT');
        e.preventDefault();
        console.log('RIGHT')
        break
      case 40:
        eventBus.dispatch('AstroKeyEvent', 'DOWN');
        e.preventDefault();
        console.log('DOWN')
        break
      case 13:
        console.log('OK')
        e.preventDefault();
        eventBus.dispatch('AstroKeyEvent', 'OK');
        break
      case 116:
        console.log('PLAY')
        e.preventDefault();
        eventBus.dispatch('AstroKeyEvent', 'PLAY');
        break
      case 118:
        console.log('FORWARD')
        e.preventDefault();
        eventBus.dispatch('AstroKeyEvent', 'FORWARD');
        break
      case 119:
        console.log('REVERSE')
        e.preventDefault();
        eventBus.dispatch('AstroKeyEvent', 'REVERSE');
        break
      case 8:
        console.log('BACK')
        e.preventDefault();
        eventBus.dispatch('AstroKeyEvent', 'BACK');
        break
      case 117:
        console.log('STOP')
        e.preventDefault();
        eventBus.dispatch('AstroKeyEvent', 'STOP');
        break
      default:
        eventBus.dispatch('AstroKeyEvent', e.keyCode);
    }
  });
}
export default registerForKeyEvents