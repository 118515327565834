import './SubtitleItem.css'
import SubtitleIcon from '../../assets/icons/player/subtitle-icon.png'
import { useRef, useEffect, useState } from 'react'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';


const SubtitleItem = ({ hasFocus, data, chosen }) => {

    const [itemState, setItemState] = useState({selected : false})








    return (
        <div className={"subtitle-item-container " + (chosen ? "subtitle-focused" : "")}>
            <input className="subtitle-radio-button"
                type="radio"
                name={data.language}
                value={data.language}
                checked={hasFocus}
            />
            <span className='custom-radio'></span>
            <p className="subtitle-item-text">{data.language}</p>

        </div>
    )
}

export default SubtitleItem