import './PinEntry.css'
import {useState, useEffect} from 'react'
import PlayerPinKeyboard from '../../components/Player/PlayerPinKeyboard'
import PinTextDisplay from '../../components/Player/PinTextDisplay'
import CustomButton from '../../components/button/CustomButton'
import {getStorageItem} from '../../utils/utils'
import { createPath } from 'react-router-dom'

const PinEntry = ({ hasFocus, bubbleFocusUP, onVerify}) => {

    const [pinText, setPinText ] = useState(["","","",""])
    const [cursor, setCursor ] = useState(0)
    const [key, setKey] = useState({value : ''})
    const [currFocusOwner, setCurrFocusOwner] = useState('')
    const [showDiv,setShowDiv] = useState(false)
    var localSettings = getStorageItem("pinSettings", true, true);

    var pinToCheck = (localSettings && localSettings["pintoSave"]) ? localSettings["pintoSave"] : null

    const handleFocusChange = (data) => {
        console.log(data)
        if(currFocusOwner === 'PlayerPinKeyBoard' && data.action === 'DOWN'){
            setCurrFocusOwner('PlayButton')
        }

        if(currFocusOwner === 'PlayButton' && data.action === 'UP'){
            setCurrFocusOwner('PlayerPinKeyBoard')
        }
    }

    useEffect(()=>{
        if(hasFocus){
            setCurrFocusOwner('PlayerPinKeyBoard')
        }
    },[hasFocus])



    useEffect(()=>{
        if(key.value !== '<'){
            if(cursor <= 3 && key.value !== ''){
                setPinText(pinText =>{
                    pinText[cursor] = key.value
                    return pinText
                })
                setCursor(cursor => {
                    if(cursor < 3){
                        return cursor + 1 
                    }else {
                        setCurrFocusOwner('PlayButton')
                        return cursor 
                    } 
                })
            }
        }else{
            if(cursor >= 0){
                setPinText(pinText =>{
                    if (cursor === 0){
                        return ["","","",""]
                    }else {
                        pinText[cursor] = ""
                        return pinText
                    }
                })
                setCursor(cursor => {
                    return cursor === 0 ? 0 : cursor - 1
                })
                
            }
        }
        console.log(key)
    },[key])


    const onPressPlay = ()=> {
        let pin = pinText.join("")
        if(pin === pinToCheck){
            onVerify()
        }else{
            setShowDiv(true)
            setTimeout(() => {
                setShowDiv(false)
            }, 1000);
        }
        console.log(pin)
        console.log(pinToCheck)
    }

    return(
        <div className="player-pin-entry-container">
            <div className="player-pin-entry-display-items">
                <p className="player-pin-entry-title">Please enter your four-digit PIN</p>
                <p className="player-pin-entry-sub-title">You'll need this to play any content with a guidance label</p>
                <PinTextDisplay cursor={cursor} pinText={pinText}/>
                <div className={`${showDiv ? 'errorDivPlayer' : 'errorDivHide'}`}>
                    <div className="errorLineOnePlayer">{pinText.join("").length > 0 ? "Sorry that doesn't look right" : "You need to enter your Parental Guidance PIN"}</div>
                    <div className="errorLineTwoPlayer">{pinText.join("").length > 0 ? "Please try entering again" : "You need this to play any content with a Guidance label"}</div>
                </div>
            </div>
            <div className="player-pin-entry-control-items">
                <PlayerPinKeyboard bubbleFocusUP={handleFocusChange} setKey={setKey} hasFocus={currFocusOwner === 'PlayerPinKeyBoard'} />
                <div className="player-pin-entry-play-container">
                    <CustomButton onPress={onPressPlay} hasFocus={currFocusOwner === "PlayButton" } name="Play" bubbleFocusUP={handleFocusChange}/>
                </div>
            </div>
        </div>
    )
}

export default PinEntry