import { useState, useCallback, useEffect, useReducer } from 'react'
import eventBus from "../utils/EventBus";

/**
 * 
 * @param {Object} usedKeys - array of remote keys used by the component
 * @callback unhandledKeyCallback - cb function to call when a key not in the usedKeys array is pressed
 * @param {Bool} hasFocusProp - bool value that tracks the components focus
 */

const useRemoteKeyEvent = (usedKeys, unhandledKeyCallback, hasFocusProp) => {
    const [relieveFocus, setRelieveFocus] = useState(false)
    const [relievedAction, setRelievedAction] = useState('')
    const [hasFocus, setHasFocus] = useState(false)
    const [suppressed, setSuppressed] = useState(false)


    const [keyState, setKeyState] = useState({ UP: false, DOWN: false, RIGHT: false, LEFT: false, OK: false })

    const setFocus = useCallback(function () {
        if(!hasFocus){
            eventBus.on("AstroKeyEvent", handleCustomKeyPress);
            setHasFocus(true);
        }
    }, [])

    useEffect(() => {
        console.log("adding key listener on custom hook")
        eventBus.on("AstroKeyEvent", handleCustomKeyPress);
        return () => {
            eventBus.remove("AstroKeyEvent", handleCustomKeyPress);
        };
    }, []);


    useEffect(() => {
        console.log("removing listener")
        if (!hasFocusProp) {
            eventBus.remove("AstroKeyEvent", handleCustomKeyPress)
        }else{
            eventBus.on("AstroKeyEvent", handleCustomKeyPress);
            setHasFocus(true);
        }
        setHasFocus(hasFocusProp)
    }, [hasFocusProp]);




    useEffect(() => {
        if (relieveFocus) {
            unhandledKeyCallback({ relieveFocus: true, action: relievedAction })
            setRelieveFocus(false)
            setRelievedAction('')
        }


    }, [relieveFocus, relievedAction])


    const handleState = (state, action) => {
        let shouldHandle = usedKeys.includes(action.type)

        if (!shouldHandle) {
            if(!suppressed || action.type === 'BACK'){
                setRelieveFocus(true)
                setRelievedAction(action.type)
                return;
            }
        } else {
            if (!suppressed) {
                let userAction = action.type
                setKeyState({ [userAction]: true })
            }

        }
    }
    const [state, dispatch] = useReducer(handleState, { selected: false })
    const handleCustomKeyPress = useCallback((e) => {

        dispatch({ type: e.detail })

    }, []);



    return [keyState, setFocus, setSuppressed]
}

export default useRemoteKeyEvent