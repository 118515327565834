import './HelpAndFAQ.css'
import UpNav from '../../../assets/images/upNav.png'
import DownNav from '../../../assets/images/downNav.png'
import React , {useEffect,useState} from 'react'
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';

const HelpAndFAQ = (props) => {

    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    const [keyState, setFocus] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    const navKeys = [
        [
        { "keycode": "", "xIndex": 0, "yIndex": 0, "span":2, "imgsrc":UpNav },
        { "keycode": "", "xIndex": 0, "yIndex": 1 , "span":2, "imgsrc":DownNav},
    ]
    ] 
    const scrollDiv = document.getElementById('helpDivToScroll')

    useEffect(() => {
        console.log(" Inside Help Page:"+props.hasFocus)
        if (props.hasFocus) {
            console.log("Help page gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
        } else {
            //setCurrentFocusOwner('')
            console.log('Help page Lost Focus')
            setFocusPoint({ "x": -1, "y": -1})
        }
    }, [props.hasFocus])

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        //Left Needs to be handled later
    
        if (keyState.DOWN) {
            let newFocusPoint = {}
            if (currentFocuspoint.y < 1 && currentFocuspoint.x === 0) {
                newFocusPoint.y = currentFocuspoint.y + 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        } else if (keyState.LEFT){
            props.bubbleFocusUP({ relieveFocus : true, action : "LEFT"})
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y === 1 && currentFocuspoint.x === 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            if (currentFocuspoint.y === 0 && currentFocuspoint.x === 0){
                scrollDiv.scrollBy(0,-100);
            }else {
                scrollDiv.scrollBy(0,100);
            }
        }
        else if (keyState.BACK) {
            props.backNavFromSettings();
        }
    }, [keyState]);

    return ( <div>
    <div className='helpTitle'>Help FAQ's</div>
    <div className='helpLine'></div>
    <div className='extremeOuterHelpDiv'>
    <div className='keyContainer'>{
        navKeys.map((row, rowIndex) => {
            return (row.map((item, colIndex) => {
                let divStyle = 'upAndDownKeys'
                if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                    divStyle = divStyle + "  focused"
                }

                return (<div className={divStyle}><img className='upAndDownImage' src = {item.imgsrc}></img></div>)
            }))
        })
       }
    </div>
    <div id = "helpDivToScroll" className='helpOuterDiv'>
    <div className='helpContent'>
    <p className='mainTitle'>About BBC Player</p>
    <p className='boldTitle'> 1:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What is BBC Player?</p>
    <p className='firstParaContentHelp'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player is an authenticated multi-genre SVOD (Subscription Video on Demand) 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;service that allows viewers to watch the very best BBC programmes anytime and 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;anywhere. It is available online (www.bbcplayer.com) and as an app, via Apple App 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Store and Google Play Store for smartphones and tablets.
    <br/>
    <br/>
    </p>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With BBC Player, subscribers to BBC channels on Astro and unifi will be able to:
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Watch BBC First – the home of premium and original drama from the BBC and 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;British independent production companies; with an array of titles that challenge, 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;immerse and entertain, including some of the BBC’s most popular and distinctive 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;dramas;
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enjoy programmes from new genre brand BBC Brit – which offers premium 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;factual entertainment that is intelligent but irreverent. BBC Brit will offer 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;programmes covering science, adventure, business, life-changing moments, 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;extreme characters, quirky eccentrics, music, sport and comedy;
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Access a selection of popular programmes from the linear channels on their
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TV subscription – including <b>BBC Earth, BBC Lifestyle and CBeebies;</b>
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stream a selection of award-winning and highly rated BBC programmes to watch
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;offline for up to 30 days.
    </p>
    <br/>
    <br/>
    <p className='boldTitle'> 2:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;How different is BBC Player from BBC iPlayer?</p>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC iPlayer is the BBC’s streaming catch-up television and radio service that is only
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;available in the UK. The service is funded by UK license fee payers and is not legally 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;available outside the UK.
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player is an authenticated service currently available in Malaysia to Subscribers 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of Astro and unifi TV packages.
    </p>
    <br/>
    <br/>
    <p className='boldTitle'> 3:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Can you tell me more about the brands / channels 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;on BBC Player? What are they?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player features content from five BBC global brands – BBC Earth, BBC First, 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Lifestyle, CBeebies and BBC Brit
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>BBC Earth</b> inspires audiences by sharing the 
    incredible wonders of our universe, 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Earth showcases the work of the world’s
   foremost factual filmmakers as it 
   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;seeks to take audiences on a thrilling journey of 
    discovery across natural history and 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;science. From the smallest creature under 
    the microscope to the limitless expanses 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;of space, BBC Earth brings viewers face to 
    face with heart pounding action, 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;mind-blowing ideas and the wonders of being 
    human.
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>BBC First</b> is the home of premium, original British drama. Showcasing world-
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;renowned, award-winning talent in front of and behind the camera, it delivers 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;audiences the first chance to see stories that challenge, immerse and entertain, all
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; with a unique British perspective.
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>BBC Brit</b> offers premium factual entertainment that is intelligent but irreverent.
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Male-skewing but with broader appeal, it blends expertise with entertainment and 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;fact with fun. The channel will become the exclusive home of Top Gear and covers a 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rich variety of subjects: cars, science, adventure, business, life-changing moments, 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;extreme characters, quirky eccentrics and comedy. BBC Brit is available exclusively 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;on BBC Player.
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>BBC Lifestyle</b> is the unique entertainment destination brimming with vibrant, fresh 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and exciting programmes which set trends and guide you into transforming your 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;world; from revamping your home to invigorating your culinary skills and 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;helping you to become happier and get the best out of life.
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>CBeebies</b> is loved by preschoolers under six, and trusted by their parents and carers. 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The channel is inspired by preschoolers’ natural curiosity and zest for play. 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Programming on the channel is customised to appeal to and suit the developmental
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; needs of distinct age groups. Parents and carers trust CBeebies to deliver a safe,
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; non-violent, interactive experience designed by developmental experts, and
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;delivered by the trusted BBC brand, to promote imaginative play, social interaction,
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; language skills and educational values.
    </p>
    <br/>
    <br/>
    <p className='boldTitle'> 4:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Is BBC Player exclusive to subscribers?</p>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The BBC Player is currently available only to Astro and TM Net subscribers in 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Malaysia. For more information, please visit. 
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Astro TV Subscribers: https://api.whatsapp.com/send?
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone=60395433838&text=Get%20BBC
    <br/> 
    <br/>
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net Subscribers: https://unifi.com.my/tv.  
    </p>
    <br/>
    <br/>
    <p className='boldTitle'> 5:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In terms of functions and features, is there a difference 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;between the BBC Player on set top box, mobile app 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and http://www.bbcplayer.com?</p>
    <br/>
    <br/>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;They are the same except that the download feature is only available on the BBC 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Player mobile apps.
    </p>
    <br/>
    <br/>
    <p className='boldTitle'> 6:&nbsp;&nbsp;&nbsp;&nbsp;Why are some programmes from my favourite BBC 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;channels not available on BBC Player to stream?
    </p>
    <br/>
    <br/>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unfortunately, we are not always able to acquire BBC Player rights for every
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;programme we broadcast; and sometimes we can only acquire the rights to stream a
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;programme.
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We are continually looking at ways to ensure a great viewing experience for you, so 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;check back often to discover what is new on BBC Player.
    <br/>
    <br/>
    </p>
    <p className='boldTitle'> 7:&nbsp;&nbsp;&nbsp;&nbsp;Will BBC Player be bringing in the latest BBC shows 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;very soon after the UK telecast?
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We will bring select programmes express from the UK. Do check in regularly to see 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;what’s new on BBC Player!
    </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Also, follow us on Facebook (https://www.facebook.com/BBCPlayer) to get the 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;latest news on BBC Player.
    <br/>
    <br/>
    </p>
    <p className='mainTitle'><b>ACCESSING BBC PLAYER IN MALAYSIA</b></p>
    <p className='boldTitle'> 8:&nbsp;&nbsp;&nbsp;&nbsp;How do I begin watching all my favourite programmes 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;on the BBC Player? </p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player is available online via www.bbcplayer.com, and also through an app on 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;your mobile phone via the Apple App Store or Google Play Store, or on your TV via 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;your set top box. 
    </p>
    <br/>
    <br/>
    <p className='boldTitle'> 9:&nbsp;&nbsp;&nbsp;&nbsp;Do I need to pay/ How much do I need to pay for BBC 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Player?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player is currently available only to Astro and TM Net subscribers in Malaysia. 
    <br/> 
    <br/> 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For more information, please visit:
    <br/> 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Astro TV Subscribers: https://api.whatsapp.com/send?
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone=60395433838&text=Get%20BBC
    <br/> 
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net Subscribers: https://activate.unifi.com.my/ott/ 
    <br/>
    <br/>
    </p>
    <p className='boldTitle'> 10:&nbsp;&nbsp;Can I access the BBC Player if I don’t have any 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subscription?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will be able to access the BBC Player catalogue and get a sneak peek of our 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;programmes through trailers and promotional videos without an account.
    <br/>
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We hope to pique your interest there and you can sign up for the BBC Player via your
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;service provider. 
    <br/> 
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For more information, please visit:
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Astro TV Subscribers: https://api.whatsapp.com/send?
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone=60395433838&text=Get%20BBC
    <br/> 
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net Subscribers: https://activate.unifi.com.my/ott/ 
    <br/>
    <br/>
    </p>
    <p className='boldTitle'>11:&nbsp;&nbsp;How do I cancel my BBC Player subscription?</p>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To cancel your BBC Player subscription, please get in touch with your service 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;provider.
    <br/>
    <br/>
    </p>
    <p className='mainTitle'><b>SECURITY</b></p>
    <p className='boldTitle'>12:&nbsp;&nbsp;How does the BBC Player support parental guidance 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and protection for children?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player lets you use a PIN to control access to programmes, based on their 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;rating, on supported devices. To set up a PIN, go to ‘Settings’ on the BBC Player app 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;or website.Please note that you will have to do this for every new device that you
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;download BBC Player to and for every browser you use to access 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;http://www.bbcplayer.com/.
    <br/>
    <br/>
    </p>
    <p className='boldTitle'>13:&nbsp;&nbsp;Why do I need to log on again on each device and 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;browser?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The requirement to log on is part of the security feature that helps to prevent 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;unauthorised usage of your TV account.
    <br/>
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will need to log into every device that you’ve downloaded the BBC Player app 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;onto, and every new computer you’re using for the online version.
    </p>
    <br/>
    <br/>
    <br/>
    <p className='mainTitle'><b>STREAMING AND DOWNLOADING PROGRAMMES ON BBC PLAYER</b></p>
    <br/>
    <br/>
    <p className='boldTitle'>14:&nbsp;&nbsp;I want to watch the programmes in HD. Is there an 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;option to choose the video quality on BBC Player?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In most cases, you will be watching the HD versions. If you wish to lower the video 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;quality (in order to save bandwidth, for example), you can set your preference in the 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;video and audio settings under ‘settings’.
    </p>
    <br/>
    <p className='boldTitle'>15:&nbsp;&nbsp;How often will you be uploading new programmes onto 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BBC Player Service?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Programmes will be updated regularly, so please do check back often!
    <br/>
    <br/>
    </p>
    <p className='mainTitle'>LANGUAGES AND SUBTITLES</p>
    <p className='boldTitle'>16:&nbsp;&nbsp;Will programmes be available in different languages?</p>
    <br/>
    <p className='boldTitle'>17:&nbsp;&nbsp;Will the programmes on BBC Player be dubbed or 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;subtitled?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;English closed captions,Chinese and Bahasa Malaysia subtitles are available for
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;selected programmes. At the moment, BBC Player features content only in English
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;audio.
    <br/>
    <br/>
    </p>
    <p className='firstParaContentHelp'>
    <p className='boldTitle'>18:&nbsp;&nbsp;How can I switch off the subtitles?</p>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subtitles can be switched on or off using the ‘subtitles’ button which is shown in the 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;form of a speech bubble located at the bottom right corner of the video player.
    <br/>
    <br/>
    </p>
    <p className='mainTitle'>TECHNICAL ISSUES</p>
    <p className='boldTitle'>19:&nbsp;&nbsp;I’m having problems playing programmes on my 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;device. What can I do?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please contact your service provider for any technical issues with your TV set top box
    </p>
    <p className='boldTitle'>20:&nbsp;&nbsp;&nbsp;I’m watching programmes at home via Wi-Fi. Yet I’m 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;having skipping and buffering problems. Why is that?</p>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you’re having problems with playback such as the video skipping or buffering, 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;you might want to check the Wi-Fi connection. If the signal is strong, check if other 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;devices on the network are downloading or uploading anything as this may slow 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;down the service.
    <br/>
    <br/>
    </p>
    <p className='boldTitle'>21:&nbsp;&nbsp;&nbsp;&nbsp;I am unable to access some programmes on BBC 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Player and I keep getting a pop-up that asks me to 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;upgrade my subscription.</p>
    <br/>
    <br/>
    <br/>
    <br/>
    <p className='firstParaContentHelp'>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is because you are currently not subscribed to the corresponding BBC 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;channels provided by your service provider. 
    <br/>
    <br/>
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For more information, please visit:
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Astro TV Subscribers: https://api.whatsapp.com/send?
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;phone=60395433838&text=Get%20BBC
    <br/> 
    <br/>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TM Net Subscribers: https://activate.unifi.com.my/ott/ 
    <br/>  
    <br/>
    <br/> 
    </p>
    <br/>
    <br/>
    </div>
    </div>
    </div>
    </div>
    )
}

export default HelpAndFAQ