import './PlayerPinKeyboard.css'
import DeleteImage from "../../assets/images/DeleteKey.png";
import {useState, useEffect} from 'react'

import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';

const PlayerPinKeyboard = (props) => {

    const [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    const [changeButtonColor,setChangeButtonColor] = useState(false)


    useEffect(() => {
        console.log("Inside Pin Keyboard:"+props.hasFocus)
        if (props.hasFocus) {
            console.log("Pin Keyboard gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
        } else {
            setFocusPoint({ "x": -1, "y": -1})
            console.log('Pin Key Board Lost Focus')
        }
    }, [props.hasFocus])


    const pinKeys = [
        [ 
            { "keycode": "1", "xIndex": 0, "yIndex": 0 },
            { "keycode": "2", "xIndex": 1, "yIndex": 0 },
            { "keycode": "3", "xIndex": 2, "yIndex": 0 },
            { "keycode": "4", "xIndex": 3, "yIndex": 0 },
            { "keycode": "5", "xIndex": 4, "yIndex": 0 },
            { "keycode": "6", "xIndex": 5, "yIndex": 0 },],
        [
            { "keycode": "7", "xIndex": 0, "yIndex": 1 },
            { "keycode": "8", "xIndex": 1, "yIndex": 1 },
            { "keycode": "9", "xIndex": 2, "yIndex": 1 },
            { "keycode": "0", "xIndex": 3, "yIndex": 1 },
            { "keycode": "<", "xIndex": 4, "yIndex": 1, "span": 2 }
        ]
    ]

    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT"], props.bubbleFocusUP, props.hasFocus)
    let cursor = 0
    useEffect(() => {
        if (cursor >= 0){
            var newArray = [...pinText]
            if (pinKeys[focusPoint.y][focusPoint.x].keycode === "<"){
                newArray[cursor] = ""
            }else {
                newArray[cursor - 1] = pinKeys[focusPoint.y][focusPoint.x].keycode
            }
            setPinText(newArray);
        }
    }, [cursor])

    const [pinText, setPinText ] = useState(["","","",""])
   

    useEffect(() => {
        let currentFocuspoint = focusPoint;
        if (keyState.RIGHT) {
            let newFocusPoint = {}
            if ((currentFocuspoint.x < 5 && currentFocuspoint.y === 0) || (currentFocuspoint.x < 4 && currentFocuspoint.y === 1) || (currentFocuspoint.x === 0 && (currentFocuspoint.y < 2))){
                newFocusPoint.x = currentFocuspoint.x + 1
                newFocusPoint.y = currentFocuspoint.y
                setFocusPoint(newFocusPoint)
            }
            
        }
        else if (keyState.LEFT) {
            let newFocusPoint = {}
            if (currentFocuspoint.x > 0) {
                newFocusPoint.x = currentFocuspoint.x - 1
                newFocusPoint.y = currentFocuspoint.y
                setFocusPoint(newFocusPoint)
            }else{
               // props.bubbleFocusUP({relieveFocus : true, action : "LEFT"})
            }
        }
        else if (keyState.DOWN) {
            let newFocusPoint = {}
            if (currentFocuspoint.y < 1) {
                newFocusPoint.y = currentFocuspoint.y + 1
                if (currentFocuspoint.x === 5){
                    newFocusPoint.x = 4
                }else {
                    newFocusPoint.x = currentFocuspoint.x
                }
                setFocusPoint(newFocusPoint)
            }else{
                props.bubbleFocusUP({action : 'DOWN'})
            }
            console.log("The new focus point is:"+JSON.stringify(newFocusPoint))
        }
        else if (keyState.UP) {
            var newFocusPoint = {}
            console.log("Inside UP the current focus point is:"+JSON.stringify(currentFocuspoint))
            if (currentFocuspoint.y > 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            props.setKey({ value : pinKeys[focusPoint.y][focusPoint.x].keycode})
        }
    }, [keyState]);




    return(
        <div className="player-pin-kb-container">
        <div className = "player-pin-keyboard-panel"> {
                 pinKeys.map((row, rowIndex) => {
                    return (row.map((item, colIndex) => {
                        var style = "playerPinKeyBoardButton"
                        
                        if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                            style = style + " focusedElement"
                        }

                        if (item.span === 2 ) {
                            style = style + " deleteKeyForPin"
                            return <div className={style}>
                                <img className="deleteImage" src ={DeleteImage}/>
                            </div>
                        }
                       
                        if (item.span === 6){
                            if (item.keycode === "Set new Pin" || item.keycode === "Next" || item.keycode === "Confirm and complete" || item.keycode === "Continue and turn off PIN"){
                                style = style + " nextKey"
                                if (changeButtonColor){
                                    style = style + " selectedKey"
                                }
                            }else {
                                style = style + " forgottenKey"
                            }
                            
                        }
                        return <div key={'key' + rowIndex + "_" + colIndex} className={style}>{item.keycode}</div>
                            
                    }))
                })
            }
            </div>
    </div>
    )

    
}

export default PlayerPinKeyboard