import LoadingSpinner from '../../assets/icons/loading-icon.png'
import Logo from '../../assets/images/logo-player.png'
import './LoadingScreen.css'

const LoadingScreen = ({isOpaque}) => {
    return(
        <div className="loading-screen-container">
            <img className='loading-screen-logo' src={Logo}></img>
            <span className='loading-screen-text'>Loading</span>
            <img className="loagin-screen-icon" src={LoadingSpinner} />
        </div>
    )
}

export default LoadingScreen