import React, { useState,useEffect } from 'react';
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import './PinManagement.css';
import PinManagementHome from '../PinManagement/PinManagementHome';
import useFocusParent from '../../../hooks/useFocusParent'
import ChoosePin from './ChoosePin';
import SecurityQuestions from '../PinManagement/SecurityQuestions';
import SecurityAnswer from './SecurityAnswer';
import RatingSelector from './RatingSelector';
import ChangePinSecurity from './ChangePinSecurity';
import CreatePin from './CreatePin';
import { setStorageItem,getStorageItem,removeStorageItem } from '../../../utils/utils';

const PinManagement = (props) => {
    const [screenState, setScreenState] = useState({
        "showSetpin": {
        "visibility": false,
        "metaData" : {
            "componentName": "CreatePin"
        }
        },
        "showChoosePin": {
        "changeConfirmation":false,
        "disable":false,
        //This is for initial flow(1)
        "createNew":false,
        //This is for flow(2)
        "checkChangePin":false,
        //This is also for flow(2)
        "changeNew":false,
        //This is for flow 3
        "forgotten":false,
        "visibility": false,
        "changeRatingConfirmation":false,
        "metaData" : {
            "componentName": "ChoosePin"
        },
        "labelData":{
            "lineOne":"",
            "parentLabel":""
        }
        },
        "showSecurity": {
        "visibility": false,
        "metaData" : {
            "componentName": "SecurityQuestions"
        }
        },
        "showAnswers": {
        "visibility": false,
        "metaData" : {
            "componentName": "SecurityAnswer"
        },
        "forgotten":false,
        "labelData":{
            "lineOne":"",
            "question":""
        }
        },
        "showRatingSelector": {
        "visibility": false,
        "forgotten":false,
        "metaData" : {
            "componentName": "RatingSelector"
        } ,
        "labelData":{
            "lineOne":"",
            "lineTwo":""
        }
        }, 
        "showChangePinSecurity" : {
        "visibility": false,
        "metaData" : {
            "componentName": "ChangePinSecurity"
        }
        },
        "tempSetting":{
            "securityQuestion":"",
            "ratingToSave":"",
            "pintoSave":"",
            "securityAnswer":""
        }
    })
    const [someValue,setSomeValue] = useState("")
    const [showSecurity, setshowSecurity] = useState(false)
    const [pinManagementData, setpinManagementData] = useState({flow :null,step:null,flowState:{"pin":null,"rating":null}})
    const placeHolderSettingsData = {"pin":null,"rating":null}
    const flows = {
        "choosePin": [
          ChoosePin
        ]
    };

    let focusNavMap = {
        ChangePinSecurity:{
            "LEFT": "SettingsSideMenu"
        },
        ChoosePin : {
            "LEFT" : "SettingsSideMenu"
        }

    }
    const [currentFocusOwner, setCurrentFocusOwner] = useState('')

   // const [currentFocusOwner, setFocusRequest, changeFocusOwner, unhandledAction, setUnHandledAction] = useFocusParent('', focusNavMap)
    useEffect(() => {
        //check if user has already created settings
        console.log("Jenix Calling use effect once")
        let pinSettings = getStorageItem("pinSettings", true, true);
        //let hasPindetails = false
        //removeStorageItem("pinSettings")
        //let pinSettings = false
        console.log("The Pin settings is:"+JSON.stringify(pinSettings))
        
        if (pinSettings){
            if (props.hasFocus){
                setCurrentFocusOwner('ChangePinSecurity')
            }
            setScreenState((prevState) => ({...prevState, showChangePinSecurity:{...prevState.showChangePinSecurity,  visibility:true}}))
        }else {
            setCurrentFocusOwner('CreatePin')
            setScreenState((prevState) => ({...prevState, showSetpin:{...prevState.showSetpin,  visibility:true}}))
        }
        
    },[])

    useEffect(() => {
        //Change focus Owner
        console.log("The screen state after changing is:"+JSON.stringify(screenState))
        let noItemHasVisibilty = true
        for (let key in screenState){
            let item = screenState[key]
            if (item && item.visibility){
                noItemHasVisibilty = false
                if (props.hasFocus){
                    console.log("Changing the focus owner to:"+item["metaData"].componentName)
                    setCurrentFocusOwner(item["metaData"].componentName)
                }
                
            }
        }

        if (noItemHasVisibilty){
            console.log("NO ITEM HAS VISIB")
            // setCurrentFocusOwner('CreatePin')
            // setScreenState((prevState) => ({...prevState, showSetpin:{...prevState.showSetpin,  visibility:true}}))
        }

    },[screenState])

    useEffect(() => {
        console.log("Inside pin management:"+props.hasFocus)
        if (props.hasFocus) {
            console.log('Pin Management Gained Focus')
            for (let key in screenState){
                let item = screenState[key]
                if (item && item.visibility){
                    if (props.hasFocus){
                        console.log("Changing the focus owner to:"+item["metaData"].componentName)
                        setCurrentFocusOwner(item["metaData"].componentName)
                    }
                    
                }
            }
        }else{
            setCurrentFocusOwner('')
            console.log('Pin Management Lost Focus')
        }
    }, [props.hasFocus]) 

    const {flow, step, flowState } = pinManagementData
    const {pin, rating } = placeHolderSettingsData
    const FlowStepComponent = flow && flows[flow][step];

    return (
        <div>
            <div className='headerTitle'>PIN management</div>
            <div className='line'></div>
            <div>{ 
                screenState.showSetpin["visibility"] ? <CreatePin backNavFromSettings={props.backNavFromSettings} screenState = {screenState} setScreenState = {setScreenState}  hasFocus={currentFocusOwner === "CreatePin"} bubbleFocusUP={props.bubbleFocusUP}/> 
                : screenState.showChoosePin["visibility"] ? <ChoosePin backNavFromSettings={props.backNavFromSettings} hasFocus={currentFocusOwner === "ChoosePin"} bubbleFocusUP={props.bubbleFocusUP} screenState = {screenState} setScreenState = {setScreenState} lineOne = {screenState.showChoosePin.labelData["lineOne"]} parentLabel = {screenState.showChoosePin.labelData["parentLabel"]}/>
                : screenState.showSecurity["visibility"] ? <SecurityQuestions backNavFromSettings={props.backNavFromSettings} hasFocus={currentFocusOwner === "SecurityQuestions"} bubbleFocusUP={props.bubbleFocusUP} screenState = {screenState} setScreenState = {setScreenState}/> 
                : screenState.showAnswers["visibility"] ? <SecurityAnswer backNavFromSettings={props.backNavFromSettings} hasFocus={currentFocusOwner === "SecurityAnswer"} bubbleFocusUP={props.bubbleFocusUP} screenState = {screenState} setScreenState = {setScreenState} lineOne = {screenState.showAnswers.labelData["lineOne"]} parentLabel = {screenState.showAnswers.labelData["parentLabel"]} forgotten={screenState.showAnswers.forgotten}/> 
                : screenState.showRatingSelector["visibility"] ? <RatingSelector backNavFromSettings={props.backNavFromSettings} hasFocus={currentFocusOwner === "RatingSelector"} bubbleFocusUP={props.bubbleFocusUP} screenState = {screenState} setScreenState = {setScreenState} lineOne = {screenState.showRatingSelector.labelData["lineOne"] } lineTwo = {screenState.showRatingSelector.labelData["lineTwo"]} nextLabel = {screenState.showRatingSelector.forgotten ? "Confirm PIN rating level":"Confirm and complete"}/> 
                : screenState.showChangePinSecurity["visibility"]? <ChangePinSecurity backNavFromSettings={props.backNavFromSettings} hasFocus={currentFocusOwner === "ChangePinSecurity"} bubbleFocusUP={props.bubbleFocusUP} screenState = {screenState} setScreenState = {setScreenState}/> :<div>Heheh</div>
            }</div>
            
        </div>
    )
}



export default PinManagement