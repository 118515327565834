import './RatingSelector.css'
import useRemoteKeyEvent from '../../../hooks/useRemoteKeyEvent';
import { useEffect, useState } from 'react'
import { makeRequest } from "../../../utils/fetchData";
import Modal from '../../Modals/Modal';
import { setStorageItem,getStorageItem,removeStorageItem } from '../../../utils/utils';
import defaultMap from '../../../utils/defaultMap';
import GreenTick from '../../../assets/images/greenTick.png'

const RatingSelector = (props) => {

    let [focusPoint, setFocusPoint] = useState({ "x": 0, "y": 0 });
    const [keyState, setFocus,setSuppressed] = useRemoteKeyEvent(['DOWN', 'UP', 'OK', "RIGHT", "LEFT", "BACK"], props.bubbleFocusUP, props.hasFocus)
    const [selectedRating, setselectedRating] = useState(null)
    const [ratings, setRatings] = useState([]);
    const [isModalOpen,setOpen] = useState(false);
    
    const localCertifications = [{"id":"0MY","name":"U","age":0},{"id":"13MY","name":"PG13","age":13},{"id":"18MY","name":"18","age":18},{"id":"21MY","name":"R21","age":21}]

    useEffect(() => {
        //Fetch Available rating data from MW
        const url = `https://api.bbcplayer.com/api/certifications`;
        makeRequest(url)
        .then((data) => {
            console.log(data)
            let certifications = (data && data.certifications && data.certifications.nodes) ? data.certifications.nodes : null
            if( certifications.length === 0){
                certifications = localCertifications 
            }

            let xIndex = 0
            let ratingArray = []
            let firstArray = []
            console.log("The certis are :"+JSON.stringify(certifications))
            if (certifications){
                for (const certi of certifications){
                    let dict = {"keycode":certi.name,"xIndex": xIndex, "yIndex": 0}
                    xIndex = xIndex + 1
                    firstArray.push(dict)
                }
                ratingArray.push(firstArray)
                ratingArray.push([{ "keycode": "", "xIndex": 0, "yIndex": 1, span:4 }])
                setRatings(ratingArray)
            }
        })
        .catch((err) => console.log(err));
    }, [])


    useEffect(() => {
        console.log("Inside use effect of isModalOpen:"+isModalOpen)
        if(isModalOpen){
            console.log("The current inside Rating selector is:"+JSON.stringify(props.screenState))
            let localSettings = getStorageItem("pinSettings", true, true);
            let pinSettingsToSave = localSettings !== null ? localSettings : props.screenState["tempSetting"]
            setStorageItem("pinSettings",pinSettingsToSave,true,true)
        }else{
            //Take the user back to the Pin Management screen
            // let oldState = props.screenState  
            // oldState.showRatingSelector["visibility"] = false
            // oldState.showChangePinSecurity["visibility"] = true
            // props.setScreenState({...oldState}) 

        }
    }, [isModalOpen])

    useEffect(() => {
        console.log("Rating changed")
    }, [selectedRating])

    useEffect(() => {
        console.log("The reatings are:"+JSON.stringify(ratings))
    }, [ratings])

    const MAX_ROW = (ratings && ratings[0]) ? ratings[0].length - 1 : 0
   
    useEffect(() => {
        let currentFocuspoint = focusPoint;
        
        if (keyState.RIGHT) {
            let newFocusPoint = {}
            if (currentFocuspoint.x < MAX_ROW && currentFocuspoint.y < 4) {
                newFocusPoint.x = currentFocuspoint.x + 1
                newFocusPoint.y = currentFocuspoint.y 
                setFocusPoint(newFocusPoint)     
            }
        }
        else if (keyState.LEFT) {
            let newFocusPoint = {}
            console.log(currentFocuspoint.x )
            if (currentFocuspoint.x > 0) {
                newFocusPoint.x = currentFocuspoint.x - 1
                newFocusPoint.y = currentFocuspoint.y
                setFocusPoint(newFocusPoint)
            }else{
                console.log('bubble focus mgmt to parent')
                props.bubbleFocusUP({ relieveFocus : true, action : "LEFT"})
            }
        }
        else if (keyState.DOWN) {
            let newFocusPoint = {}
            if (currentFocuspoint.y < 1 && selectedRating) {
                newFocusPoint.y = currentFocuspoint.y + 1 
                newFocusPoint.x = 0
                setFocusPoint(newFocusPoint)
            }
            
        }
        else if (keyState.UP) {
            let newFocusPoint = {}
            if (currentFocuspoint.y > 0) {
                newFocusPoint.y = currentFocuspoint.y - 1
                newFocusPoint.x = currentFocuspoint.x
                setFocusPoint(newFocusPoint)
            }
        }
        else if (keyState.OK) {
            //If confirmation button is clicked
            //console.log("Jenix selecting"+ratings[currentFocuspoint.y][currentFocuspoint.x].keycode)
            if (ratings && ratings[currentFocuspoint.y] && ratings[currentFocuspoint.y][currentFocuspoint.x] && ratings[currentFocuspoint.y][currentFocuspoint.x].keycode !==  props.nextLabel ){
                setselectedRating(ratings[currentFocuspoint.y][currentFocuspoint.x].keycode)
            }else if (ratings && ratings[currentFocuspoint.y] && ratings[currentFocuspoint.y][currentFocuspoint.x] && ratings[currentFocuspoint.y][currentFocuspoint.x].keycode ===  props.nextLabel){
                let oldState = props.screenState
                let localSettings = getStorageItem("pinSettings", true, true);
                oldState.tempSetting["ratingToSave"] = selectedRating
                props.setScreenState(oldState)
                console.log(localSettings)
                if(localSettings){
                    localSettings["ratingToSave"] = selectedRating
                }     
                let pinSettingsToSave = localSettings !== null ?  localSettings : props.screenState.tempSetting
                console.log("The temp setting is:"+JSON.stringify(oldState.tempSetting))
                setStorageItem("pinSettings",pinSettingsToSave,true,true)
                setOpen(true)
                setSuppressed(true)
            }else {
                //To handle the propogation to the next screen
                // setOpen(true)
                // setSuppressed(true)
            }
        }
        else if (keyState.BACK){
            props.backNavFromSettings()
        }


    }, [keyState]);

    useEffect(() => {
        if (props.hasFocus) {
            console.log("Rating selector gained focus:"+props.hasFocus)
            setFocusPoint({ "x": 0, "y": 0})
            setFocus()
        } else {
            setFocusPoint({ "x": -1, "y": -1})
            console.log('Rating selector Lost Focus')
        }
    }, [props.hasFocus])

    
    return (
        <div>
            <div className="lineOne">{props.lineOne}</div>
            <div className="lineTwo">{props.lineTwo}</div>
            <div className='ratingPanel'>{
               ratings.map((row, rowIndex) => {
                return (row.map((item, colIndex) => {
                    let style = "ratingButton"
                    
                    if (item.span === 4){
                        if (selectedRating){
                            style = style + " moveToNext"
                        }else {
                            style = style + " nextButton"
                        }
                        
                        item.keycode = props.nextLabel
                    }
                    console.log("Jennix the focus point is:"+JSON.stringify(focusPoint))
                    if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y) {
                        style = style + "  ratingFocused"
                    }
                
                    if (selectedRating && (selectedRating === item.keycode)){
                        var newStyle = "ratingDivSelect"
                        newStyle = newStyle + " selected"
                        if (item.xIndex === focusPoint.x && item.yIndex === focusPoint.y){
                            newStyle = newStyle + "  ratingFocused"
                        }
                        return <div className={newStyle}>
                                <img src = {GreenTick}></img>
                                <div>{item.keycode}</div>
                                </div>
                    }

                    return <div key={'key' + rowIndex + "_" + colIndex} className={style}>{item.keycode}</div>
                        
                }))
            })
            }
            </div>
            <Modal open={isModalOpen}  hasFocus={isModalOpen} onClose={() => {
                setOpen(false); 
                setSuppressed(false); 
                let oldState = props.screenState  
                oldState.showRatingSelector["visibility"] = false
                oldState.showChangePinSecurity["visibility"] = true
                oldState.showRatingSelector["forgotten"] = false
                props.setScreenState({...oldState}) 
                }} showBtwo = {false} buttonOneText = {"Continue"} title = {"Your Settings have successfully been updated"}>
            </Modal>
        </div>
    )
}


export default RatingSelector