import "./AutoplayCard.css";
import { getContentItemMetadata } from "../../utils/getMetadata";

//hard coded low image to avoid crash
import LowResThumb from '../../assets/images/bbc-default-thumbnail.png'

const AutoplayCard = ({ item, hasFocus }) => {
  let autoPlayImage = LowResThumb
  //Check if lowResTile is available if not check if the manually ingested content is available if not then use the existing one
  if (item.lowResTile && item.lowResTile.image){
    autoPlayImage =  item.lowResTile.image["url"]
  }else{
      if (item.images && item.images.length > 0){
        const result = item.images.filter(image => image.name.includes("860x504"));
        //Manually ingested image
        if( result.length > 0 ){
          autoPlayImage = result[0].url
        }else {
          autoPlayImage = item.images[0].url
        }
      }
  }

  return (
    <div className={"autoplaycard"}>
      <img className={"autoplaycard" + (hasFocus ? " onSelected" : "")} src={autoPlayImage} alt="" />
      <div className="autoplaycard-body">
        <p className="autoplaycard-title">{item?.series?.name}</p>
        <p className="autoplaycard-episode">{item?.tile?.header}</p>
        <p className="autoplaycard-description">{item?.tile?.subHeader}</p>
      </div> 
    </div>
  )
};

export default AutoplayCard;
