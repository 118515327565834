import { createPath } from "react-router-dom";

const getDeviceInfo = async () => {
    let data = JSON.stringify({"method": "DeviceIdentification.1.deviceidentification"});
    let url = "http://127.0.0.1:11111/jsonrpc";

 
    const result = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc. 
            headers: {
              'Content-Type': 'application/json',
            },
            body: data // body data type must match "Content-Type" header
          }).catch(err => {
              console.log("ASTRO DEVICE ID FETCH FAIL")
              return undefined
          })
    console.log(result)
    
    if( result && result.ok){
        let jsonBody = result.json()
        console.log("ASTRO DEVICE ID FETCH SUCCESS")
        console.log(jsonBody)
        return jsonBody
    }else{
        console.log("ASTRO DEVICE ID FETCH FAIL")
        return undefined
    }
        
  }

  export const requestExitApp = () => {
      let body = { "jsonrpc": "2.0", "id": 1, "method": "Controller.1.deactivate", "params": { "callsign": "WebKitBrowser" } }
      let url = 'http://127.0.0.1:11111/jsonrpc'
      fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc. 
        headers: {
          'Content-Type': 'x-www-form-urlencoded',
        },
        body: '{ "jsonrpc": "2.0", "id": 1, "method": "Controller.1.deactivate", "params": { "callsign": "WebKitBrowser" } }' // body data type must match "Content-Type" header
      })
  }


export default getDeviceInfo;