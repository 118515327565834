import React, { useEffect, useState } from 'react'
import ReactDom, { render } from 'react-dom'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';
import AstroButton from '../button/AstroButton'
import './Modal.css'
import GreenTick from "../../assets/images/greenTick.png"

const MODAL_STYLES = {
  position: 'fixed',
  display: 'flex',
  top: '540px',
  left: '960px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'rgb(255, 255, 255)',
  padding: '50px',
  zIndex: 1000,
  width: '610px',
  height: '220px',
  justifyContent : 'center',
  alignItems: 'left',
  flexDirection: 'column',
  
}

const MODAL_STYLES_FULLSCREEN = {
  position: 'absolute',
  display: 'flex',
  top: '540px',
  left: '1300px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'hsl(0deg 0% 0% / 3%)',
  padding: '50px',
  zIndex: 1000,
  width: '1920px',
  height: '1080px',
  justifyContent : 'center',
  alignItems: 'left',
  flexDirection: 'column',
  
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000
}
export default function Modal({ open, children, onClose, hasFocus, buttonOneText, buttonTwoText, showBone, showBtwo, title, onCancel, fullScreen, subtitle, subtitleDesc, iswhiteBg }) {
  const bubbleFocusUP = () => {

  }

  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(()=> {
    if(fullScreen){
      setIsFullScreen(true)
    }

  },[fullScreen])

  const [keyState, setFocus] = useRemoteKeyEvent(['LEFT', 'RIGHT', 'OK', 'BACK'], bubbleFocusUP, hasFocus)
  const [currentFocus, setCurrentFocus] = useState('')
  useEffect(() => {
    if (hasFocus) {
      setFocus()
      setCurrentFocus("Ok")
    }
  }, [hasFocus])


  useEffect(() => {

    if (keyState.RIGHT) {
      console.log("Modal  RIGHT")
      if (currentFocus === "Ok") {
        setCurrentFocus("Cancel")
      }
    }

    if (keyState.LEFT) {
      console.log("Modal LEFT")
      if (currentFocus === "Cancel") {
        setCurrentFocus("Ok")
      }
    }


    if (keyState.OK) {

      console.log(currentFocus)
      console.log(onClose)

      if (currentFocus === "Cancel") {
        //cancelled
        if(onCancel){
          onCancel()
        }else{
          onClose()
        }
        
      }

      if (currentFocus === "Ok") {
        onClose()
      }
    }

    if (keyState.BACK) {
      console.log("Modal BACK")
    }


  }, [keyState]);


  const dummy = () => {

  }

  if (!open) return null

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} />
      <div style={isFullScreen ? MODAL_STYLES_FULLSCREEN : MODAL_STYLES}>
        {
          !showBtwo ?  
            <>
              <><div className='modalTitleDiv'><img className='modalImg' src = {GreenTick}></img><span className='modalTitle'>{title}</span></div>
              <AstroButton className="modal-button-ok" hasFocus={currentFocus === "Ok"} data={{text : buttonOneText , customClass: "play"}} bubbleFocusUP={dummy}/> </>
            </>
            :
            <>
              <div className={isFullScreen ? "modal-two-button-container-fullscreen" : "modal-two-button-container"}>
                <div className={isFullScreen ? "modal-title-fullscreen" : "modal-title"}>{title}</div>
                {isFullScreen && 
                  <div className="modal-subtitle-container">
                     <div className='modal-sub-title-one'>{subtitle}</div>
                    <div className='modal-sub-title-two'>{subtitleDesc}</div>
                  </div>
                 
                }
                <div className={isFullScreen ? "modal-buttons-list-fullscreen" : "modal-buttons-list"}>
                  <AstroButton className="modal-button-ok" hasFocus={currentFocus === "Ok"} data={{ text: buttonOneText,  customClass : isFullScreen ? "modal-fullscreen" : "modal"  }} bubbleFocusUP={dummy} />
                  <AstroButton hasFocus={currentFocus === "Cancel"} data={{ text: buttonTwoText, 
                    customClass : isFullScreen ? `modal-fullscreen ${ iswhiteBg ? 'whitebg-uncolored':'uncolored'}` : `modal ${ iswhiteBg ? 'whitebg-uncolored':'uncolored'}` }} bubbleFocusUP={dummy} />
                </div>
              </div>
            </>
        }
         {/* {children} */}
      </div>
    </>,
    document.getElementById('portal')
  )
}