import React from 'react';
import { SVG } from '../../assets';
import './SettingsButton.css'

const AudioButton = ({hasFocus, clickHandler }) =>{

    return(
        <div onClick={clickHandler} className={`${hasFocus ? 'settings-button-selected' : 'settings-button-unselected'}`}>
           <SVG.Audio className="Settings-button-icon"  />
        </div>
    )
}

export default AudioButton

