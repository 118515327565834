import './PinTextDisplay.css'
import {useState} from 'react'

const PinTextDisplay = ({cursor, pinText}) => {

    

    return(
        <div className="pin-text-display-container">
            <div className = "pinTextContainer">{
                pinText.map((item , index) => {
                    return (index === cursor) ? <div className="cursorFocused">{item}</div>  : <div>{item}</div> 
                })
                }
            </div>
        </div>
    )
}

export default PinTextDisplay