import React, { useState, useContext, cloneElement } from "react";
import "./Home.css";
import { useEffect,useRef } from "react";
import useFocusParent from "../../hooks/useFocusParent";
import { makeRequest } from "../../utils/fetchData";
import SliderList from "../../components/Slider/SliderList";
import {AstroBBCContext} from '../../App'
import {useNavigate} from 'react-router-dom'
import { memo } from "react"; 
import getContentItemMetadata from '../../utils/getMetadata'

import SpotLight from "../../components/SpotLight/SpotLight";
const Home = ({ hasFocus, bubbleFocusUP, changeFocusOwnerApp, setPlayerSource, toggleAppExit, suppressKeys, showAstroInfo, setShowAstroInfo }) => {
  const [components, setComponents] = useState([]);
  const [isInitLoad, setIsInitLoad] = useState(true)
  const [spotLightData, setSpotLightData] = useState(null)
  const [heroComponent, setHeroComponent] = useState(undefined)

  const appData = useContext(AstroBBCContext);
  const [shouldSetFstoSl,setshouldSetFstoSl] = useState(false);
  const homeRef = useRef(null)


  let homePageMetaData = {}

  const history = useNavigate();
  useEffect(() => {
    let header = null
    appData.showLoading(true)
    if(appData.auth.isUserSignedIn()){
      header = appData.auth.getAuthHeader()
      console.log(header)
    }
    const url = `${process.env.REACT_APP_MIDDLEWARE_URL}/contentitem/`;
    makeRequest(url, 'GET', null, header)
      .then((data) => {



        if (data?.contentItem) {  
          const promises = [];
          const metadataMap = {}
          const unfiltered = data.contentItem.components;
          let metaDataFetchIndex = 0
          unfiltered.forEach( (item, index)=> {
            if(item?.viewAll !== '' && item.viewAll !== undefined){
              let viewAllURL = url + item.viewAll
              promises.push(makeRequest(viewAllURL, 'GET', null, header))
              metadataMap[item?.header] = metaDataFetchIndex
              metaDataFetchIndex++
            }
          });

          Promise.all(promises)
          .then((metadatas) => {
              console.log(metadatas)
              console.log(metadataMap)
              console.log(unfiltered)
              unfiltered.forEach((element,i, self) => {
                if(element?.viewAll !== '' && element.viewAll !== undefined){
                  let current = element
                  let currentIndex = metadataMap[element?.header]
                  current.collection.items.nodes = metadatas[currentIndex].contentItem?.components[0]?.collection?.items?.nodes
                  unfiltered[i] = current
                  console.log(metadatas[currentIndex].contentItem?.components[0]?.collection?.items?.nodes)
                }
              });

              setComponents(unfiltered);
              appData.showLoading(false)
              let heroData = null
              components.forEach(element => {
                if(element.__typename === "Hero"){
                  heroData = element
                }
              });
              setSpotLightData(heroData)
          })
          .catch((e) => {
              // handle errors here
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(()=> {
    if(spotLightData !== null){
      setshouldSetFstoSl(true)
      setHeroComponent('SpotLight')
    }

  }, [spotLightData])


  const mergeMetaData = (actual, viewAll) => {
    


  }

  let focusNavMap = {
    SliderList: {
     
    },
    Home: {
      LEFT: "SideMenu",
    },
    SpotLight: {
      DOWN: "SliderList",
      OK: "Player"
    },
  };

  useEffect(()=>{
    if(suppressKeys){
        setSuppressed(suppressKeys)
    }else{
        setSuppressed(suppressKeys)
    }
}, [suppressKeys])

  const [
    currentFocusOwner,
    setFocusRequest,
    changeFocusOwner,
    unhandledAction,
    setUnHandledAction,
    setSuppressed
  ] = useFocusParent("", focusNavMap, null,null, toggleAppExit, null, showAstroInfo, setShowAstroInfo);

  useEffect(() => {
    console.log("Hello focus gain should set focus:"+shouldSetFstoSl);
    if (hasFocus) {
      changeFocusOwner("SliderList");
      setIsInitLoad(false)
    } else {
      changeFocusOwnerApp("SideMenu")
      if(!isInitLoad){
        changeFocusOwner("");
        setUnHandledAction("");
      }
    }
  }, [hasFocus]);

  const trySetFocusToSL = (shouldSet) => {
    if(heroComponent !== undefined && shouldSet){
      setshouldSetFstoSl(true)
    }
    
  }

  useEffect(() => {
    console.log("Hello should have focus"+shouldSetFstoSl)
  },[shouldSetFstoSl])
  useEffect(() => {
    if (unhandledAction !== "" && !isInitLoad) {
      bubbleFocusUP({ relieveFocus: true, action: unhandledAction });
    }
  }, [unhandledAction]);

  return (
    <div className= {hasFocus ? "home-screen removeHomeMargin" : "home-screen addHomeMargin"}>
      {/* <header>New Releases Double Pica</header> */}
      { spotLightData !== null && false &&
              <SpotLight
              hasFocus={currentFocusOwner === "SpotLight"}
              bubbleFocusUP={setFocusRequest}
              setPlayerSource={setPlayerSource}
              changeFocusOwnerApp={changeFocusOwnerApp}
              suppressKeys={suppressKeys}
            />
      }

      <div className="home-slider-list-container">
      <SliderList
        hasFocus={currentFocusOwner === "SliderList"}
        text="Slider"
        bubbleFocusUP={setFocusRequest}
        isSelected={true}
        source="Home"
        components={components}
        holdFocus={false}
        setPlayerSource={setPlayerSource}
        changeFocusOwnerApp={changeFocusOwnerApp}
        shouldSetFocus = {trySetFocusToSL}
        suppressKeys={suppressKeys}
      />
      </div>
      
    </div>
  );
};

export default memo(Home);
