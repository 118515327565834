import './Autoplay.css'
import AutoplayCard from '../../components/Card/AutoplayCard'
import AutoplayTimer from './AutoplayTimer'

import {useRef, useEffect, useState} from 'react'
import useRemoteKeyEvent from '../../hooks/useRemoteKeyEvent';


const Autoplay = ({ hasFocus, bubbleFocusUP, onFinish,item, onCancel }) => {

    const [keyState, setFocus, setSuppressed] = useRemoteKeyEvent(['OK', 'BACK', 'UP', 'DOWN', 'RIGHT', 'LEFT'], bubbleFocusUP, hasFocus)

    useEffect(()=>{

    }, [])

    const [currentFocusOwner, changeCurrentFocusOwner] = useState('')
    const [timerTrigger, setTimerTrigger] = useState(false)



    useEffect(() => {
        if (hasFocus) {
            setFocus()
            console.log(item)
            setTimerTrigger(true)
            changeCurrentFocusOwner('AutoplayCard')
        }
    }, [hasFocus])


    useEffect(() => {
        

        if (keyState.OK) {
            console.log(currentFocusOwner)
            if(currentFocusOwner === 'AutoplayCard'){
                //onfinish
                setTimerTrigger(false)
                onFinish()
            }else{
                //oncancel
                onCancel()
                setTimerTrigger(false)
            }
        }

        if (keyState.BACK) {
           
        }


        if (keyState.LEFT) {
            if(currentFocusOwner === 'AutoplayCard'){
                console.log("focus to timer")
                changeCurrentFocusOwner('AutoplayTimer')
            }  
        }


        if (keyState.RIGHT) {
            if(currentFocusOwner === 'AutoplayTimer'){
                changeCurrentFocusOwner('AutoplayCard')
            }      
        }

    }, [keyState]);




    return (
        <div className="autoplay-container">
            <p className="autoplay-title">Next episode starts in...</p>
            <div className="autoplay-control-container">
                <AutoplayTimer hasFocus={currentFocusOwner === 'AutoplayTimer'} trigger={timerTrigger} onFinish={onFinish}/>
                { item && 
                     <AutoplayCard  hasFocus={currentFocusOwner === 'AutoplayCard'} item={item}  />
                }
               
            </div>
        </div>
    )
}

export default Autoplay

