import React , {useEffect, useState, useRef} from 'react';
import './AutoplayTimer.css'
import CircleIcon from '../../assets/icons/player/circle-outline.png'


const AutoplayTimer = ({onCancel, onFinish, trigger, hasFocus }) =>{
    const autoTimer = useRef(null);
    const [counter, setCounter] = useState(10)
    
    useEffect(()=> {
        if(trigger){
            autoTimer.current = setInterval(handleCounterUpdate, 1000);
            return () => clearTimeout(autoTimer.current)
        }else{
            clearTimeout(autoTimer.current)
            autoTimer.current = null
        }
    }, [trigger])

    const handleCounterUpdate = () => {

            console.log("auto timer")
            setCounter( counter => counter -1)
 
        
    }
    useEffect(()=>{
        if(counter === 0){
            clearTimeout(autoTimer.current)
            autoTimer.current = null
            onFinish()
            console.log("done")
        }

    }, [counter])


    return(
        <div className="autoplay-timer-container">
            <div className="autoplay-timer-image">
                <img className="autoplay-timer-circle" src={CircleIcon} />
                <p className="autoplay-timer-counter"> {counter} </p>
            </div>
            <p className={"autoplay-cancel-text" + (hasFocus ? " autoplay-cancel-onselected" : "")}  >
                Cancel
            </p>
            
        </div>
    )
}

export default AutoplayTimer

